import { combineReducers } from 'redux';
import popApi from '../services';
import sessionReducer from './sessionReducer';
import createStudyReducer from './createStudyReducer';
import createStudyResourceReducer from './createStudyResourceReducer';
import snackbarReducer from './snackbarReducer';
import projectReducer from './projectReducer';
import resourceReducer from './resourceReducer';
import createQuotationReducer from './createQuotationReducer';
import featureFlagReducer from './featureFlagReducer';
import criteriaGroupsReducer from './criteriaGroupsReducer';
import { retailAuditResourceReducer } from './retailAuditReducer';
import remoteConfigReducer from './remoteConfigReducer';
import panelExploreReducer from './panelExploreReducer';
import popliteWalletReducer from './popliteWalletReducer';
import poplitePromoReducer from './poplitePromoReducer';
import popliteTrxReducer from './popliteTrxReducer';
import popliteDrawerReducer from './popliteDrawerReducer';
import poplitePopcoinsPromoReducer from './poplitePopcoinsPromoReducer';
import creditWalletReducer from './creditWalletReducer';
import amRemoteAccessReducer from './amRemoteAccessReducer';
import clientManagementReducer from './clientManagementReducer';
import eligibleParticipantReducer from './eligibleParticipantReducer';
import { resourcesApi } from './slice/resourceApiSlice';

const rootReducer = combineReducers({
  session: sessionReducer,
  study: createStudyReducer,
  createStudyResource: createStudyResourceReducer,
  snackbar: snackbarReducer,
  project: projectReducer,
  resource: resourceReducer,
  quotation: createQuotationReducer,
  featureFlag: featureFlagReducer,
  criteriaGroups: criteriaGroupsReducer,
  retailAuditResource: retailAuditResourceReducer,
  remoteConfig: remoteConfigReducer,
  panelExplore: panelExploreReducer,
  popliteWallet: popliteWalletReducer,
  creditWallet: creditWalletReducer,
  poplitePromo: poplitePromoReducer,
  popliteTrx: popliteTrxReducer,
  popliteDrawer: popliteDrawerReducer,
  poplitePopcoinsPromo: poplitePopcoinsPromoReducer,
  amRemoteAccess: amRemoteAccessReducer,
  clientManagement: clientManagementReducer,
  eligibleParticipant: eligibleParticipantReducer,
  [popApi.reducerPath]: popApi.reducer,
  [resourcesApi.reducerPath]: resourcesApi.reducer,
});

export default rootReducer;

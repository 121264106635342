import mixpanel from 'mixpanel-browser';
mixpanel.init(process.env.REACT_APP_MIXPANEL, { debug: false });

let actions = {
  identify: (id) => {
    mixpanel.identify(id);
  },
  alias: (id) => {
    mixpanel.alias(id);
  },
  track: (name, props) => {
    mixpanel.track(name, props);
  },
  people: {
    set: (props) => {
      mixpanel.people.set(props);
    },
  },
  source: () => {
    if (document.referrer.search('https?://(.*)google.([^/?]*)') === 0) {
      return 'Google';
    } else if (document.referrer.search('https?://(.*)bing.([^/?]*)') === 0) {
      return 'Bing';
    } else if (document.referrer.search('https?://(.*)yahoo.([^/?]*)') === 0) {
      return 'Yahoo';
    } else if (
      document.referrer.search('https?://(.*)facebook.([^/?]*)') === 0
    ) {
      return 'Facebook';
    } else if (
      document.referrer.search('https?://(.*)twitter.([^/?]*)') === 0
    ) {
      return 'Twitter';
    } else if (
      document.referrer.search('https?://(.*)info.populix.([^/?]*)') === 0
    ) {
      return 'Website_Populix';
    } else {
      return 'Other';
    }
  },
};

export let Mixpanel = actions;

import { popCall, getErrorMessage } from 'src/utils/popCall';
import { omit } from './helper';
import reduxStore from '../store';

/**
 * @typedef {object} RemoteClient
 * @property {string} userEmail
 * @property {string} expiredAt
 * @property {boolean} isPoplitePro
 */

const STORAGE_KEY = 'clientAM';

/** @param {string} str */
function utf8ToB64(str) {
  return window.btoa(unescape(encodeURIComponent(str)));
}

/** @returns {Record<string, RemoteClient>} */
function getClientList() {
  try {
    return JSON.parse(window.localStorage.getItem(STORAGE_KEY) ?? '{}');
  } catch {
    return {};
  }
}

/** @param {string} b64UserEmail */
export function getClientByB64UserEmail(b64UserEmail) {
  return getClientList()[b64UserEmail]
}

/** @param {RemoteClient} data */
function storeClient(data) {
  const encryptedEmail = utf8ToB64(data.userEmail);
  const isExist = !!getClientByB64UserEmail(encryptedEmail)?.userEmail;

  if (!isExist) {
    window.localStorage.setItem(
      STORAGE_KEY,
      JSON.stringify({
        ...getClientList(),
        [encryptedEmail]: data,
      }),
    );
  }

  return { ...data, encryptedEmail };
}

/** @param {string} b64UserEmail */
function removeClientByB64UserEmail(b64UserEmail) {
  const clientList = omit(getClientList(), [b64UserEmail]);
  window.localStorage.setItem(STORAGE_KEY, JSON.stringify(clientList));
}

/** @param {string} userEmail */
export async function endSession(userEmail) {
  const response = await popCall.post(`/popliteAccountManager/user/${userEmail}/logout`);

  const encryptedEmail = utf8ToB64(userEmail);
  removeClientByB64UserEmail(encryptedEmail);

  return response
}

/** @param {string} userEmail */
function startSession(userEmail) {
  return popCall.post('/popliteAccountManager', {
    popliteClientEmail: userEmail,
  });
}

/** @param {string} clientEmail */
function getSessionByClientEmail(clientEmail) {
  return popCall.get(`/popliteAccountManager/user/${clientEmail}`);
}

function parseClientDataFromServer(data) {
  return {
    expiredAt: data?.expiredAt ?? '',
    userEmail: data?.ClientEmail ?? '',
    isPoplitePro: data?.isPoplitePro ?? false,
  }
}

/** @param {string} userEmail */
export async function loginAsUser(userEmail) {
  try {
    const { data } = await startSession(userEmail);

    return storeClient(parseClientDataFromServer(data));
  } catch (error) {
    const reason = getErrorMessage(error);

    // if the account manager is already logged in,
    //  don't throw an error, but return the client data
    if (/already granted/i.test(reason)) {
      const encryptedEmail = utf8ToB64(userEmail);
      const client = getClientByB64UserEmail(encryptedEmail);

      if (!client) {
        const { data } = await getSessionByClientEmail(userEmail);

        return storeClient(parseClientDataFromServer(data));
      }

      return {
        ...client,
        encryptedEmail,
      };
    }

    throw new Error(reason);
  }
}

export function getRemoteAccessState() {
  try {
    const state = reduxStore.store.getState()?.amRemoteAccess;

    if (!state?.isRemoting) {
      return undefined;
    }

    return state;
  } catch {
    return undefined;
  }
}

export function getPopliteClientEmail() {
  return getRemoteAccessState()?.userEmail
}

/** @param {string} url */
export function appendAMRemoteKey(url) {
  const remoteKey = getRemoteAccessState()?.remoteKey
  return [url, remoteKey ? `?access=${remoteKey}` : ''].join('')
}

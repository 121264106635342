/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/**
 * defined your api mock here
 * use for development only!
 * remove the handler if the real api is ready
 * docs: https://mswjs.io/docs/getting-started/mocks/rest-api
 */

const handlers = [];

export default handlers;

import React from 'react';

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/styles';
import { formatNumber } from 'src/utils/helper';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    width: '325px',
    padding: '16px 16px',
    backgroundColor: 'transparent',
  },
  dialogField: {
    marginBottom: 16,
    marginTop: 10,
  },
  dialogAction: {
    justifyContent: 'center',
  },
  title: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  titleText: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 600,
    color: '#333333',
  },
  actionButton: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  boxTotalBill: {
    padding: '8px 16px',
    border: '1px dashed #828282',
    borderRadius: 12,
    marginBottom: 16,
  },
  totalBillTitle: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: '18px',
    color: '#333333',
    marginBottom: 8,
  },
  totalBillText: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: '28px',
    color: '#329BFF',
  },
  contentDivider: {
    margin: theme.spacing(0, 0, 2, 0),
    backgroundColor: '#E0E0E0',
  },
  textPromoBadge: {
    color: '#FFFFFF',
    fontWeight: 600,
    fontSize: '11px',
    backgroundColor: '#4CAF50',
    padding: theme.spacing(0.5),
    borderRadius: '10px',
    marginRight: theme.spacing(1.5),
  },
  titleTips: {
    fontSize: 16,
    flexGrow: 1,
    color: '#333333',
    fontWeight: 600,
  },
  promoInfoTitleLeft: {
    backgroundColor: '#FABD00',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  promoInfoTitleRight: {
    backgroundColor: '#78DC77',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  promoInfoTitleText: {
    fontSize: 12,
    flexGrow: 1,
    color: '#333333',
    fontWeight: 600,
  },
  promoInfoContentLeftText: {
    fontSize: 12,
    flexGrow: 1,
    color: '#333333',
    fontWeight: 300,
  },
  promoInfoContentLeft: {
    backgroundColor: '#FFFBF8',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  promoInfoContentLeftLight: {
    backgroundColor: '#FFEFD0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  promoInfoContentRight: {
    backgroundColor: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  promoInfoContentRightLight: {
    backgroundColor: '#F5FFED',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  promoInfoTitleDivider: {
    backgroundColor: '#000000',
  },
  promoInfoContentDivider: {
    backgroundColor: '#BDBDBD',
  },
  textPromoInfo: {
    color: '#00417D',
    fontWeight: 300,
    fontSize: '11px',
  },
  textPromoInfoPercentageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textPromoInfoPercentage: {
    color: '#4CAF50',
    fontWeight: 600,
    fontSize: '16px',
  },
  textPromoInfoPercentageDesc: {
    color: '#4F4F4F',
    fontWeight: 300,
    fontSize: '10px',
  },
}));

const TopUpPromoInfo = ({ isTopUpForm, handleClose, data }) => {
  const classes = useStyles();
  return (
    <>
      <Box display="flex" flexDirection="row" alignItems="center" mb={2}>
        <Box>
          <Typography className={classes.textPromoBadge}>Promo</Typography>
        </Box>
        <Typography className={classes.titleTips}>
          Dapatkan Extra Coins!
        </Typography>
        {!isTopUpForm && (
          <IconButton className={classes.iconButton} onClick={handleClose}>
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        )}
      </Box>
      <Grid container spacing={0}>
        <Grid item xs={6} container>
          <Grid item xs={12} className={classes.promoInfoTitleLeft}>
            <Typography className={classes.promoInfoTitleText} align="center">
              Jumlah Top Up
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={6} container>
          <Grid
            item
            xs={12}
            className={classes.promoInfoTitleRight}
            align="center"
          >
            <Typography className={classes.promoInfoTitleText}>
              Extra PopCoins
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Divider component="hr" className={classes.promoInfoTitleDivider} />
      {data?.map((item, index) => {
        return (
          <div key={index}>
            <Grid container spacing={0}>
              <Grid item xs={6} container>
                <Grid
                  item
                  xs={12}
                  className={
                    index === 0 || index % 2 === 0
                      ? classes.promoInfoContentLeft
                      : classes.promoInfoContentLeftLight
                  }
                  align="center"
                >
                  <Typography className={classes.promoInfoContentLeftText}>
                    {item?.maximumTransaction
                      ? `Rp${formatNumber(
                          item?.minimumTransaction,
                        )} - Rp${formatNumber(item.maximumTransaction)}`
                      : `Di atas ${formatNumber(item?.minimumTransaction)}`}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={6} container>
                <Grid
                  item
                  xs={12}
                  className={
                    index === 0 || index % 2 === 0
                      ? classes.promoInfoContentRight
                      : classes.promoInfoContentRightLight
                  }
                  align="center"
                >
                  <div className={classes.textPromoInfoPercentageWrapper}>
                    <Typography className={classes.textPromoInfoPercentage}>
                      {`+${item?.amount}${
                        item?.amountType === 'PERCENTAGE' ? '%' : ''
                      }`}
                    </Typography>
                    <Typography className={classes.textPromoInfoPercentageDesc}>
                      dari jumlah Top Up
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Divider
              component="hr"
              className={classes.promoInfoContentDivider}
            />
          </div>
        );
      })}
    </>
  );
};

export default TopUpPromoInfo;

import mock from 'src/utils/mock';

mock.onGet('/surveys/999999/surveyquestions').reply(200, {
  message: 'Success',
  code: 200,
  data: [
    {
      surveyQuestionId: 1,
      title: 'Favorite drink?',
      type: 'RADIO',
      options: ['Kopi', 'Teh', 'Air Mineral'],
    },
    {
      surveyQuestionId: 2,
      title: 'Favorite places?',
      type: 'CHECKBOX',
      options: ['Coffee Shop', 'Library', 'Gym'],
    },
    {
      surveyQuestionId: 3,
      title: 'Favorite Food?',
      type: 'RADIO',
      options: ['Ayam Geprek', 'Pizza', 'Sayur Mayur'],
    },
  ],
});

/**
 * type : daily questions
 */
mock.onGet('/surveys/3/dailyquestions').reply(200, {
  message: 'Success',
  code: 200,
  data: [
    {
      surveyQuestionId: 1,
      title: 'Favorite drink daily?',
      type: 'RADIO',
      options: ['Kopi', 'Teh', 'Air Mineral'],
    },
    {
      surveyQuestionId: 2,
      title: 'Favorite places?',
      type: 'RADIO',
      options: ['Coffee Shop', 'Library', 'Gym'],
    },
    {
      surveyQuestionId: 3,
      title: 'Favorite Food?',
      type: 'RADIO',
      options: ['Ayam Geprek', 'Pizza', 'Sayur Mayur'],
    },
  ],
});

mock.onGet('/surveys/3/dailyquestions').reply(200, {
  message: 'Success',
  code: 200,
  data: [
    {
      surveyQuestionId: 1,
      title: 'Pernah naik transportasi umum?',
      type: 'RADIO',
      options: ['Pernah', 'Tidak Pernah'],
    },
    {
      surveyQuestionId: 2,
      title: 'Seberapa sering menggunakan transportasi umum?',
      type: 'RADIO',
      options: ['Setiap hari', 'Seminggu sekali', 'Sangat jarang'],
    },
  ],
});

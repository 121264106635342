import mock from 'src/utils/mock';

mock.onGet('/projects?page=1&limit=10').reply(200, {
  code: 200,
  message: 'Success',
  page: 1,
  limit: 10,
  total: 13,
  data: [
    {
      id: 1,
      title: 'Brand Health Index',
      startDate: '2020-06-01T00:00:00.000Z',
      endDate: '2020-06-06T00:00:00.000Z',
      type: 'POPLITE_QUANTITATIVE',
      totalStudy: 3,
      status: 'PENDING',
    },
    {
      id: 2,
      title: 'Brand Health Index 2',
      startDate: '2020-06-01T00:00:00.000Z',
      endDate: '2020-06-06T00:00:00.000Z',
      type: 'POPLITE_QUANTITATIVE',
      totalStudy: 0,
      status: 'QUOTATION_&_AGREEMENT',
    },
    {
      id: 3,
      title: 'Brand Health Index 3',
      startDate: '2020-06-01T00:00:00.000Z',
      endDate: '2020-06-06T00:00:00.000Z',
      type: 'POPLITE_QUANTITATIVE',
      totalStudy: 3,
      status: 'SCHEDULED',
    },
    {
      id: 4,
      title: 'Brand Health Index 4',
      startDate: '2020-06-01T00:00:00.000Z',
      endDate: '2020-06-06T00:00:00.000Z',
      type: 'POPLITE_QUANTITATIVE',
      totalStudy: 0,
      status: 'STUDY_PROCESS',
    },
    {
      id: 5,
      title: 'Brand Health Index 5',
      startDate: '2020-06-01T00:00:00.000Z',
      endDate: '2020-06-06T00:00:00.000Z',
      type: 'POPLITE_QUANTITATIVE',
      totalStudy: 3,
      status: 'REPORT_PROCESS',
    },
    {
      id: 6,
      title: 'Brand Health Index 6',
      startDate: '2020-06-01T00:00:00.000Z',
      endDate: '2020-06-06T00:00:00.000Z',
      type: 'POPLITE_QUANTITATIVE',
      totalStudy: 0,
      status: 'REPORT_REVIEW',
    },
    {
      id: 7,
      title: 'Brand Health Index 7',
      startDate: '2020-06-01T00:00:00.000Z',
      endDate: '2020-06-06T00:00:00.000Z',
      type: 'POPLITE_QUANTITATIVE',
      totalStudy: 3,
      status: 'PROJECT_DONE',
    },
    {
      id: 8,
      title: 'Brand Health Index 8',
      startDate: '2020-06-01T00:00:00.000Z',
      endDate: '2020-06-06T00:00:00.000Z',
      type: 'POPLITE_QUANTITATIVE',
      totalStudy: 0,
      status: 'PENDING',
    },
    {
      id: 9,
      title: 'Brand Health Index 9',
      startDate: '2020-06-01T00:00:00.000Z',
      endDate: '2020-06-06T00:00:00.000Z',
      type: 'POPLITE_QUANTITATIVE',
      totalStudy: 3,
      status: 'PENDING',
    },
    {
      id: 10,
      title: 'Brand Health Index 10',
      startDate: '2020-06-01T00:00:00.000Z',
      endDate: '2020-06-06T00:00:00.000Z',
      type: 'POPLITE_QUANTITATIVE',
      totalStudy: 0,
      status: 'PENDING',
    },
  ],
});

mock.onGet('/projects?page=2&limit=10').reply(200, {
  code: 200,
  message: 'Success',
  page: 2,
  limit: 10,
  total: 13,
  data: [
    {
      id: 11,
      title: 'Brand Health Index 11',
      startDate: '2020-06-01T00:00:00.000Z',
      endDate: '2020-06-06T00:00:00.000Z',
      type: 'POPLITE_QUANTITATIVE',
      totalStudy: 3,
      status: 'PENDING',
    },
    {
      id: 12,
      title: 'Brand Health Index 12',
      startDate: '2020-06-01T00:00:00.000Z',
      endDate: '2020-06-06T00:00:00.000Z',
      type: 'POPLITE_QUANTITATIVE',
      totalStudy: 0,
      status: 'PENDING',
    },
    {
      id: 13,
      title: 'Brand Health Index 13',
      startDate: '2020-06-01T00:00:00.000Z',
      endDate: '2020-06-06T00:00:00.000Z',
      type: 'POPLITE_QUANTITATIVE',
      totalStudy: 0,
      status: 'PENDING',
    },
  ],
});

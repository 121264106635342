import { setAsPoplitePro } from './sessionActions'
import { setPopliteFeature } from './popliteDrawerActions';
import { getClientByB64UserEmail } from 'src/utils/remoteClient';
import { POPLITE_FEATURE } from 'src/utils/variables';


export const REMOTE_ACCESS_ACTIVE = 'REMOTE_ACCESS_ACTIVE';
export const REMOTE_ACCESS_INACTIVE = 'REMOTE_ACCESS_INACTIVE';

/** @param {string} payload - base64 user email */
export const activateRemoteClient = (payload) => (dispatch) => {
  const client = getClientByB64UserEmail(payload);

  if (!client?.expiredAt) return;

  dispatch({
    type: REMOTE_ACCESS_ACTIVE,
    remoteKey: payload,
    userEmail: client?.userEmail,
    sessionRemaining: new Date(client?.expiredAt).getTime() - Date.now(),
  });

  dispatch(setPopliteFeature(POPLITE_FEATURE));

  if (client?.isPoplitePro) {
    dispatch(setAsPoplitePro(client?.isPoplitePro));
  }
};

export const inactiveRemoteClient = () => (dispatch) => {
  dispatch({ type: REMOTE_ACCESS_INACTIVE });
};

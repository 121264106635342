import React from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { INTERCOM_APP_ID } from 'src/utils/variables';

function IntercomWidget() {
  const session = useSelector((state) => state.session);

  const renderIntercomWidget = () => {
    if (session?.loggedIn) {
      const { email, firstName, lastName, companyName, isPoplite, isDosenBinus } = session?.user;
      const userType = isPoplite ? 'POPLITE' : isDosenBinus ? 'POPLITE-DOSEN' : '';

      return (
        <script>
          {`
            window.Intercom('boot', {
              app_id: '${INTERCOM_APP_ID}',
              email: '${email}',
              name: '${firstName} ${lastName}',
              companyName: '${companyName}',
              category: '${userType}'
            });
          `}
        </script>
      );
    }

    return (
      <script>
        {`
          window.Intercom('boot', {
            app_id: '${INTERCOM_APP_ID}'
          });
        `}
      </script>
    );
  };

  return (
    <Helmet>
      <script>
        {`
        (function(){
          var w=window;
          var ic=w.Intercom;
          if(typeof ic==="function"){
            ic('reattach_activator');ic('update',w.intercomSettings);
          }else{
            var d=document;
            var i=function(){
              i.c(arguments);
            };
            i.q=[];
            i.c=function(args){
              i.q.push(args);
            };
            w.Intercom=i;
            var l=function(){
              var s=d.createElement('script');
              s.type='text/javascript';
              s.async=true;
              s.src='https://widget.intercom.io/widget/${INTERCOM_APP_ID}';
              var x=d.getElementsByTagName('script')[0];
              x.parentNode.insertBefore(s,x);
            };
            if(w.attachEvent){
              w.attachEvent('onload',l);
            }else{
              w.addEventListener('load',l,false);
            }
          }
        })();
        `}
      </script>
      {renderIntercomWidget()}
      <script>
        {`
          window.intercomSettings = {
            app_id: '${INTERCOM_APP_ID}',
            alignment: 'right',
            horizontal_padding: 20,
            vertical_padding:90,
            hide_default_launcher: true
          };
        `}
      </script>
    </Helmet>
  );
}

export default IntercomWidget;

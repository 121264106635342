import * as actionTypes from 'src/actions';

const initialState = null;

const criteriaGroupsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SET_CRITERIA_GROUPS: {
      return [...(state || []), ...payload];
    }
    default: {
      return state;
    }
  }
};

export default criteriaGroupsReducer;

/* eslint-disable no-undef */
import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import MomentUtils from '@date-io/moment';
import { Provider as StoreProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import ReactGA from 'react-ga';
import { theme } from './theme';
import reduxStore from './store';
import Routes from './routes';
import GoogleAnalytics from './components/GoogleAnalytics';
import IntercomWidget from './components/IntercomWidget';
import NpsWidget from './components/NpsWidget';
import ScrollReset from './components/ScrollReset';
import StylesProvider from './components/StylesProvider';
import SnackbarWrapper from './SnackbarWrapper';
import MouseFlow from './components/MouseFlow';

import './mixins/chartjs';
import './mixins/moment';
import './mixins/validate';
import './mixins/prismjs';
import './mock';
import './utils/firebaseService';

import './assets/scss/main.scss';

// Import css carousel files
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ConfirmationDialogProvider from './components/ConfirmationDialog/Provider';
import RouteChecker from './components/RouteChecker';
import { worker } from './services/mocks';

const isProd = process.env.REACT_APP_ENVIRONMENT === 'production';

if (process.env.REACT_APP_SENTRY_DSN && isProd) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

if (!isProd) worker.start({ onUnhandledRequest: 'bypass' });

if (process.env.REACT_APP_GA_MEASUREMENT_ID) {
  ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
}

const history = createBrowserHistory();
const { store, persistor } = reduxStore;

function App() {
  const direction = 'ltr';

  return (
    <StoreProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <StylesProvider direction={direction}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <ConfirmationDialogProvider>
                <Router history={history}>
                  {isProd && <MouseFlow />}

                  <ScrollReset />
                  <GoogleAnalytics />
                  <IntercomWidget />
                  <NpsWidget />
                  <Routes />
                  <RouteChecker />
                </Router>
                <SnackbarWrapper />
              </ConfirmationDialogProvider>
            </MuiPickersUtilsProvider>
          </StylesProvider>
        </ThemeProvider>
      </PersistGate>
    </StoreProvider>
  );
}

export default App;

/* eslint-disable no-useless-catch */
import CategoryListFMCG from 'src/assets/RetailAuditCategoryListFMCG.json';
import CategoryListNonFMCG from 'src/assets/RetailAuditCategoryListNonFMCG.json';
import PopCall from '../utils/popCall';

export const SET_RETAIL_AUDIT_RESOURCE = 'SET_RETAIL_AUDIT_RESOURCE';

export const setRetailAuditResource = () => {
  return async (dispatch) => {
    try {
      const popCall = new PopCall();

      const responseBrands = await popCall.get('/retailAudit/brands');
      const responseVariantTypes = await popCall.get(
        '/retailAudit/varianttypes',
      );
      const responseVariantDetails = await popCall.get(
        '/retailAudit/variantdetails',
      );

      const payload = {
        brands: [],
        categories: [],
        variantDetails: [],
        variantTypes: [],
      };

      // TODO: fetch data for categories
      if (responseBrands && responseBrands.data) {
        payload.brands = responseBrands.data.map((brand) => brand);
      }

      if (responseVariantDetails && responseVariantDetails.data) {
        payload.variantDetails = responseVariantDetails.data.map(
          (variantDetail) => variantDetail,
        );
      }

      if (responseVariantTypes && responseVariantTypes.data) {
        payload.variantTypes = responseVariantTypes.data.map(
          (variantType) => variantType,
        );
      }

      payload.categoryList = [];

      for (const categoryFmcg of Object.keys(CategoryListFMCG)) {
        const subCategoryItems = CategoryListFMCG[categoryFmcg];
        for (const subCategoryItem of subCategoryItems) {
          payload.categoryList.push(
            `FMCG, ${categoryFmcg}, ${subCategoryItem}`,
          );
        }
      }

      for (const categoryNonFmcg of Object.keys(CategoryListNonFMCG)) {
        const subCategoryItems = CategoryListNonFMCG[categoryNonFmcg];
        for (const subCategoryItem of subCategoryItems) {
          payload.categoryList.push(
            `Non-FMCG, ${categoryNonFmcg}, ${subCategoryItem}`,
          );
        }
      }

      return dispatch({
        type: SET_RETAIL_AUDIT_RESOURCE,
        payload,
      });
    } catch (err) {
      throw err;
    }
  };
};

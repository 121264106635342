import React, { Fragment } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import StarIcon from '@material-ui/icons/Star';
import Typography from '@material-ui/core/Typography';
import PopButton from 'src/components/PopButton';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: '#FFB808',
  },
  blueText: { color: '#00417D' },
  title: { marginBottom: 6 },
  subtitle: { fontWeight: 300, fontStyle: 'normal' },
  bodyPadding: {
    paddingBottom: theme.spacing(4),
  },
}));

const componentProps = {
  box: {
    borderRadius: 38,
    color: '#00417D',
    borderColor: '#00417D',
    border: 1,
    p: 2.5,
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.04)',
  },
  gridConfig: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
};

const GuestCreateProjectCard = ({ userRoles }) => {
  const classes = useStyles();

  function handleClickCreateProject() {
    window.location.replace(
      'https://info.populix.co/post/paket-hemat-populix',
    );
  }
  return (
    (userRoles && userRoles.includes('guest') && (
      <Box {...componentProps.box}>
        <Grid container>
          <Grid item {...componentProps.gridConfig}>
            <StarIcon className={classes.icon} />
          </Grid>
          <Grid
            item
            {...componentProps.gridConfig}
            className={classes.bodyPadding}
          >
            <Typography
              variant="h3"
              className={clsx(classes.blueText, classes.title)}
            >
              Interested in the Project?
            </Typography>
            <Typography
              variant="h5"
              className={clsx(classes.blueText, classes.subtitle)}
            >
              Let’s elevate your research with Populix!
            </Typography>
          </Grid>
          <Grid item {...componentProps.gridConfig}>
            <PopButton
              color="warning"
              variant="contained"
              fullWidth
              onClick={handleClickCreateProject}
            >
              Create Project
            </PopButton>
          </Grid>
        </Grid>
      </Box>
    )) ||
    Fragment
  );
};

export default GuestCreateProjectCard;

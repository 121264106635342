import React from 'react';
import { Route as RouteRR } from 'react-router-dom';
import { ApmRoute } from '@elastic/apm-rum-react';

export default function Route(props) {
  const isPoplite = props?.path?.includes('poplite');
  if (isPoplite) {
    return (
      <ApmRoute
        key={props.key}
        path={props.path}
        exact={props.exact}
        component={props.render}
      />
    );
  }
  return <RouteRR {...props} />;
}

import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  Avatar,
  colors,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
} from '@material-ui/core';
import CircleIcon from '@material-ui/icons/FiberManualRecordRounded';
import InfiniteScroll from 'react-infinite-scroll-component';
import PopulixCartIcon from 'src/assets/svg/icon-cart.svg';
import PopulixStickyIcon from 'src/assets/svg/icon-sticky.svg';
import PopulixInfoIcon from 'src/assets/svg/icon-info.svg';
import gradients from 'src/utils/gradients';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 565,
  },
  listItem: {
    padding: '16px 20px',
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
  },
  listContent: {
    width: '100%',
  },
  avatarBlue: {
    backgroundImage: gradients.blue,
  },
  avatarGreen: {
    backgroundImage: gradients.green,
  },
  avatarOrange: {
    backgroundImage: gradients.orange,
  },
  avatarIndigo: {
    backgroundImage: gradients.indigo,
  },
  arrowForwardIcon: {
    color: '#329BFF',
    fontSize: 14,
    marginRight: 5,
  },
  listItemText: {
    fontSize: 12,
    color: '#333333',
    fontWeight: 'bold',
  },
  listItemIcon: {
    minWidth: 38,
  },
  headerItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  headerContentItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  contentItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: '100%',
  }
}));

function NotificationList({
  notifications,
  className,
  onClick,
  handleLoadMoreNotifications,
  isLoadMore,
  loading,
  ...rest
}) {
  const classes = useStyles();

  const icons = {
    Survei: <img src={PopulixStickyIcon} alt="survey" />,
    Transaksi: <img src={PopulixCartIcon} alt="topup" />,
    Info: <img src={PopulixInfoIcon} alt="payment" />,
  };

  return (
    <List {...rest} className={clsx(classes.root, className)} disablePadding>
      <InfiniteScroll
        dataLength={notifications?.length}
        next={handleLoadMoreNotifications}
        hasMore={isLoadMore}
        loader={loading && <h4>Loading...</h4>}
      >
        {notifications.map((notification, i) => (
          <ListItem
            className={classes.listItem}
            style={{
              backgroundColor:
                (notification?.IsRead === 0)
                  ? colors.white
                  : 'rgba(181, 205, 255, 0.15)',
            }}
            component={RouterLink}
            divider={i < notifications.length - 1}
            key={notification.NotificationId}
            onClick={() => notification?.IsRead && onClick(notification?.NotificationId)}
            to="#"
          >
            <div className={classes.listContent}>
              <div className={classes.headerItem}>
                <div className={classes.headerContentItem}>
                  <ListItemIcon className={classes.listItemIcon}>
                    {icons[notification?.Type]}
                  </ListItemIcon>
                  <ListItemText secondary={notification?.Type} />
                </div>
                <div className={classes.headerContentItem}>
                  {notification?.IsRead === 1 && (
                    <CircleIcon className={classes.arrowForwardIcon} />
                  )}
                  <ListItemText secondary={moment(notification?.CreatedAt).fromNow()} />
                </div>
              </div>
              <ListItemText
                classes={{ primary: classes.listItemText }}
                primary={notification?.Title}
                primaryTypographyProps={{ variant: 'body1' }}
                secondary={notification?.Description}
              />
            </div>
          </ListItem>
        ))}
      </InfiniteScroll>
    </List>
  );
}

NotificationList.propTypes = {
  className: PropTypes.string,
  notifications: PropTypes.array.isRequired,
  onClick: PropTypes.func,
};

export default NotificationList;

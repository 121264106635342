/* eslint-disable no-else-return */
import moment from 'moment';
import { useLocation } from 'react-router';
import { popCall } from 'src/utils/popCall';
import {
  screeningTypeOptions,
  studyStatus,
  studyGroupTypeOptions,
  projectTypeOptions,
  STOP,
  OMNIBUS,
  chartColorsV2,
} from './variables';

// check whether arrays are equal in length and its elements
export function arraysEqual(arrA, arrB) {
  return (
    Array.isArray(arrA) &&
    Array.isArray(arrB) &&
    arrA.length === arrB.length &&
    arrA.every((val) => arrB.includes(val))
  );
}

export function addLeadingZeros(num, totalLength) {
  return String(num).padStart(totalLength, '0');
}

// expecting a date format. will return 20 Mar 2020
export function formatDate(date) {
  return moment(date).format('DD MMM YYYY');
}

export function formatTimestamp(date) {
  const nowDate = moment();
  const createdAtDate = moment(date);

  const diffMinute = nowDate.diff(createdAtDate, 'minutes');
  let timestampText = `${diffMinute} minute${diffMinute > 1 ? 's' : ''}`;

  if (diffMinute > 525600) {
    const diffYear = nowDate.diff(createdAtDate, 'years');
    timestampText = `${diffYear} year${diffYear > 1 ? 's' : ''}`;
  } else if (diffMinute > 43800) {
    const diffMonth = nowDate.diff(createdAtDate, 'months');
    timestampText = `${diffMonth} month${diffMonth > 1 ? 's' : ''}`;
  } else if (diffMinute > 10080) {
    const diffWeek = nowDate.diff(createdAtDate, 'weeks');
    timestampText = `${diffWeek} week${diffWeek > 1 ? 's' : ''}`;
  } else if (diffMinute > 1440) {
    const diffDay = nowDate.diff(createdAtDate, 'days');
    timestampText = `${diffDay} day${diffDay > 1 ? 's' : ''}`;
  } else if (diffMinute > 60) {
    const diffHour = nowDate.diff(createdAtDate, 'hours');
    timestampText = `${diffHour} hour${diffHour > 1 ? 's' : ''}`;
  }

  return timestampText;
}

/**
 * Format timestamp to day month year hour:minute format
 * @param {number} date
 * @param {boolean} is24Hour - default = `true`
 * @returns formattedDate
 */
export function formatDateHourMinute(date, is24Hour = true) {
  if (is24Hour) {
    return moment(date).format('DD MMMM YYYY HH:mm');
  }
  return moment(date).format('DD MMMM YYYY hh:mm A');
}

export function formatNumber(number, format = 'id-ID') {
  if (!number) return 0;
  return new Intl.NumberFormat(format).format(number);
}
export const dayDuration = (endDate, now = moment()) => {
  const duration = Math.round(moment.duration(moment(endDate) - now).asDays());
  return duration > 0 ? duration : 0;
};

export function formatRupiah(amount) {
  if (!amount) {
    return 'Rp 0';
  }

  return new Intl.NumberFormat('id-ID', {
    style: 'currency',
    minimumFractionDigits: 0,
    currency: 'IDR',
  }).format(amount);
}

export function isValidEmail(email) {
  return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email,
  );
}

export function isExistWhitelist(newWhitelistEmail, whitelistEmail) {
  const whitelistSplit = whitelistEmail?.split(';');
  if (Array.isArray(whitelistSplit)) {
    for (let i = 0; i < whitelistSplit.length; i += 1) {
      if (
        newWhitelistEmail.toString().trim().toLowerCase() ===
        whitelistSplit[i].toString().trim().toLowerCase()
      ) {
        return true;
      }
    }
  }
  return false;
}

export function isValidData(data) {
  if (
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      data,
    )
  ) {
    return true;
  } else if (data.match(/^[0-9]+$/)) {
    return true;
  } else {
    return false;
  }
}

export function capitalizeEachWord(words) {
  return words
    ?.toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
    .join(' ');
}

/**
 * The return value will be capitalized or not.
 * @param {string} screeningType - One of screeningTypeOptions
 * @param {bool} [uppercase] - An optional argument that will convert to lowercase
 */
export function localizedScreeningType(screeningType, uppercase = true) {
  const localizedScreeningTypeOption = screeningTypeOptions.find(
    (screeningTypeOption) => screeningTypeOption.value === screeningType,
  );
  const { alias, label } = localizedScreeningTypeOption;
  return uppercase ? (alias || label).toUpperCase() : alias || label;
}

/**
 * The return value will be capitalized or not.
 * @param {Object} study - Study object
 * @param {int} [uppercase] - An optional argument that will convert to lowercase
 */
export function localizedStudyStatus(study, uppercase = true) {
  const localizedStudyStatusOption = studyStatus.find(
    (studyGroupTypeOption) => studyGroupTypeOption.value === study.status,
  ).label;
  if (study.participantDone === study.requiredParticipants) {
    return 'FULFILLED';
  }
  if (
    localizedStudyStatusOption === STOP &&
    study.participantDone !== study.requiredParticipants
  ) {
    return 'UNFULFILLED';
  }
  return uppercase
    ? localizedStudyStatusOption.toUpperCase()
    : localizedStudyStatusOption;
}

/**
 * The return value will be a converted string.
 * @param {string} word - Word to convert
 * @param {string} [wordcase] - Case to convert to
 * @param {string} [fallbackCase] - Default wordcase in case of wordcase is not provided or unknown
 */
export function convertWord(word, wordcase, fallbackCase) {
  let newWord;
  if (wordcase === 'uppercase' || fallbackCase === 'uppercase') {
    newWord = word?.replace(/_/g, ' ').toUpperCase();
  } else if (wordcase === 'capitalize' || fallbackCase === 'capitalize') {
    newWord = capitalizeEachWord(word?.replace(/_/g, ' '));
  }
  return newWord;
}

/**
 * The return value will be uppercase or capitalized.
 * @param {string} status - One of study group status
 * @param {string} [wordcase] - An optional argument upper that will convert to uppercase
 */
export function localizedStudyGroupStatus(status, wordcase = 'uppercase') {
  const newStatus = convertWord(status, wordcase, 'uppercase');
  return newStatus;
}

/**
 * The default value will be capitalized.
 * @param {string} status - One of study group type
 * @param {string} [wordcase] - An optional argument 'uppercase' that will convert to uppercase
 */
export function localizedStudyGroupType(status, wordcase = 'capitalize') {
  let localizedStudyGroupOption;
  localizedStudyGroupOption = studyGroupTypeOptions.find(
    (studyGroup) => studyGroup.value === status,
  ).title;
  localizedStudyGroupOption = convertWord(
    localizedStudyGroupOption,
    wordcase,
    'capitalize',
  );
  return localizedStudyGroupOption;
}

/**
 * The the default value will be capitalized
 * @param {string} type - One of project type
 * @param {string} [wordcase] - An optional argument 'uppercase' that will convert to uppercase
 */
export function localizedProjectType(type, wordcase = 'capitalize') {
  let projectTypeOption;
  projectTypeOption = projectTypeOptions.find(
    (projectType) => projectType.value === type,
  )?.title;
  projectTypeOption = convertWord(projectTypeOption, wordcase, 'capitalize');
  return projectTypeOption;
}

/**
 * The return value will be true or false ( bool ).
 * @param {string} studyGroup - data of StudyGroup
 */
export const isScreeningAllUser = (studyGroup) => {
  let isScreeningTypeAllUser = false;
  if (
    studyGroup &&
    (studyGroup.type === 'Online Survey' ||
      studyGroup.type === 'ONLINE_SURVEY') &&
    studyGroup.studies &&
    studyGroup.studies[0] &&
    studyGroup.studies[0].screeningType === 'allUser'
  ) {
    isScreeningTypeAllUser = true;
  }
  return isScreeningTypeAllUser;
};

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

/**
 * Fetch study group data
 * @param {Object} params Function Params
 * @param {Number} params.currentPage Current Page
 * @param {Number} params.limitPerPage Limit data per page
 * @param {String} [params.search] Search query by study group or study title
 * @param {Number} [params.projectId] Project Id
 * @param {Object} [params.filters] Object of filter data
 * @return {Promise<{ studyGroups: Array<Object>, page: Number, limit: Number, total: Number }>}
 * Fetch result
 */
export const fetchStudyGroups = async ({
  currentPage = 0,
  limitPerPage = 10,
  projectId,
  search,
  filters,
  isPoplite = false,
}) => {
  const url = isPoplite
    ? '/researchers/popliteStudyGroups'
    : 'researchers/studyGroups';
  let queryStrings = `?page=${currentPage + 1}&limit=${limitPerPage}`;

  if (projectId) {
    queryStrings += `&projectId=${projectId}`;
  }
  if (search) {
    queryStrings += `&search=${search}`;
  }
  if (filters) {
    const filterKeys = Object.keys(filters);
    filterKeys.forEach((filterKey) => {
      const filterValue = filters[filterKey];
      if (filterValue) {
        queryStrings += `&filters[${filterKey}]=${filterValue}`;
      }
    });
  }

  const response = await popCall.get(`${url}${queryStrings}`);

  // Data shaping
  const _studyGroups = response.data;
  _studyGroups.forEach((studyGroup) => {
    const _studyGroup = studyGroup;
    _studyGroup.status = [];
    _studyGroup.totalParticipants = _studyGroup.studies
      .reduce((a, b) => a + b.requiredParticipants, 0)
      .toLocaleString();
    _studyGroup.startDate = _studyGroup.startDate
      ? formatDate(_studyGroup.startDate)
      : '-';
    _studyGroup.endDate = _studyGroup.endDate
      ? formatDate(_studyGroup.endDate)
      : '-';
    _studyGroup.isOpen = false;
    studyGroup.studies.forEach((study) => {
      const _study = study;
      const percentage = parseFloat(
        (_study.participantDone / _study.requiredParticipants) * 100,
      ).toFixed(2);
      _study.studyPercentage = !(parseFloat(percentage) % 1)
        ? parseFloat(percentage).toFixed(0)
        : parseFloat(percentage).toFixed(2);
      _studyGroup.status.push(_study.status);
      _study.participantDone =
        _study.participantDone && _study.participantDone.toLocaleString();
      _study.requiredParticipants =
        _study.requiredParticipants &&
        _study.requiredParticipants.toLocaleString();
    });
    _studyGroup.type =
      (_studyGroup.type === 'ONLINE_SURVEY' && 'Online Survey') ||
      (_studyGroup.type === 'DAILY_QUESTION' && 'Daily Question') ||
      (_studyGroup.type === 'FGD' && 'Forum Group Discussion') ||
      (_studyGroup.type === 'IDI' && 'In-Depth Interview');
    _studyGroup.status =
      (_studyGroup?.StudyGroupAdditionalInfo?.isDraft && 'Pending') ||
      (_studyGroup.status.includes('NEED_CONFIRMATION') && 'Pending') ||
      (_studyGroup.status.includes('ACTIVE') && 'In Progress') ||
      (_studyGroup.status.every((value) => value === 'STOP') && 'Completed');
    _studyGroup.ProjectName = _studyGroup.ProjectName
      ? _studyGroup.ProjectName
      : '-';
  });

  return {
    studyGroups: _studyGroups,
    page: response.page,
    limit: response.limit,
    total: response.total,
  };
};

/**
 * Change phone number to +62
 * @param {string} phone - phone number
 */
export function formatPhoneID(phone) {
  let phoneID = phone;
  if (phoneID.charAt(0) === '0') {
    phoneID = phoneID.replace('0', '+62');
  }
  return phoneID;
}

/**
 *
 * @param {Object} project project object
 */
export function isOmnibusProject(project) {
  return (project && project.type && project.type === OMNIBUS) || false;
}

const MonthlyExpensesOptions = {
  '> Rp 20.000.000,-': {
    topValue: 9999999999,
    bottomValue: 20000001,
    label: 'A',
  },
  'Rp 15.000.001 – Rp 20.000.000,-': {
    topValue: 20000000,
    bottomValue: 15000001,
    label: 'A',
  },
  'Rp 10.000.001 – Rp 15.000.000,-': {
    topValue: 15000000,
    bottomValue: 10000001,
    label: 'A',
  },
  'Rp 9.000.001 – Rp 10.000.000,-': {
    topValue: 10000000,
    bottomValue: 9000001,
    label: 'A',
  },
  'Rp 8.000.001 – Rp 9.000.000,-': {
    topValue: 9000000,
    bottomValue: 8000001,
    label: 'A',
  },
  'Rp 7.000.001 – Rp 8.000.000,-': {
    topValue: 8000000,
    bottomValue: 7000001,
    label: 'A',
  },
  'Rp 6.000.001 – Rp 7.000.000,-': {
    topValue: 7000000,
    bottomValue: 6000001,
    label: 'A',
  },
  'Rp 5.000.001 – Rp 6.000.000,-': {
    topValue: 6000000,
    bottomValue: 5000001,
    label: 'B',
  },
  'Rp 4.000.001 – Rp 5.000.000,-': {
    topValue: 5000000,
    bottomValue: 4000001,
    label: 'B',
  },
  'Rp 3.000.001 – Rp 4.000.000,-': {
    topValue: 4000000,
    bottomValue: 3000001,
    label: 'C',
  },
  'Rp 2.700.001 – Rp 3.000.000,-': {
    topValue: 3000000,
    bottomValue: 2700001,
    label: 'C',
  },
  'Rp 2.000.001 – Rp 2.700.000,-': {
    topValue: 2700000,
    bottomValue: 2000001,
    label: 'C',
  },
  'Rp 1.750.001 – Rp 2.000.000,-': {
    topValue: 2000000,
    bottomValue: 1750001,
    label: 'D',
  },
  'Rp 1.500.001 – Rp 1.750.000,-': {
    topValue: 1750000,
    bottomValue: 1500001,
    label: 'D',
  },
  'Rp 1.300.001 – Rp 1.500.000,-': {
    topValue: 1500000,
    bottomValue: 1300001,
    label: 'D',
  },
  'Rp 900.001 – Rp 1.300.000,-': {
    topValue: 1300000,
    bottomValue: 900001,
    label: 'E',
  },
  'Rp 750.001 – Rp 900.000,-': {
    topValue: 900000,
    bottomValue: 750001,
    label: 'E',
  },
  '< Rp 750.000,-': {
    topValue: 750000,
    bottomValue: 0,
    label: 'E',
  },
};

export const sanitizeMonthlyExpensesRange = (monthlyExpensesLabel) => {
  const { bottomValue, topValue } = MonthlyExpensesOptions[
    monthlyExpensesLabel
  ];

  return `${bottomValue}-${topValue}`;
};

/**
 * The return value will be a converted string word to string sentence.
 * @param {string} word - Word to convert
 */
export function splitCapitalizedWord(word) {
  let newWord = '';

  if (word && word.length > 0) {
    for (let i = 0; i < word.length; i += 1) {
      if (word[i] === word[i].toUpperCase() && i !== 0) {
        newWord += ' ';
      }
      newWord += word[i];
    }
  }

  return newWord;
}

export function getChartColorsByAmount(amountToTake) {
  const chartColorLength = chartColorsV2.length;
  const colorDistance = chartColorLength / amountToTake;
  const colorsToDisplay = [];

  if (amountToTake < 1 || amountToTake > chartColorLength - 1) {
    colorsToDisplay.push(...chartColorsV2);
  } else if (amountToTake === 1) {
    colorsToDisplay.push(chartColorsV2[0]);
  } else if (amountToTake === 2) {
    colorsToDisplay.push(chartColorsV2[0]);
    colorsToDisplay.push(chartColorsV2[chartColorLength - 1]);
  } else if (amountToTake > 2) {
    let colorIndex = 0;

    for (let index = 0; index < amountToTake - 1; index++) {
      if (Math.round(colorIndex) >= chartColorLength) {
        colorsToDisplay.push(chartColorsV2[chartColorLength - 1]);
      } else {
        colorsToDisplay.push(chartColorsV2[Math.round(colorIndex)]);
      }
      colorIndex += colorDistance;
    }
    colorsToDisplay.push(chartColorsV2[chartColorLength - 1]);
  }

  return colorsToDisplay;
}

export function deviceTypeParser(deviceType) {
  if (deviceType.trim().toLowerCase() === 'ios') {
    return 'iOS';
  }

  return `${deviceType.charAt(0).toUpperCase()}${deviceType.slice(1)}`;
}

export async function fetchRetry(cbAsync, attempt = 5) {
  try {
    const response = await cbAsync();

    return response;
  } catch (err) {
    if (attempt > 0) return fetchRetry(cbAsync, attempt - 1);
    throw err;
  }
}

/**
 * Change number to phone number format
 * @param {string} phone - phone number
 */
export function formatPhone(phone, countryCode = 'ID') {
  switch (countryCode) {
    case 'ID':
      if (phone.charAt(0) === '0') {
        return phone.replace('0', '+62');
      }

      if (phone.toString().substring(0, 2) === '62') {
        return `+${phone.toString()}`;
      }

      return `+62${phone.toString()}`;
    default:
  }
}

export function redirectToWhatsapp(message, phoneNumber = '6282113025183') {
  window.open(
    `https://wa.me/${phoneNumber}${message ? `?text=${message}` : ''}`,
    '_blank',
  );
}

export function retryLoader(lazyComponent, attemptsLeft) {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (attemptsLeft === 1) {
            reject(error);
            return;
          }
          retryLoader(lazyComponent, attemptsLeft - 1).then(resolve, reject);
        }, 1500);
      });
  });
}

const notCompanyEmailRegex = /@gmail.*|@yahoo.*|@mail.*|@outlook.*|@hotmail.*|@live.*|@*.ac.id/;
export function checkUserIsCorporate(userEmail) {
  return !notCompanyEmailRegex.test(userEmail);
}

const questionproRegex = /questionpro.com/;
export function isQuestionproUrl(url) {
  return questionproRegex.test(url);
}

export function omit(object, keysToOmit = []) {
  const clone = { ...object };

  // eslint-disable-next-line no-restricted-syntax
  for (const key of keysToOmit) {
    if (key in clone) delete clone[key];
  }

  return clone;
}

export const urlRegex = /((https?):\/\/)?(www\.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?.*$/;

export const generateResToCsvFile = ({ response, name = 'report' }) => {
  const rows = response.split('\n').map((line) => line.split(';'));
  const headers = rows[0];
  const data = rows.slice(1).map((row) => {
    const rowData = {};
    headers.forEach((header, index) => {
      rowData[header.trim()] = row[index] ? row[index].trim() : ''; // Assign empty string if value is undefined
    });
    return rowData;
  });

  const csvContent =
    'data:text/csv;charset=utf-8,' +
    headers.join(',') +
    '\n' +
    data
      .map((row) => headers.map((header) => row[header]).join(','))
      .join('\n');

  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', `${name}.csv`);
  document.body.appendChild(link);
  link.click();
};

export const capitalizeFirstLetter = (string) => {
  if (!string) {
    return '';
  }

  const result = string
    .toLowerCase()
    .replace(/_/g, ' ') // Replace underscores with spaces
    .replace(/\b\w/g, (match) => match.toUpperCase()); // Capitalize first letter of each word

  return result;
};

// expecting a date format. will return 20/01/2024
export const dateMonthYear = (date) =>
  date ? moment(date).format('DD/MMM/YYYY') : '-';

import * as actionTypes from 'src/actions';

const initialState = {
  data: [],
};

const poplitePopcoinsPromoReducer = (state = initialState, actions) => {
  const { payload, type } = actions;

  switch (type) {
    case actionTypes.SET_TOP_UP_POPCOINS_PROMO:
      return {
        ...state,
        data: payload,
      };
    default:
      return state;
  }
};

export default poplitePopcoinsPromoReducer;

import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from 'src/reducers';
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { resourcesApi } from 'src/reducers/slice/resourceApiSlice';
import popApi from '../services';

const isDev = process.env.NODE_ENV === 'development';

const persistConfig = {
  key: 'populix',
  storage,
  whitelist: ['session'],
};

const store = configureStore({
  reducer: persistReducer(persistConfig, rootReducer),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
      .concat(popApi?.middleware || [])
      .concat(resourcesApi?.middleware || []),
  devTools: isDev,
});

setupListeners(store.dispatch);

const persistor = persistStore(store);

export default {
  persistor,
  store,
};

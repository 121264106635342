export const SET_POPLITE_PROMO = 'SET_POPLITE_PROMO';

export const setPoplitePromo = (data = {}, selectedPromoCode = '') => (
  dispatch,
) => {
  dispatch({
    type: SET_POPLITE_PROMO,
    payload: {
      data,
      selectedPromoCode,
    },
  });
};

import { withStyles } from '@material-ui/styles';
import { lighten } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { buttonColors } from 'src/utils/variables';

const OrangeButton = withStyles((theme) => ({
  root: {
    color: buttonColors.white,
    backgroundColor: buttonColors.orange,
    '&:hover': {
      backgroundColor: lighten(buttonColors.orange, 0.1),
    },
    padding: '9px 50px',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 600,
    textTransform: 'none',
    [theme.breakpoints.down('xs')]: {
      fontSize: 11,
      padding: '8px 12px',
    },
  },
}))(Button);

const GreenButton = withStyles((theme) => ({
  root: {
    color: buttonColors.white,
    backgroundColor: buttonColors.green,
    '&:hover': {
      backgroundColor: lighten(buttonColors.green, 0.1),
    },
    '&:disabled': {
      backgroundColor: lighten(buttonColors.gray, 0.5),
      color: buttonColors.white,
    },
    padding: '9px 24px',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 600,
    textTransform: 'none',
    [theme.breakpoints.down('xs')]: {
      fontSize: 11,
      padding: '8px 12px',
    },
  },
}))(Button);

const RedButton = withStyles((theme) => ({
  root: {
    color: buttonColors.white,
    backgroundColor: buttonColors.red,
    '&:hover': {
      backgroundColor: lighten(buttonColors.red, 0.1),
    },
    '&:disabled': {
      backgroundColor: lighten(buttonColors.gray, 0.5),
    },
    padding: '9px 24px',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 600,
    textTransform: 'none',
    [theme.breakpoints.down('xs')]: {
      fontSize: 11,
      padding: '8px 12px',
    },
  },
}))(Button);

const BlueButton = withStyles((theme) => ({
  root: {
    color: buttonColors.white,
    backgroundColor: buttonColors.blue,
    '&:hover': {
      backgroundColor: lighten(buttonColors.blue, 0.1),
    },
    '&:disabled': {
      backgroundColor: lighten(buttonColors.gray, 0.5),
      color: buttonColors.white,
    },
    padding: '9px 24px',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 600,
    textTransform: 'none',
    [theme.breakpoints.down('xs')]: {
      fontSize: 11,
      padding: '8px 12px',
    },
  },
}))(Button);

const OutlinedBlueButton = withStyles((theme) => ({
  root: {
    color: buttonColors.blue,
    backgroundColor: buttonColors.white,
    '&:hover': {
      backgroundColor: lighten(buttonColors.blue, 0.1),
      color: buttonColors.white,
    },
    '&:disabled': {
      backgroundColor: 'transparent',
      color: buttonColors.grey,
      borderColor: buttonColors.gray,
    },
    padding: '9px 24px',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 600,
    textTransform: 'none',
    [theme.breakpoints.down('xs')]: {
      fontSize: 11,
      padding: '8px 12px',
    },
    border: '1px solid #329BFF',
  },
}))(Button);

const GrayButton = withStyles((theme) => ({
  root: {
    color: buttonColors.white,
    backgroundColor: buttonColors.gray,
    '&:hover': {
      backgroundColor: lighten(buttonColors.gray, 0.1),
    },
    padding: '9px 24px',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 600,
    textTransform: 'none',
    [theme.breakpoints.down('xs')]: {
      fontSize: 11,
      padding: '8px 12px',
    },
  },
}))(Button);

const OutlinedButton = withStyles((theme) => ({
  root: {
    color: buttonColors.gray,
    backgroundColor: buttonColors.white,
    '&:hover': {
      borderColor: lighten(buttonColors.blue, 0.1),
      color: lighten(buttonColors.blue, 0.1),
      backgroundColor: buttonColors.white,
    },
    padding: '6px 16px',
    fontSize: '12px',
    lineHeight: '18px',
    fontWeight: 300,
    textTransform: 'none',
    boxShadow: 'none',
    border: '1px solid #BDBDBD',
    [theme.breakpoints.down('xs')]: {
      fontSize: 11,
      padding: '8px 12px',
    },
  },
}))(Button);

export {
  OrangeButton,
  GreenButton,
  RedButton,
  BlueButton,
  GrayButton,
  OutlinedBlueButton,
  OutlinedButton,
};

import * as React from 'react';
import firebase from 'firebase';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Popover,
  Typography,
  ButtonBase,
  Dialog,
  Divider,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import { ReactComponent as SupportAgentIcon } from 'src/assets/svg/support-agent.svg';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import EventIcon from '@material-ui/icons/Event';
import * as yup from 'yup';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { getFormikMuiFieldProps } from 'src/utils/formik';
import { useSnackbar } from 'src/utils/snackbar';
import { popCall, getErrorMessage } from 'src/utils/popCall';
import { checkUserIsCorporate } from 'src/utils/helper';

const SCHEDULE_MEETING_URL = 'https://meetings.hubspot.com/arrayan';

const useStyles = makeStyles((theme) => ({
  button: {
    height: 40,
    padding: '10px 12px',
    borderRadius: theme.spacing(9),
    marginRight: theme.spacing(1.5),
    backgroundColor: '#002B5A',
    border: '1px solid #002B5A',
    '&:hover': {
      border: '1px solid #ffffff',
    },
    '& .MuiButton-startIcon': {
      marginLeft: 0,
      marginRight: 5,
    },
  },
  popover: {
    '& .MuiPopover-paper': {
      width: 320,
      padding: 20,
      borderRadius: 24,
    },
    '& .contact-expert-popover__title': {
      color: '#4F4F4F',
      fontWeight: 600,
    },
    '& .contact-expert-popover__caption': {
      marginTop: 4,
      marginBottom: 20,
    },
    '& .contact-expert-popover__actions': {
      display: 'flex',
      flexDirection: 'column',
      gap: 8,
    },
    '& .contact-expert-popover__button': {
      width: '100%',
      fontSize: 12,
      fontWeight: 600,
      color: '#333333',
      padding: '18px 15px',
      border: '1px solid #F2F2F2',
      borderRadius: 4,
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      position: 'relative',
    },
    '& .contact-expert-popover__button-icon': {
      color: '#00417D',
      marginRight: 12,
    },
    '& .contact-expert-popover__button-chevron-icon': {
      position: 'absolute',
      right: 15,
    },
  },
  dialog: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    width: 560,
    padding: 24,

    '& .contact-expert-dialog__title': {
      fontWeight: 600,
      fontSize: 20,
      color: '#333333',
    },
    '& .contact-expert-dialog__sub-title': {
      fontWeight: 600,
      fontSize: 14,
      color: '#333333',
    },
    '& .contact-expert-dialog__input-container': {
      display: 'flex',
      flexDirection: 'column',
      gap: 20,
    },
    '& .contact-expert-dialog__field': {
      display: 'flex',
      flexDirection: 'column',
      gap: 12,
    },
    '& .contact-expert-dialog__actions': {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: 12,
    },
    '& .contact-expert-dialog__btn': {
      padding: '10px 20px',
    },
    '& .contact-expert-dialog__btn-send': {
      color: 'white',
      backgroundColor: theme.palette.success.main,

      '&:hover': {
        backgroundColor: theme.palette.success.dark,
      },
    },
  },
}));

const contactSchema = yup.object().shape({
  email: yup.string().email().required().label('Email'),
  phoneNumber: yup
    .string()
    .matches(/^([1-9]{1})(\d*)$/, 'Invalid phone number')
    .min(9)
    .max(13)
    .required()
    .label('Phone number'),
  subject: yup.string().required().label('Subject'),
  message: yup.string().required().label('Message'),
});

const initialContact = {
  email: '',
  phoneNumber: '',
  subject: '',
  message: '',
};

/** @param {typeof initialContact} formData */
async function sendEmail(formData) {
  return popCall.post('/popliteAMEmails', {
    phoneNumber: ['+62', formData.phoneNumber].join(''),
    emailSubject: formData.subject,
    emailMessage: formData.message,
  });
}

function DialogContactExpert(props) {
  const classes = useStyles();
  const snackbar = useSnackbar();
  const user = useSelector((state) => state.session?.user);
  const formik = useFormik({
    initialValues: {
      ...initialContact,
      email: user?.email,
      phoneNumber: user?.phoneNumber?.replace('+62', ''),
    },
    validationSchema: contactSchema,
    onSubmit: handleSubmitContact,
  });

  async function handleSubmitContact(values, { resetForm }) {
    try {
      await sendEmail(values);

      resetForm();
      props.onClose();

      snackbar({
        type: 'success',
        message: 'Email ke Poplite Expert berhasil terkirim',
      });
    } catch (error) {
      snackbar({
        type: 'error',
        message: getErrorMessage(error),
      });
    }
  }

  function handleClose() {
    formik.resetForm();
    props.onClose();
  }

  return (
    <Dialog
      {...props}
      onClose={handleClose}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <form onSubmit={formik.handleSubmit} className={classes.dialog}>
        <Typography className="contact-expert-dialog__title">
          Email ke Poplite Expert
        </Typography>

        <Divider />

        <div className="contact-expert-dialog__input-container">
          <Typography className="contact-expert-dialog__sub-title">
            Beritahu kami tentang diri Anda
          </Typography>

          <div className="contact-expert-dialog__field">
            <TextField
              disabled
              fullWidth
              label="Email"
              variant="outlined"
              {...getFormikMuiFieldProps(formik, 'email')}
            />

            <TextField
              fullWidth
              label="Nomor Handphone"
              variant="outlined"
              InputProps={{
                pattern: '[0-9]*',
                inputMode: 'tel',
                startAdornment: (
                  <InputAdornment position="start">+62</InputAdornment>
                ),
              }}
              {...getFormikMuiFieldProps(formik, 'phoneNumber')}
            />
          </div>
        </div>

        <div className="contact-expert-dialog__input-container">
          <Typography className="contact-expert-dialog__sub-title">
            Apa yang ingin Anda diskusikan?
          </Typography>

          <div className="contact-expert-dialog__field">
            <TextField
              fullWidth
              label="Subyek Email"
              variant="outlined"
              {...getFormikMuiFieldProps(formik, 'subject')}
            />

            <TextField
              multiline
              rows={5}
              fullWidth
              label="Pesan"
              variant="outlined"
              {...getFormikMuiFieldProps(formik, 'message')}
            />
          </div>
        </div>

        <Divider />

        <div className="contact-expert-dialog__actions">
          <Button
            type="reset"
            onClick={handleClose}
            className="contact-expert-dialog__btn"
          >
            Kembali
          </Button>

          <Button
            type="submit"
            disabled={formik.isSubmitting}
            className="contact-expert-dialog__btn contact-expert-dialog__btn-send"
          >
            Kirim Email
          </Button>
        </div>
      </form>
    </Dialog>
  );
}

export function LinkScheduleMeeting(props) {
  const [meetingUrl, setMeetingUrl] = React.useState(SCHEDULE_MEETING_URL);

  React.useEffect(() => {
    firebase
      .database()
      .ref('/researcherStaging/amScheduleMeeting')
      .on('value', (snapshot) => {
        setMeetingUrl(snapshot.val()?.url);
      });
  }, []);

  return <a href={meetingUrl} target="_blank" {...props} />;
}

function PopoverContactExpert(props) {
  const user = useSelector((state) => state.session?.user);
  const isCompanyEmail = checkUserIsCorporate(user?.email);
  const isShownScheduleDemo = isCompanyEmail && !user?.hasLaunchedSurvey;

  return (
    <Popover
      {...props}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Typography className="contact-expert-popover__title">
        Kontak Expert
      </Typography>
      <Typography className="contact-expert-popover__caption">
        Mari bicara dengan Poplite Expert untuk mempelajari lebih lanjut tentang
        produk ini.
      </Typography>

      <div className="contact-expert-popover__actions">
        {isShownScheduleDemo ? (
          <LinkScheduleMeeting className="contact-expert-popover__button">
            <EventIcon className="contact-expert-popover__button-icon" />
            <span>Jadwalkan Demo Produk</span>
            <ChevronRightIcon className="contact-expert-popover__button-chevron-icon" />
          </LinkScheduleMeeting>
        ) : null}

        <ButtonBase
          className="contact-expert-popover__button"
          onClick={props.onOpenContact}
        >
          <MailOutlineIcon className="contact-expert-popover__button-icon" />
          Email ke Poplite Expert
          <ChevronRightIcon className="contact-expert-popover__button-chevron-icon" />
        </ButtonBase>
      </div>
    </Popover>
  );
}

export function PopliteContactExpert(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClosePopover() {
    setAnchorEl(null);
  }

  function handleOpenContact() {
    handleClosePopover();
    props.toggleOpenContact();
  }

  return (
    <>
      <Button
        startIcon={<SupportAgentIcon />}
        className={classes.button}
        variant="contained"
        color="primary"
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        Kontak Expert
      </Button>

      <PopoverContactExpert
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        className={classes.popover}
        onOpenContact={handleOpenContact}
      />

      <DialogContactExpert
        open={props.openContact}
        onClose={props.toggleOpenContact}
      />
    </>
  );
}

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ChecklistIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.9708 5.58748L13.7958 4.41248L8.5125 9.69581L9.6875 10.8708L14.9708 5.58748ZM18.5042 4.41248L9.6875 13.2291L6.20417 9.75414L5.02917 10.9291L9.6875 15.5875L19.6875 5.58748L18.5042 4.41248ZM0.3125 10.9291L4.97083 15.5875L6.14583 14.4125L1.49583 9.75414L0.3125 10.9291Z" fill="#329BFF"/>
      </svg>
    </SvgIcon>
  );
};

export default ChecklistIcon;

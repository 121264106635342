import React from 'react';
import ReactGA from 'react-ga';
import { useDispatch } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/styles';

import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { exampleResultURL } from 'src/utils/variables';
import imageExampleStudy from 'src/assets/images/see-example-poplite.png';
import { useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(1),
    paddingTop: 20,
    minWidth: 237,
    [theme.breakpoints.between('lg', theme.breakpoints.hlg)]: {
      minWidth: 200,
    },
    minHeight: 270,
  },
  card: {
    border: '1px solid #F2F2F2',
    padding: theme.spacing(2),
  },
  textTitle: {
    fontSize: '12px',
    fontWeight: 600,
    color: '#00417D',
    lineHeight: '20px',
    marginBottom: '10px',
    whiteSpace: 'break-spaces',
    minWidth: 220,
    [theme.breakpoints.between('lg', theme.breakpoints.hlg)]: {
      minWidth: 100,
    },
  },
  previewImage: {
    width: '100%',
    display: 'block',
    maxWidth: '350px',
    marginBottom: theme.spacing(1.25),
  },
  previewButton: {
    width: '100%',
    fontSize: 10,
    textTransform: 'none',
    padding: theme.spacing(1),
  },
}));

export function PopliteSeeResultCard() {
  const classes = useStyles();
  const dispatch = useDispatch();
  
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const _onPreview = () => {
    if (process.env.REACT_APP_GA_MEASUREMENT_ID) {
      ReactGA.event({
        category: 'Dashboard - Project Poplite',
        action: 'project_example',
      });
    }

    const redirectURI =
      process.env.REACT_APP_ENVIRONMENT === 'production'
        ? exampleResultURL.production
        : exampleResultURL.qa;

    window.open(redirectURI, '_blank');
  };

  return (
    <div className={!isMobile && classes.wrapper}>
      <Card className={classes.card} elevation={0}>
        <img
          src={imageExampleStudy}
          alt="example-study"
          className={classes.previewImage}
        />
        <Typography className={classes.textTitle}>
          Penasaran hasil survei Poplite seperti apa?
        </Typography>
        <Box display="flex" justifyContent="center">
          <Button
            id="see_example"
            variant="outlined"
            color="secondary"
            disableElevation
            onClick={_onPreview}
            className={classes.previewButton}
          >
            Lihat Contoh Hasil
          </Button>
        </Box>
      </Card>
    </div>
  );
}

export default PopliteSeeResultCard;

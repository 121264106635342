import firebase, { remoteConfig } from 'firebase';
import reduxStore from '../store';
import { setFeatureFlag } from '../actions/featureFlagActions';
import { setRemoteConfig } from '../actions/remoteConfigActions';
import { showSnackbar } from '../actions/snackbarActions';

const config = {
  clientId:
    '492166602200-h8eidtlbtqrundl8e1us1dh11os8tmgq.apps.googleusercontent.com',
  appId: '1:492166602200:android:195c4196f6d530aeb326ad',
  apiKey: 'AIzaSyCUWtB4Zo8jRTgh39vHJ5kd1cn5voDZ2Xk',
  messagingSenderId: '492166602200',
  projectId: 'populix-android-app',
  persistence: true,
  databaseURL: 'https://populix-android-app.firebaseio.com/',
};

const { store } = reduxStore;

(() => {
  const devFeatureFlag = {
    showNewSES: {
      isActive: true,
    },
    oldBlastAPI: {
      isActive: false,
    },
    intercomWidget: {
      isActive: true,
    },
    popliteSignup: {
      isActive: true,
    },
    microservices: {
      surveybuilder: {
        isActive: false,
      },
    },
  };

  const devRemoteConfig = {
    poplite_register_variant: 'option1',
  };

  if (['production', 'staging'].includes(process.env.REACT_APP_ENVIRONMENT)) {
    firebase.initializeApp(config);

    firebase
      .database()
      .ref('/')
      .on('value', (snapshot) => {
        const payload = snapshot.val();
        if (payload) {
          let featureFlag = {};
          const {
            BEProd,
            BEStaging,
            researcherProd,
            researcherStaging,
            staging,
            production,
          } = payload;
          if (process.env.REACT_APP_ENVIRONMENT === 'production') {
            const {
              showNewSES,
              intercomWidget,
              microservices,
              sosmedAuth,
              isHideImagePicker,
            } = BEProd || {};
            featureFlag = {
              ...(researcherProd || {}),
              showNewSES,
              intercomWidget,
              microservices,
              sosmedAuth,
              isHideImagePicker,
              disableProjectPage: production?.disableProjectPage,
              disableStudyPage: production?.disableStudyPage,
            };
          } else if (process.env.REACT_APP_ENVIRONMENT === 'staging') {
            const {
              showNewSES,
              intercomWidget,
              microservices,
              sosmedAuth,
              isHideImagePicker,
            } = BEStaging || {};

            featureFlag = {
              ...(researcherStaging || {}),
              showNewSES,
              intercomWidget,
              microservices,
              sosmedAuth,
              isHideImagePicker,
              disableProjectPage: staging?.disableProjectPage,
              disableStudyPage: staging?.disableStudyPage,
            };
          } else {
            featureFlag = devFeatureFlag;
          }

          store.dispatch(setFeatureFlag(featureFlag));
        }
      });

    remoteConfig()
      .fetchAndActivate()
      .then(() => {
        const remoteConfigParams = remoteConfig().getAll();
        store.dispatch(setRemoteConfig(remoteConfigParams));
      })
      .catch(() => {
        store.dispatch(
          showSnackbar('Error while getting page variant', 'error'),
        );
      });
  } else {
    store.dispatch(setFeatureFlag(devFeatureFlag));
    store.dispatch(setRemoteConfig(devRemoteConfig));
  }
})();

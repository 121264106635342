import * as actionTypes from 'src/actions';

const initialState = {
  code: '',
  createdAt: null,
  status: '',
  cashAmount: 0,
  balanceAmount: 0,
  expiryDate: null,
  paymentLink: '',
  requiredParticipants: 0,
  totalQuestions: 0,
  transactionAmount: 0,
  promoAmount: 0,
  balanceAmount: 0,
  // top up state,
  isOpenTopUpInput: false,
};

const popliteTrxReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SET_TRX_POPLITE: {
      return {
        ...state,
        ...payload,
      };
    }
    case actionTypes.SET_OPEN_TOPUP_INPUT: {
      return {
        ...state,
        isOpenTopUpInput: payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default popliteTrxReducer;

export const SET_TRX_POPLITE = 'SET_TRX_POPLITE';
export const SET_OPEN_TOPUP_INPUT = 'SET_OPEN_TOPUP_INPUT';

export const setTrxData = (payload) => {
  return (dispatch) => {
    dispatch({ type: SET_TRX_POPLITE, payload });
  };
};

export const setOpenTopUpInput = (payload) => {
  return (dispatch) => {
    dispatch({ type: SET_OPEN_TOPUP_INPUT, payload });
  };
};

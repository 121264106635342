/* eslint-disable function-paren-newline */
/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy, Suspense, Fragment } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { logout } from 'src/actions';
import AuthLayout from 'src/layouts/Auth';
import ErrorLayout from 'src/layouts/Error';
import DashboardLayout from 'src/layouts/Dashboard';
import AuthGuard from 'src/components/AuthGuard';
import {
  ADMIN,
  RESEARCHER,
  POPLITE,
  RETAIL_AUDIT_QC,
  GUEST,
  ENTERPRISE_CLIENT,
} from 'src/utils/variables';
import { useQuery, retryLoader } from 'src/utils/helper';
import Route from './Route';
import { initAPM } from './utils/elasitcAPM';

const UnderMaintenance = lazy(() =>
  retryLoader(() => import('src/views/UnderMaintenance')),
);

const routesConfig = [
  {
    path: '/',
    exact: true,
  },
  {
    path: '/under-maintenance',
    exact: true,
    component: lazy(() =>
      retryLoader(() => import('src/views/UnderMaintenance')),
    ),
  },
  {
    path: '/auth',
    layout: AuthLayout,
    routes: [
      {
        path: '/auth/login',
        exact: true,
        component: lazy(() => retryLoader(() => import('src/views/Login'))),
      },
      {
        path: '/auth/register-phone',
        exact: true,
        component: lazy(() =>
          retryLoader(() => import('src/views/auth/RegisterPhone')),
        ),
      },
      {
        path: '/auth/login-poplite',
        exact: true,
        component: lazy(() => retryLoader(() => import('src/views/Login'))),
      },
      {
        path: '/auth/register-poplite',
        exact: true,
        component: lazy(() =>
          retryLoader(() => import('src/views/RegisterPoplite')),
        ),
      },
      {
        path: '/auth/register/confirm-otp-sms',
        exact: true,
        component: lazy(() =>
          retryLoader(() => import('src/views/OtpConfirmation')),
        ),
      },
      {
        path: '/auth/register/confirm-otp-email',
        exact: true,
        component: lazy(() =>
          retryLoader(() => import('src/views/OtpConfirmation')),
        ),
      },
      {
        path: '/auth/register/referral-code',
        exact: true,
        component: lazy(() =>
          retryLoader(() => import('src/views/OtpConfirmation/ReferralCode')),
        ),
      },
      {
        path: '/auth/forgot',
        exact: true,
        component: lazy(() =>
          retryLoader(() =>
            import('src/views/auth/ForgotPassword/SendVerificationCode'),
          ),
        ),
      },
      {
        path: '/auth/forgot/new-password',
        exact: true,
        component: lazy(() =>
          retryLoader(() =>
            import('src/views/auth/ForgotPassword/NewPassword'),
          ),
        ),
      },
      {
        path: '/auth/callback',
        exact: true,
        component: lazy(() =>
          retryLoader(() => import('src/views/auth/AuthCallback')),
        ),
      },
      {
        path: '/auth/poplite-services',
        exact: true,
        component: lazy(() =>
          retryLoader(() => import('src/views/auth/PopliteFeatureSelection')),
        ),
      },
      {
        component: () => <Redirect to="/errors/error-404" />,
      },
    ],
  },
  {
    path: '/errors',
    layout: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => retryLoader(() => import('src/views/Error401'))),
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => retryLoader(() => import('src/views/Error404'))),
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => retryLoader(() => import('src/views/Error500'))),
      },
      {
        path: '/errors/block-access',
        exact: true,
        component: lazy(() =>
          retryLoader(() => import('src/views/MobileBlockAccess')),
        ),
      },
      {
        component: () => <Redirect to="/errors/error-404" />,
      },
    ],
  },
  {
    path: '/poplite/errors',
    layout: DashboardLayout,
    routes: [
      {
        path: '/poplite/errors/block-access',
        exact: true,
        component: lazy(() =>
          retryLoader(() => import('src/views/MobileBlockAccess')),
        ),
      },
    ],
  },
  {
    path: '/studies',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        path: '/studies/:studyGroupId/details',
        allowedRoles: [ADMIN, RESEARCHER, GUEST],
        exact: true,
        component: lazy(() =>
          retryLoader(() => import('src/views/Studies/Details')),
        ),
      },
      {
        path: '/studies/:studyGroupId/details/:tab',
        allowedRoles: [ADMIN, RESEARCHER, GUEST],
        exact: true,
        component: lazy(() =>
          retryLoader(() => import('src/views/Studies/Details')),
        ),
      },
      {
        path: '/studies/:studyGroupId/portrait/:studyId/details',
        allowedRoles: [ADMIN, RESEARCHER, GUEST],
        exact: true,
        component: lazy(() =>
          retryLoader(() => import('src/views/Studies/StudyPortraitDetails')),
        ),
      },
      {
        path: '/studies/:studyGroupId/portrait/:studyId/details/:tab',
        allowedRoles: [ADMIN, RESEARCHER, GUEST],
        exact: true,
        component: lazy(() =>
          retryLoader(() => import('src/views/Studies/StudyPortraitDetails')),
        ),
      },
      {
        path: '/studies/:studyGroupId/redo-study',
        allowedRoles: [ADMIN, RESEARCHER],
        exact: true,
        component: lazy(() =>
          retryLoader(() => import('src/views/Studies/AllowRedoStudy')),
        ),
      },
      {
        path: '/studies/browse',
        allowedRoles: [ADMIN, RESEARCHER],
        exact: true,
        component: lazy(() =>
          retryLoader(() => import('src/views/Studies/StudyList')),
        ),
      },
      {
        path: '/studies/create',
        allowedRoles: [ADMIN, RESEARCHER],
        exact: true,
        component: lazy(() =>
          retryLoader(() => import('src/views/Studies/Create')),
        ),
      },
      {
        path: '/studies/create/dailyquestion',
        allowedRoles: [ADMIN, RESEARCHER],
        exact: true,
        component: lazy(() =>
          retryLoader(() => import('src/views/Studies/Create/DailyQuestion')),
        ),
      },
      {
        path: '/studies/:id/edit-screening',
        allowedRoles: [ADMIN],
        exact: true,
        component: lazy(() =>
          retryLoader(() => import('src/views/Studies/EditScreening')),
        ),
      },
    ],
  },
  {
    path: '/profile',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        path: '/profile/general',
        allowedRoles: [ADMIN, RESEARCHER],
        exact: true,
        component: lazy(() =>
          retryLoader(() => import('src/views/Profile/General')),
        ),
      },
      {
        path: '/profile/security',
        allowedRoles: [ADMIN, RESEARCHER],
        exact: true,
        component: lazy(() =>
          retryLoader(() => import('src/views/Profile/Security')),
        ),
      },
      {
        path: '/profile/:id',
        allowedRoles: [ADMIN, RESEARCHER],
        exact: true,
        component: lazy(() => retryLoader(() => import('src/views/Profile'))),
      },
      {
        path: '/profile/:id/:tab',
        allowedRoles: [ADMIN, RESEARCHER],
        exact: true,
        component: lazy(() => retryLoader(() => import('src/views/Profile'))),
      },
      {
        component: () => <Redirect to="/errors/error-404" />,
      },
    ],
  },
  {
    path: '/projects',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        path: '/projects/browse',
        allowedRoles: [ADMIN, RESEARCHER, GUEST, ENTERPRISE_CLIENT],
        exact: true,
        component: lazy(() =>
          retryLoader(() => import('src/views/Projects/ProjectList')),
        ),
      },
      {
        path: '/projects/create',
        allowedRoles: [ADMIN, RESEARCHER],
        exact: true,
        component: lazy(() =>
          retryLoader(() => import('src/views/Projects/Create')),
        ),
      },
      {
        path: '/projects/:id/quotation/create',
        allowedRoles: [ADMIN, RESEARCHER, GUEST],
        exact: true,
        component: lazy(() =>
          retryLoader(() => import('src/views/Projects/CreateQuotation')),
        ),
      },
      {
        path: '/projects/:id/details/',
        allowedRoles: [ADMIN, RESEARCHER, GUEST, ENTERPRISE_CLIENT],
        exact: true,
        component: lazy(() =>
          retryLoader(() => import('src/views/Projects/Details')),
        ),
      },
      {
        path: '/projects/:id/details/:tab',
        allowedRoles: [ADMIN, RESEARCHER, GUEST, ENTERPRISE_CLIENT],
        exact: true,
        component: lazy(() =>
          retryLoader(() => import('src/views/Projects/Details')),
        ),
      },
    ],
  },
  {
    path: '/retail-audit',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        path: '/retail-audit/browse',
        allowedRoles: [ADMIN, RETAIL_AUDIT_QC],
        exact: true,
        component: lazy(() =>
          retryLoader(() => import('src/views/RetailAudit/TransactionList')),
        ),
      },
      {
        path: '/retail-audit/products/browse',
        allowedRoles: [ADMIN, RETAIL_AUDIT_QC],
        exact: true,
        component: lazy(() =>
          retryLoader(() => import('src/views/RetailAudit/ProductDatabase')),
        ),
      },
    ],
  },
  {
    path: '/referrals',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        path: '/referrals/create',
        allowedRoles: [ADMIN],
        exact: true,
        component: lazy(() =>
          retryLoader(() => import('src/views/ReferralTools/Create')),
        ),
      },
      {
        path: '/referrals/browse',
        allowedRoles: [ADMIN],
        exact: true,
        component: lazy(() =>
          retryLoader(() => import('src/views/ReferralTools/ReferralList')),
        ),
      },
      {
        path: '/referrals/:id/details/',
        allowedRoles: [ADMIN],
        exact: true,
        component: lazy(() =>
          retryLoader(() => import('src/views/ReferralTools/Details')),
        ),
      },
    ],
  },
  {
    path: '/user-management',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        path: '/user-management',
        allowedRoles: [ADMIN],
        exact: true,
        component: lazy(() =>
          retryLoader(() => import('src/views/UserManagement/UserList')),
        ),
      },
      {
        path: '/user-management/:userId/details/',
        allowedRoles: [ADMIN],
        exact: true,
        component: lazy(() =>
          retryLoader(() => import('src/views/UserManagement/UserDetail')),
        ),
      },
      {
        path: '/user-management/:userId/details/:tab',
        allowedRoles: [ADMIN],
        exact: true,
        component: lazy(() =>
          retryLoader(() => import('src/views/UserManagement/UserDetail')),
        ),
      },
      {
        path: '/user-management/:email/edit',
        allowedRoles: [ADMIN],
        exact: true,
        component: lazy(() =>
          retryLoader(() => import('src/views/UserManagement/UserEdit')),
        ),
      },
    ],
  },
  {
    path: '/criteria',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        path: '/criteria/create',
        allowedRoles: [ADMIN],
        exact: true,
        component: lazy(() =>
          retryLoader(() => import('src/views/Criteria/Create')),
        ),
      },
      {
        path: '/criteria/browse',
        allowedRoles: [ADMIN],
        exact: true,
        component: lazy(() =>
          retryLoader(() => import('src/views/Criteria/List')),
        ),
      },
      {
        path: '/criteria/:criteriaId/details',
        allowedRoles: [ADMIN],
        exact: true,
        component: lazy(() =>
          retryLoader(() => import('src/views/Criteria/Detail')),
        ),
      },
      {
        path: '/criteria/criteriaQuestion/:criteriaQuestionId/edit',
        allowedRoles: [ADMIN],
        exact: true,
        component: lazy(() =>
          retryLoader(() => import('src/views/Criteria/EditCriteria')),
        ),
      },
      {
        path: '/criteria/participant',
        allowedRoles: [ADMIN],
        exact: true,
        component: lazy(() =>
          retryLoader(() => import('src/views/Criteria/ParticipantCriteria')),
        ),
      },
    ],
  },
  {
    path: '/screening-logic',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        path: '/screening-logic/browse',
        allowedRoles: [ADMIN],
        exact: true,
        component: lazy(() =>
          retryLoader(() => import('src/views/Criteria/ListScreeningLogic')),
        ),
      },
      {
        path: '/screening-logic/:screeningLogicId/details',
        allowedRoles: [ADMIN],
        exact: true,
        component: lazy(() =>
          retryLoader(() => import('src/views/Criteria/DetailLogic')),
        ),
      },
      {
        path: '/screening-logic/create',
        allowedRoles: [ADMIN],
        exact: true,
        component: lazy(() =>
          retryLoader(() => import('src/views/Criteria/CreateScreeningLogic')),
        ),
      },
    ],
  },
  {
    path: '/personality-result',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/personality-result/upload',
        allowedRoles: [ADMIN],
        component: lazy(() =>
          retryLoader(() => import('src/views/PersonalityResult/Upload')),
        ),
      },
    ],
  },
  {
    path: '/client-view/project/:id',
    guard: AuthGuard,
    exact: true,
    allowedRoles: [ADMIN, RESEARCHER],
    component: lazy(() =>
      retryLoader(() => import('./views/Projects/Details/ClientView')),
    ),
  },
  {
    path: '/marketing',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        path: '/marketing/pop-poll',
        allowedRoles: [ADMIN],
        exact: true,
        component: lazy(() =>
          retryLoader(() => import('src/views/Marketing/PopPoll/PopPollList')),
        ),
      },
      {
        path: '/marketing/pop-poll/create',
        allowedRoles: [ADMIN],
        exact: true,
        component: lazy(() =>
          retryLoader(() => import('src/views/Marketing/PopPoll/Create')),
        ),
      },
      {
        path: '/marketing/pop-poll/category',
        allowedRoles: [ADMIN],
        exact: true,
        component: lazy(() =>
          retryLoader(() => import('src/views/Marketing/PopPoll/Category')),
        ),
      },
      {
        path: '/marketing/pop-poll/:id/details/:tab',
        allowedRoles: [ADMIN],
        exact: true,
        component: lazy(() =>
          retryLoader(() => import('src/views/Marketing/PopPoll/Details')),
        ),
      },
      {
        path: '/marketing/pop-mart',
        allowedRoles: [ADMIN],
        exact: true,
        component: lazy(() =>
          retryLoader(() => import('src/views/Marketing/PopMart/Product')),
        ),
      },
      {
        path: '/marketing/pop-mart/category',
        allowedRoles: [ADMIN],
        exact: true,
        component: lazy(() =>
          retryLoader(() => import('src/views/Marketing/PopMart/Category')),
        ),
      },
      {
        path: '/marketing/pop-mart/vendor',
        allowedRoles: [ADMIN],
        exact: true,
        component: lazy(() =>
          retryLoader(() => import('src/views/Marketing/PopMart/Vendor')),
        ),
      },
      {
        path: '/marketing/pop-mart/product/create/:productType',
        allowedRoles: [ADMIN],
        exact: true,
        component: lazy(() =>
          retryLoader(() =>
            import('src/views/Marketing/PopMart/Product/Create'),
          ),
        ),
      },
      {
        path: '/marketing/pop-mart/product/:id/edit/:productType',
        allowedRoles: [ADMIN],
        exact: true,
        component: lazy(() =>
          retryLoader(() => import('src/views/Marketing/PopMart/Product/Edit')),
        ),
      },
      {
        path: '/marketing/lucky-draw',
        allowedRoles: [ADMIN],
        exact: true,
        component: lazy(() =>
          retryLoader(() => import('src/views/Marketing/LuckyDraw/Product')),
        ),
      },
      {
        path: '/marketing/lucky-draw/product/create',
        allowedRoles: [ADMIN],
        exact: true,
        component: lazy(() =>
          retryLoader(() =>
            import('src/views/Marketing/LuckyDraw/Product/Create'),
          ),
        ),
      },
      {
        path: '/marketing/lucky-draw/product/:id/edit',
        allowedRoles: [ADMIN],
        exact: true,
        component: lazy(() =>
          retryLoader(() =>
            import('src/views/Marketing/LuckyDraw/Product/Edit'),
          ),
        ),
      },
      {
        path: '/marketing/pop-star',
        allowedRoles: [ADMIN],
        routes: [
          {
            path: '/marketing/pop-star',
            allowedRoles: [ADMIN],
            exact: true,
            component: lazy(() =>
              retryLoader(() => import('src/views/Marketing/PopStar')),
            ),
          },
          {
            path: '/marketing/pop-star/list',
            allowedRoles: [ADMIN],
            exact: true,
            component: lazy(() =>
              retryLoader(() => import('src/views/Marketing/PopStar')),
            ),
          },
          {
            path: '/marketing/pop-star/list/:popStarId',
            allowedRoles: [ADMIN],
            exact: true,
            component: lazy(() =>
              retryLoader(() =>
                import(
                  'src/views/Marketing/PopStar/PopStar/PopStarDetail/PopStarDetail'
                ),
              ),
            ),
          },
          {
            path: '/marketing/pop-star/:activeTab',
            allowedRoles: [ADMIN],
            exact: true,
            component: lazy(() =>
              retryLoader(() => import('src/views/Marketing/PopStar')),
            ),
          },
          {
            path: '/marketing/pop-star/campaign/create',
            allowedRoles: [ADMIN],
            exact: true,
            component: lazy(() =>
              retryLoader(() =>
                import('src/views/Marketing/PopStar/PopStarCampaign/Create'),
              ),
            ),
          },
          {
            path: '/marketing/pop-star/campaign/:id/:activeTab',
            allowedRoles: [ADMIN],
            exact: true,
            component: lazy(() =>
              retryLoader(() =>
                import('src/views/Marketing/PopStar/PopStarCampaign/Details'),
              ),
            ),
          },
          {
            path: '/marketing/pop-star/campaign/:id',
            allowedRoles: [ADMIN],
            exact: true,
            component: lazy(() =>
              retryLoader(() =>
                import('src/views/Marketing/PopStar/PopStarCampaign/Details'),
              ),
            ),
          },
          {
            path:
              '/marketing/pop-star/campaign/:campaignId/submission/:submissionId',
            allowedRoles: [ADMIN],
            exact: true,
            component: lazy(() =>
              retryLoader(() =>
                import('src/views/Marketing/PopStar/PopStarSubmission'),
              ),
            ),
          },
        ],
      },
      {
        path: '/marketing/reports',
        allowedRoles: [ADMIN],
        exact: true,
        component: lazy(() =>
          retryLoader(() => import('src/views/Marketing/Reports')),
        ),
      },
    ],
  },
  {
    path: '*',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        path: '/panel-explorer',
        allowedRoles: [ADMIN],
        exact: true,
        component: lazy(() =>
          retryLoader(() => import('src/views/PanelExplorerDashboard')),
        ),
      },
      {
        path: '/panel-book',
        allowedRoles: [ADMIN, RESEARCHER, GUEST],
        exact: true,
        component: lazy(() => retryLoader(() => import('src/views/PanelBook'))),
      },
      {
        component: () => <Redirect to="/errors/error-404" />,
      },
    ],
  },
];

const isUserAllowedToAccess = (allowedRoles, userRoles) => {
  const isAllowedToAccess = allowedRoles.some((allowedRole) =>
    userRoles.includes(allowedRole),
  );
  return isAllowedToAccess;
};

function renderRoutes(
  routes,
  user,
  underMaintenance,
  popliteSignup,
  isCxToolsFeature,
  isMobile,
) {
  return routes ? (
    <Suspense
      fallback={
        <div style={{ width: '100%', textAlign: 'center', marginTop: 64 }}>
          <CircularProgress />
        </div>
      }
    >
      <Switch>
        <Route path="/under-maintenance" exact component={UnderMaintenance} />
        {underMaintenance && <Redirect to="/under-maintenance" />}
        {routes.map((route, index) => {
          const RouteGuard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;
          const RouteRoutes = route.routes;
          const { allowedRoles } = route;
          const { roles } = user || [];
          const isPoplite = roles?.length > 0 && roles?.includes(POPLITE);
          const mobileBlockRoutes = [
            '/poplite/studies/create',
            '/poplite/studies/:studyGroupId/edit',
            '/poplite/cx-tools/survey/create',
            '/poplite/cx-tools/survey/nps/create',
          ];

          if (isMobile && mobileBlockRoutes.includes(route.path)) {
            return (
              <Redirect
                key={index}
                from={route.path}
                to="/poplite/errors/block-access"
              />
            );
          }

          if (
            allowedRoles &&
            user &&
            !isUserAllowedToAccess(allowedRoles, roles)
          ) {
            return (
              <Redirect key={index} from={route.path} to="/errors/error-404" />
            );
          }

          if (
            popliteSignup === false &&
            route.path === '/auth/register-poplite'
          ) {
            return <Redirect key={index} from={route.path} to="/auth/login" />;
          }

          return (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              render={(props) => {
                if (route.path === '/' && !user) {
                  return <Redirect to="/auth/login" />;
                }
                if (
                  route.path === '/' &&
                  user &&
                  !user.roles.includes('retailAuditQC')
                ) {
                  const popliteRedirect =
                    isCxToolsFeature || user?.isPopliteCxTools
                      ? '/poplite/cx-tools/survey'
                      : '/poplite/projects';
                  const redirectUrl = isPoplite
                    ? popliteRedirect
                    : '/panel-explorer';

                  return <Redirect key={index} to={redirectUrl} />;
                }
                return (
                  <RouteGuard>
                    <Layout>
                      {RouteRoutes &&
                        renderRoutes(
                          RouteRoutes,
                          user,
                          underMaintenance,
                          popliteSignup,
                          isCxToolsFeature,
                          isMobile,
                        )}

                      {!RouteRoutes && <Component {...props} />}
                    </Layout>
                  </RouteGuard>
                );
              }}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;
}

const Routes = () => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'), {
    noSsr: true,
  });
  const user = useSelector(
    (state) => state && state.session && state.session.user,
  );
  const isCxToolsFeature = useSelector(
    (state) => state.popliteDrawer?.isCxToolsFeature,
  );
  const underMaintenance = useSelector(
    (state) => state?.featureFlag?.underMaintenance?.isActive,
  );
  const popliteSignup = useSelector(
    (state) => state?.featureFlag?.popliteSignup?.isActive,
  );

  const elasticAPM = useSelector(
    (state) => state?.featureFlag?.elasticAPM?.isActive,
  );

  if (elasticAPM) {
    initAPM(elasticAPM);
  }

  const query = useQuery();
  const ref = query.get('ref');

  if (ref !== null) {
    const referralCode = ref.replace(/[^A-Za-z0-9]/g, '').substr(0, 64);
    if (referralCode.length > 0) {
      sessionStorage.setItem('referralCode', referralCode);
    } else {
      sessionStorage.removeItem('referralCode');
    }
  }

  if (user && !user.roles) {
    dispatch(logout());
    return <Redirect to="/auth/login" />;
  }

  return renderRoutes(
    routesConfig,
    user,
    underMaintenance,
    popliteSignup,
    isCxToolsFeature,
    isMobile,
  );
};
export default Routes;

import { colors } from '@material-ui/core';

export default {
  root: {
    borderRadius: 50,
    padding: '9px 20px',
    textTransform: 'initial',
  },
  contained: {
    boxShadow: '0 1px 1px 0 rgba(0,0,0,0.14)',
    backgroundColor: '#F2F9FE',
    '&:hover': {
      backgroundColor: colors.grey[300],
    },
  },
};

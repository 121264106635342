import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import PopCall from '../utils/popCall';

const popCall = new PopCall();

function NpsWidget() {
  const [isNeedRespond, setIsNeedRespond] = useState(false);
  const session = useSelector((state) => state.session);

  const _getUserRespondStatus = async () => {
    try {
      const response = await popCall.get('/participant/nps');
      setIsNeedRespond(response?.data?.needResponse);
    } catch (err) {
      // throw err;
    }
  };

  useEffect(() => {
    if (session.loggedIn) {
      if (!session.user?.isAdmin && !session.user?.isEnterpriseClient) {
        _getUserRespondStatus();
      }
    }
  }, [session]);

  return (
    <>
      {session?.loggedIn && session?.user?.isPoplite && isNeedRespond && (
        <>
          {process.env.REACT_APP_ENVIRONMENT === 'production' && (
            <Helmet>
              <script>
                {`
                    (
                      function() {
                        var e="ss-widget",t="script",a=document,r=window;
                        var s,n,c;
                        r.SS_WIDGET_TOKEN="ntt-7580bc";
                        r.SS_ACCOUNT="poplite.surveysparrow.com";
                        r.SS_SURVEY_NAME="Poplite NPS - Live ";
                        if(!a.getElementById(e)){var S=function(){S.update(arguments)};
                        S.args=[];
                        S.update=function(e){S.args.push(e)};
                        r.SparrowLauncher=S;
                        s=a.getElementsByTagName(t);
                        c=s[s.length-1];
                        n=a.createElement(t);
                        n.type="text/javascript";
                        n.async=!0;
                        n.id=e;
                        n.src=["https://","poplite.surveysparrow.com/nps/widget/",r.SS_WIDGET_TOKEN].join("");
                        c.parentNode.insertBefore(n,c)}})();
                  `}
              </script>
              <script>
                {`
                    window.SparrowLauncher('popup', {
                      email: '${session?.user?.email}', //add email id of user
                      variables: {}, //add custom properties
                      disableDebugLog: false //toggle to enable/disable console logs
                  }) ;
                  `}
              </script>
            </Helmet>
          )}

          {process.env.REACT_APP_ENVIRONMENT === 'staging' && (
            <Helmet>
              <script>
                {`
                    (
                      function(){var e="ss-widget",t="script",a=document,r=window
                      var s,n,c;
                      r.SS_WIDGET_TOKEN="ntt-92c266";
                      r.SS_ACCOUNT="poplite.surveysparrow.com";
                      r.SS_SURVEY_NAME="Poplite NPS - Live  (QA)";
                      if(!a.getElementById(e)){var S=function(){S.update(arguments)};
                      S.args=[];
                      S.update=function(e){S.args.push(e)};
                      r.SparrowLauncher=S;
                      s=a.getElementsByTagName(t);
                      c=s[s.length-1];
                      n=a.createElement(t);
                      n.type="text/javascript";
                      n.async=!0;
                      n.id=e;
                      n.src=["https://","poplite.surveysparrow.com/nps/widget/",r.SS_WIDGET_TOKEN].join("");
                      c.parentNode.insertBefore(n,c)}})();
                  `}
              </script>
              <script>
                {`
                    window.SparrowLauncher('popup', {
                        email: '${session?.user?.email}', //add email id of user
                        variables: {}, //add custom properties
                        disableDebugLog: false //toggle to enable/disable console logs
                    }) ;
                  `}
              </script>
            </Helmet>
          )}
        </>
      )}
    </>
  );
}

export default NpsWidget;

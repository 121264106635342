import { getErrorMessage, popCall } from 'src/utils/popCall';
import { showSnackbar } from './snackbarActions';

export const SET_DEMOGRAPHICS_CLIENT_MANAGEMENT =
  'SET_DEMOGRAPHICS_CLIENT_MANAGEMENT';
export const SET_STUDYGROUPS_CLIENT_MANAGEMENT =
  'SET_STUDYGROUPS_CLIENT_MANAGEMENT';
export const INIT_CLIENT_MANAGEMENT = 'INIT_CLIENT_MANAGEMENT';
export const SET_PUBLISHING_CLIENT_MANAGEMENT =
  'SET_PUBLISHING_CLIENT_MANAGEMENT';
export const SET_FETCHING_CLIENT_MANAGEMENT = 'SET_FETCHING_CLIENT_MANAGEMENT';
export const SET_SUBMITTING_DRAFT_CLIENT_MANAGEMENT =
  'SET_SUBMITTING_DRAFT_CLIENT_MANAGEMENT';
export const RESET_CLIENT_MANAGEMENT = 'RESET_CLIENT_MANAGEMENT';
export const SET_STATUS_CLIENT_MANAGEMENT = 'SET_STATUS_CLIENT_MANAGEMENT';
export const SET_EDITING_CLIENT_MANAGEMENT = 'SET_EDITING_CLIENT_MANAGEMENT';

export const setClientManagementStatus = (payload) => ({
  type: SET_STATUS_CLIENT_MANAGEMENT,
  payload,
});
export const resetClientManagement = () => ({
  type: RESET_CLIENT_MANAGEMENT,
});

export const setDemographics = (payload) => ({
  type: SET_DEMOGRAPHICS_CLIENT_MANAGEMENT,
  payload,
});

export const setStudyGroups = (payload) => ({
  type: SET_STUDYGROUPS_CLIENT_MANAGEMENT,
  payload,
});

export const initiateClientManagement = (payload) => ({
  type: INIT_CLIENT_MANAGEMENT,
  payload,
});

export const setPublishing = (payload) => ({
  type: SET_PUBLISHING_CLIENT_MANAGEMENT,
  payload,
});

export const setFetching = (payload) => ({
  type: SET_FETCHING_CLIENT_MANAGEMENT,
  payload,
});

export const setSubmittingDraft = (payload) => ({
  type: SET_SUBMITTING_DRAFT_CLIENT_MANAGEMENT,
  payload,
});

export const setEditing = (payload) => ({
  type: SET_EDITING_CLIENT_MANAGEMENT,
  payload,
});

const errorMessage = (error) =>
  getErrorMessage(error) || 'Oops something went wrong, please try again later';

export const fetchClientManagementConfig = (projectId) => async (dispatch) => {
  try {
    dispatch(setFetching(true));
    const response = await popCall.get(
      `/projects/${projectId}/surveyquestions`,
    );

    if (response.code === 200) {
      dispatch(setFetching(false));
      dispatch(setClientManagementStatus(response?.data?.status));
      dispatch(
        initiateClientManagement({
          studyGroups: response?.data?.dataToShow?.studyGroups,
          demographics: response?.data?.dataToShow?.demographics,
        }),
      );
    }
  } catch (error) {
    const message = errorMessage(error);
    dispatch(showSnackbar(message, 'error'));
    dispatch(setFetching(false));
  }
};

export const publishClientManagement = (projectId, payload) => async (
  dispatch,
) => {
  try {
    dispatch(setPublishing(true));

    const response = await popCall.post(
      `/projects/${projectId}/publish-to-client`,
      payload,
    );

    if (response.code === 200) {
      dispatch(setPublishing(false));
      dispatch(setEditing(false));
      dispatch(fetchClientManagementConfig(projectId));
      dispatch(
        showSnackbar('Project successfully published to client', 'success'),
      );
    }
  } catch (error) {
    const message = errorMessage(error);
    dispatch(showSnackbar(message, 'error'));
    dispatch(setPublishing(false));
  }
};

export const saveDraftClientManagement = (projectId, payload) => async (
  dispatch,
) => {
  try {
    dispatch(setSubmittingDraft(true));
    const response = await popCall.put(
      `/projects/${projectId}/client-config`,
      payload,
    );

    if (response.code === 200) {
      dispatch(setEditing(false));
      dispatch(setSubmittingDraft(false));
      dispatch(showSnackbar('Draft successfully saved', 'success'));
    }
  } catch (error) {
    const message = errorMessage(error);
    dispatch(showSnackbar(message, 'error'));
    dispatch(setSubmittingDraft(false));
  }
};

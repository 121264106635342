import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PopCall from 'src/utils/popCall';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { makeStyles } from '@material-ui/styles';

import { showSnackbar, setTopUpPopcoinsPromo } from 'src/actions';
import Box from '@material-ui/core/Box';
import Backdrop from '@material-ui/core/Backdrop';
import Card from '@material-ui/core/Card';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
import HelpIcon from '@material-ui/icons/HelpOutlineOutlined';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import { OrangeButton } from 'src/components/Buttons';
import TopUpPromoInfo from 'src/components/TopUpPromoInfo';

import coinSvg from 'src/assets/svg/coin-with-bg.svg';
import { ReactComponent as TopUpAddIcon } from 'src/assets/svg/popcoin-topup.svg';
import { formatNumber } from 'src/utils/helper';
import { setOpenTopUpInput } from 'src/actions';
import { Skeleton } from '@material-ui/lab';

const popCall = new PopCall();

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(1, 1, 0, 1),
    position: 'relative',
  },
  card: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(1),
    borderRadius: '12px',
    border: '1px solid #F2F2F2',
  },
  textTitle: {
    color: '#828282',
    marginRight: 4,
    fontWeight: 600,
    fontSize: '12px',
  },
  coinImage: {
    width: '42px',
    height: '42px',
    marginRight: theme.spacing(1.5),
    '&:hover': {
      cursor: 'pointer',
    },
  },

  balanceText: {
    fontWeight: 600,
    lineHeight: '20px',
    color: theme.palette.textPrimary.main,
  },
  icon: {
    color: '#E3701B',
    width: 12,
    height: 12,
    padding: 0,
  },
  paper: {
    width: 265,
    borderRadius: 16,
    padding: theme.spacing(3),
  },
  titleTips: {
    fontSize: 16,
    flexGrow: 1,
    color: '#333333',
    fontWeight: 600,
  },
  contentTips: {
    fontSize: 12,
    color: theme.palette.textPrimary.main,
  },
  iconButton: {
    padding: 0,
  },
  iconCoinTip: {
    width: '65px',
    height: '65px',
    marginRight: theme.spacing(1.5),
  },
  closeIcon: {
    padding: 0,
    color: '#BDBDBD',
  },
  backDrop: {
    zIndex: 1,
  },
  textPromoBadge: {
    color: '#FFFFFF',
    fontWeight: 600,
    fontSize: '11px',
    backgroundColor: '#4CAF50',
    padding: theme.spacing(0.5),
    borderRadius: '10px',
    marginRight: theme.spacing(1.5),
  },
  textPromoInfo: {
    color: '#00417D',
    fontWeight: 300,
    fontSize: '11px',
  },
  paperPromo: {
    width: 320,
    borderRadius: 16,
    padding: theme.spacing(3),
  },
  popoverButton: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  iconPlus: {
    fontSize: 22,
    color: '#FFFFFF',
    marginRight: theme.spacing(2),
  },
}));

const BalanceCard = ({ isMiniDrawer, isPopliteDrawerOpen }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const popliteWalletReducer = useSelector((state) => state.popliteWallet);
  const [popCoinSkeleton, setPopCoinSkeleton] = useState(false);
  const topUpPopcoinsPromo = useSelector(
    (state) => state.poplitePopcoinsPromo.data,
  );

  // about popover variables
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElPromo, setAnchorElPromo] = useState(null);
  const isAboutPopoverOpen = Boolean(anchorEl);
  const isTopUpPromoPopoverOpen = Boolean(anchorElPromo);

  const _fetchTopUpPopcoinsPromo = async () => {
    try {
      const response = await popCall.get('/poplitePromo/myPromo?type=TOPUP');
      dispatch(setTopUpPopcoinsPromo(response?.data));
    } catch (error) {
      const { response } = error;
      let errorText = 'Error while fetching poplite popcoins promo';
      if (response?.data?.message) {
        errorText = response?.data?.message;
      }
      dispatch(showSnackbar(errorText, 'error'));
    } finally {
      setTimeout(() => {
        setPopCoinSkeleton(true)
      }, 500)
    }
  };

  useEffect(() => {
    _fetchTopUpPopcoinsPromo();
  }, []);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handlePromoPopoverOpen = (event) => {
    setAnchorElPromo(event.currentTarget);
  };

  const handlePromoPopoverClose = () => {
    setAnchorElPromo(null);
  };

  const redirectToTopUp = () => {
    dispatch(setOpenTopUpInput(true));

    const redirectUrl = '/poplite/payments/browse';
    history.push(redirectUrl);
  };

  const handlePromoButtonOnClick = () => {
    handlePromoPopoverClose();
    redirectToTopUp();
  };

  const AboutPopCoinsPopover = () => {
    return (
      <>
        <Backdrop open={isAboutPopoverOpen} className={classes.backDrop} />
        <Popover
          data-testid="about-popover"
          anchorEl={anchorEl}
          open={isAboutPopoverOpen}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          classes={{
            paper: classes.paper,
          }}
        >
          <Box display="flex" flexDirection="row" alignItems="center" mb={2}>
            <Typography className={classes.titleTips}>
              Tentang PopCoins
            </Typography>
            <IconButton
              className={classes.iconButton}
              onClick={handlePopoverClose}
            >
              <CloseIcon className={classes.closeIcon} />
            </IconButton>
          </Box>

          <Box display="flex" flexDirection="row">
            <img
              src={coinSvg}
              alt="example-study"
              className={classes.iconCoinTip}
            />
            <Box ml={0.5}>
              <Typography gutterBottom className={classes.contentTips}>
                PopCoins merupakan saldo khusus yang digunakan untuk melakukan
                transaksi survei di Poplite.
              </Typography>
              <Typography className={classes.contentTips}>
                Dalam nominal Rupiah: <br /> 1 PopCoins = Rp1
              </Typography>
            </Box>
          </Box>
        </Popover>
      </>
    );
  };

  const PopCoinsPromoPopover = () => {
    return (
      <>
        <Backdrop open={isTopUpPromoPopoverOpen} className={classes.backDrop} />
        <Popover
          data-testid="about-popover"
          anchorEl={anchorElPromo}
          open={isTopUpPromoPopoverOpen}
          onClose={handlePromoPopoverClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          classes={{
            paper: classes.paperPromo,
          }}
        >
          <TopUpPromoInfo
            handleClose={handlePromoPopoverClose}
            data={topUpPopcoinsPromo}
          />
          <OrangeButton
            className={classes.popoverButton}
            onClick={handlePromoButtonOnClick}
          >
            <Typography className={classes.iconPlus}>+</Typography>
            Top Up Sekarang
          </OrangeButton>
        </Popover>
      </>
    );
  };

  return (
    <div className={clsx({ [classes.wrapper]: !isMiniDrawer })}>
      {AboutPopCoinsPopover()}
      {PopCoinsPromoPopover()}
      <Card
        elevation={0}
        className={clsx(classes.card, { [classes.boderCard]: !isMiniDrawer })}
      >
        <Box width="100%">
          <Box display="flex" width="100%">
            <img
              src={coinSvg}
              alt="example-study"
              className={classes.coinImage}
              onClick={redirectToTopUp}
            />
            <Box display="flex" flexGrow="1">
              <Box display="flex" flexDirection="column">
                <Box
                  display="flex"
                  alignItems="center"
                  flexDirection="row"
                  mb={0.5}
                >
                  <Typography className={classes.textTitle}>
                    PopCoins
                  </Typography>
                  <IconButton
                    className={classes.iconButton}
                    onMouseEnter={handlePopoverOpen}
                  >
                    <HelpIcon className={classes.icon} />
                  </IconButton>
                </Box>
                {/* balance amount */}
                <Typography className={classes.balanceText}>
                  {
                    popCoinSkeleton ? formatNumber(popliteWalletReducer?.balance || 0) : (
                      <Skeleton style={{ borderRadius: '17.5px' }} variant="text" />
                    )
                  }
                </Typography>
              </Box>
            </Box>
            <IconButton
              className={classes.iconButton}
              onClick={redirectToTopUp}
            >
              <TopUpAddIcon />
            </IconButton>
          </Box>
          {topUpPopcoinsPromo?.length !== 0 && (
            <Box
              display="flex"
              width="100%"
              mt={2}
              alignItems="center"
              ml={!isPopliteDrawerOpen ? '-2px' : '0'}
            >
              <>
                <Box onMouseEnter={handlePromoPopoverOpen}>
                  <Typography className={classes.textPromoBadge}>
                    Promo
                  </Typography>
                </Box>
                {isPopliteDrawerOpen && (
                  <Typography className={classes.textPromoInfo}>
                    Dapatkan Extra Coins!
                  </Typography>
                )}
              </>
            </Box>
          )}
        </Box>
      </Card>
    </div>
  );
};

export default BalanceCard;

import {
  REMOTE_ACCESS_ACTIVE,
  REMOTE_ACCESS_INACTIVE,
} from '../actions/amRemoteAccessActions';

const initialState = {
  isRemoting: false,
  userEmail: null,
  remoteKey: null,
  sessionRemaining: 3 * 60 * 60 * 1_000, // 3 hours
};

export default function amRemoteAccessReducer(state = initialState, action) {
  switch (action.type) {
    case REMOTE_ACCESS_ACTIVE: {
      return {
        ...state,
        ...action,
        isRemoting: true,
      };
    }

    case REMOTE_ACCESS_INACTIVE: {
      return {
        sessionRemaining: 0,
        userEmail: null,
        isRemoting: false,
      };
    }

    default: {
      return state;
    }
  }
}

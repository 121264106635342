import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { CXTOOLS_FEATURE, POPLITE_FEATURE } from 'src/utils/variables';
import { setPopliteFeature, setOpenFeatureMenu } from 'src/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  menuButton: {
    height: 40,
    minWidth: 116,
    marginLeft: theme.spacing(2.5),
    borderRadius: theme.spacing(2.5),
    padding: '10px 12px',
    textTransform: 'capitalize',
    backgroundImage: 'linear-gradient(180deg, #00417D 0%, #002B5A 100%)',
    border: '1px solid #ffffff',
  },
}));

const StyledMenu = withStyles({
  paper: {
    minWidth: 116,
    borderRadius: 8,
    marginTop: 4,
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

export default function PopliteServiceMenu() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const menuItems = {
    [CXTOOLS_FEATURE]: {
      label: 'CX Tools',
      value: CXTOOLS_FEATURE,
      href: '/poplite/cx-tools/survey',
    },
    [POPLITE_FEATURE]: {
      label: 'Poplite',
      value: POPLITE_FEATURE,
      href: '/poplite/projects',
    },
  };

  const popliteSelectedFeature = useSelector(
    (state) => state.popliteDrawer.popliteSelectedFeature,
  );
  const isFeatureMenuOpen = useSelector(
    (state) => state.popliteDrawer.isFeatureMenuOpen,
  );

  const onClickMenuButton = (event) => {
    dispatch(setOpenFeatureMenu(!isFeatureMenuOpen));
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
    dispatch(setOpenFeatureMenu(!isFeatureMenuOpen));
  };

  const onSelectMenuItem = (selected) => {
    setAnchorEl(null);
    dispatch(setPopliteFeature(selected.value));
    history.push(selected.href);
  };

  return (
    <div className={classes.root}>
      <Button
        color="inherit"
        aria-haspopup="true"
        onClick={onClickMenuButton}
        className={classes.menuButton}
        endIcon={<ArrowDropDownIcon />}
      >
        {menuItems[popliteSelectedFeature]?.label || ''}
      </Button>

      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={onClose}
      >
        {Object.keys(menuItems).map((key, index) => (
          <MenuItem
            key={`service-item${index}`}
            disabled={menuItems[key].value === popliteSelectedFeature}
            selected={menuItems[key].value === popliteSelectedFeature}
            onClick={() => onSelectMenuItem(menuItems[key])}
          >
            {menuItems[key].label}
          </MenuItem>
        ))}
      </StyledMenu>
    </div>
  );
}

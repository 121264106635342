import PopCall from 'src/utils/popCall';
import { showSnackbar } from 'src/actions';

export const SET_WALLET = 'SET_WALLET';
export const RESET_WALLET = 'RESET_WALLET';

export const setWalletInfo = () => {
  return async (dispatch) => {
    try {
      const popCall = new PopCall();
      const response = await popCall.get('/poplitewallets/info');

      return dispatch({
        type: SET_WALLET,
        payload: response?.data || {},
      });
    } catch (err) {
      return dispatch(showSnackbar('Poplite Wallet Not Found', 'error'));
    }
  };
};

export const resetWalletInfo = () => (dispatch) => {
  dispatch({ type: RESET_WALLET });
};

import mock from 'src/utils/mock';

mock.onGet('/researchers/studyGroups/1/studies/1/result').reply(200, {
  message: 'Success',
  code: 200,
  data: {
    'Industri Pekerjaan': {
      type: 'doughnut',
      label: [
        'Pabrik Gelembung',
        'Perusahaan Transportasi Luar Angkasa',
        'Manufaktur Parts HAARP',
        'Ekspedisi Monster',
      ],
      value: [99, 23, 17, 11],
    },
    'Lama Bekerja di Perusahaan': {
      type: 'horizontal-bar',
      label: [
        '< 3 Hari',
        '5 - 10 Tahun',
        'Kurang Lebih 100 Tahun',
        'Baru Dipecat',
      ],
      value: [35, 70, 30, 15],
    },
    'Seberapa suka dengan perusahaan tempat bekerja sekarang? (rating dari 1-10)': {
      type: 'vertical-bar',
      label: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
      value: [35, 70, 30, 15, 3, 2, 15, 50, 10, 20],
    },
  },
});

mock
  .onGet(
    '/researchers/studyGroups/1/studies/1/result?filter[title]=Gender&filter[values]=Laki-Laki,Perempuan',
  )
  .reply(200, {
    message: 'Success',
    code: 200,
    data: {
      'Industri Pekerjaan': {
        type: 'doughnut',
        label: [
          'Pabrik Gelembung',
          'Perusahaan Transportasi Luar Angkasa',
          'Manufaktur Parts HAARP',
          'Ekspedisi Monster',
        ],
        value: [17, 15, 8, 3],
      },
      'Lama Bekerja di Perusahaan': {
        type: 'horizontal-bar',
        label: [
          '< 3 Hari',
          '5 - 10 Tahun',
          'Kurang Lebih 100 Tahun',
          'Baru Dipecat',
        ],
        value: [23, 5, 15, 0],
      },
      'Seberapa suka dengan perusahaan tempat bekerja sekarang? (rating dari 1-10)': {
        type: 'vertical-bar',
        label: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
        value: [5, 10, 3, 2, 2, 2, 10, 20, 1, 15],
      },
    },
  });

export const SET_PE_SCREENING_CRITERIA = 'SET_PE_SCREENING_CRITERIA';
export const RESET_IS_FROM_PE = 'RESET_IS_FROM_PE';
export const RESET_FROM_EDIT_CRITERIA = 'RESET_FROM_EDIT_CRITERIA';

export const setPanelExploreScreeningCriteria = (payload) => (dispatch) => {
  dispatch({ type: SET_PE_SCREENING_CRITERIA, payload });
};

export const resetIsFromPE = () => (dispatch) => {
  dispatch({ type: RESET_IS_FROM_PE });
};

export const resetIsFromEditCriteria = () => (dispatch) => {
  dispatch({ type: RESET_FROM_EDIT_CRITERIA });
};

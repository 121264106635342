import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Popover,
  CardHeader,
  CardActions,
  Divider,
  Button,
  IconButton,
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PopulixNoticationIcon from 'src/assets/svg/notification.svg';
import NotificationList from './NotificationList';
import Placeholder from './Placeholder';
import { ChecklistIcon } from '../Icons';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 350,
    maxWidth: '100%',
  },
  popoverContainer: {
    '& .MuiPopover-paper': {
      borderRadius: 24,
      marginTop: theme.spacing(1),
    },
  },
  actions: {
    backgroundColor: '#F2F9FE',
    justifyContent: 'center',
  },
  cardHeader: {
    display: 'flex',
    height: 65,
    alignItems: 'center',
    padding: '0px 20px',
    '& .MuiCardHeader-avatar': {
      height: 20,
    },
    '& .MuiCardHeader-content': {
      height: 16,
    },
    '& .MuiCardHeader-title': {
      fontSize: 16,
      fontWeight: '600',
      color: '#4F4F4F',
      letterSpacing: 1.5,
    },
    '& .MuiCardHeader-action': {
      margin: 0,
      '& .MuiButton-root:hover': {
        backgroundColor: '#ffffff',
      },
      '& .MuiButton-text': {
        fontSize: 12,
        fontWeight: '300',
        color: '#329BFF',
        lineHeight: '18px',
        textTransform: 'none',
        marginTop: 24,
        padding: 0,
      },
    },
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 42,
    backgroundColor: '#ffffff',
    padding: 0,
  },
  cardFooterButton: {
    fontSize: 12,
    fontWeight: '600',
    color: '#EE7824',
    borderRadius: 0,
    width: '100%',
    height: 42,
    textTransform: 'none',
  },
  perfectScrollBar: {
    height: 300,
  },
}));

function NotificationsPopover({
  notifications,
  handleLoadMoreNotifications,
  isLoadMore,
  anchorEl,
  onClick,
  ...rest
}) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Popover
      {...rest}
      anchorEl={anchorEl}
      className={classes.popoverContainer}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <div className={classes.root}>
        <CardHeader
          className={classes.cardHeader}
          avatar={<img src={PopulixNoticationIcon} alt="notification" />}
          title="Notifikasi"
          action={<Button onClick={() => notifications?.TotalUnread > 0 && onClick(0)}>
            <ChecklistIcon />Sudah Dibaca
          </Button>}
        />
        <Divider />
        <PerfectScrollbar
          className={classes.perfectScrollBar}
          onYReachEnd={isLoadMore && handleLoadMoreNotifications}
        >
          {notifications?.Notifications?.length > 0 ? (
            <NotificationList
              notifications={notifications?.Notifications}
              onClick={onClick}
            />
          ) : (
            <Placeholder />
          )}
          <Divider />
        </PerfectScrollbar>
        <CardActions
          className={classes.cardActions}
        >
          <Button
            className={classes.cardFooterButton}
            onClick={() => history.push('/poplite/notifications')}
          >
            Lihat Semua
          </Button>
        </CardActions>
      </div>
    </Popover>
  );
}

NotificationsPopover.propTypes = {
  anchorEl: PropTypes.any,
  className: PropTypes.string,
  notifications: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  handleLoadMoreNotifications: PropTypes.func,
  isLoadMore: PropTypes.bool,
};

export default NotificationsPopover;

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const RouteChecker = () => {
  const location = useLocation();
  const featureFlag = useSelector((state) => state.featureFlag);

  const handleRouteCheck = () => {
    if (
      featureFlag?.disableProjectPage &&
      location?.pathname.includes('projects')
    ) {
      return window.location.replace('/');
    }

    if (
      featureFlag?.disableStudyPage &&
      location?.pathname.includes('studies')
    ) {
      return window.location.replace('/');
    }

    return null;
  };

  useEffect(() => {
    handleRouteCheck();
  }, [location, featureFlag]);

  return <></>;
};

export default RouteChecker;

import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Button, Box, Dialog, Typography, Divider } from '@material-ui/core';
import { useSnackbar } from 'src/utils/snackbar';
import { getErrorMessage } from 'src/utils/popCall';
import { endSession } from 'src/utils/remoteClient';
import PopulixLogoutIcon from 'src/assets/svg/logout.svg';

const useStyles = makeStyles((theme) => ({
  iconEndSession: {
    marginRight: theme.spacing(1),
  },
  buttonEndSession: {
    height: 40,
    minWidth: 60,
    borderRadius: theme.spacing(9),
    padding: '10px 12px',
    textTransform: 'capitalize',
    backgroundColor: '#002B5A',
    border: '1px solid #002B5A',
    '&:hover': {
      border: '1px solid #ffffff',
    },
    '& .MuiButton-startIcon': {
      marginLeft: 0,
      marginRight: 5,
    },
  },

  dialog: {
    '& .poplite_am_session_end-dialog__paper': {
      display: 'flex',
      flexDirection: 'column',
      gap: 20,
      width: 560,
      padding: 24,
      borderRadius: 24,
    },
    '& .poplite_am_session_end-dialog__title': {
      fontWeight: 600,
      fontSize: 20,
      color: '#333333',
    },
    '& .poplite_am_session_end-dialog__content': {
      fontWeight: 300,
      fontSize: 16,
      color: '#333333',
    },
    '& .poplite_am_session_end-dialog__actions': {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: 12,
    },
    '& .poplite_am_session_end-dialog__btn': {
      padding: '10px 20px',
    },
    '& .poplite_am_session_end-dialog__btn-submit': {
      color: 'white',
      backgroundColor: theme.palette.success.main,

      '&:hover': {
        backgroundColor: theme.palette.success.dark,
      },
    },
  },
}));

function useEndSession() {
  const dispatch = useDispatch();
  const snackbar = useSnackbar();
  const userEmail = useSelector((state) => state.amRemoteAccess.userEmail);

  return async function onEndSession() {
    try {
      await endSession(userEmail);
      dispatch({ type: 'REMOTE_ACCESS_INACTIVE' });

      window.close();
    } catch (error) {
      const reason = getErrorMessage(error);
      snackbar({ type: 'error', message: reason });
    }
  };
}

export function ButtonEndSession() {
  const classes = useStyles();
  const onEndSession = useEndSession();

  return (
    <Button
      id="button_end_session"
      color="inherit"
      className={classes.buttonEndSession}
      onClick={onEndSession}
      startIcon={
        <img
          src={PopulixLogoutIcon}
          alt="logout"
          className={classes.iconEndSession}
        />
      }
    >
      End Session
    </Button>
  );
}

// put the selector outside the component to avoid unnecessary re-render
const remoteAccessInfoSelector = (state) => ({
  userEmail: state.amRemoteAccess.userEmail,
  sessionRemaining: state.amRemoteAccess.sessionRemaining,
});

const oneSecond = 1_000;

export function RemoteAccessInfo() {
  const classes = useStyles();
  const onEndSession = useEndSession();
  const { userEmail, sessionRemaining } = useSelector(remoteAccessInfoSelector);
  const [timerTimeout, setTimerTimeout] = React.useState(null); // timer on miliseconds
  const [isOpenModal, toggleOpenModal] = React.useReducer(
    (state) => !state,
    false,
  );

  const timeoutRemaining = new Date(timerTimeout)
    .toISOString()
    .substring(11, 19);

  React.useEffect(() => {
    setTimerTimeout(sessionRemaining);
  }, [sessionRemaining]);

  React.useEffect(() => {
    if (timerTimeout !== null && timerTimeout <= 0) {
      toggleOpenModal();
    }
  }, [timerTimeout]);

  React.useEffect(() => {
    // exit early when we reach 0
    if (timerTimeout <= 0) return;

    // save the interval id so we can clean it up when component unmounts
    const timerInterval = setInterval(
      () => setTimerTimeout((timer) => timer - oneSecond),
      oneSecond,
    );

    // clean up the interval to avoid memory leak
    return () => clearInterval(timerInterval);
  }, [sessionRemaining, timerTimeout]);

  return (
    <>
      <Box marginRight="14px">
        <Box color="#FFFFFF" fontWeight={600} fontSize={12}>
          Is Accessing {userEmail}’s Dashboard
        </Box>
        <Box color="#FFFFFF" fontWeight={300} fontSize={12}>
          The session will automatically end in:{' '}
          <Box component="span" color="#FFC107" fontWeight={600} fontSize={12}>
            {timeoutRemaining}
          </Box>
        </Box>
      </Box>

      <Dialog
        open={isOpenModal}
        disableBackdropClick
        disableEscapeKeyDown
        classes={{
          root: classes.dialog,
          paper: 'poplite_am_session_end-dialog__paper',
        }}
      >
        <Typography className="poplite_am_session_end-dialog__title">
          Session Expired
        </Typography>

        <Divider />

        <Typography className="poplite_am_session_end-dialog__content">
          Your session has expired. To access this user's account, you need to
          log in as this user again.
        </Typography>

        <Divider />

        <div className="poplite_am_session_end-dialog__actions">
          <Button
            onClick={onEndSession}
            className="poplite_am_session_end-dialog__btn poplite_am_session_end-dialog__btn-submit"
          >
            Back to Admin Dashboard
          </Button>
        </div>
      </Dialog>
    </>
  );
}

import mock from 'src/utils/mock';

mock.onGet('/researchers/studies/1').reply(200, {
  message: 'Success.',
  code: 200,
  data: {
    study: {
      id: 1,
      title: 'P. Flash',
      description: 'Lagi bosen ya? Sambil diisi aja studi ini #DiRumahSaja',
      criteria:
        '{"Gender":["Laki-Laki","Perempuan"],"Age":[25,45],"JobStatus":["Bekerja penuh waktu (full-time)","Bekerja paruh waktu (part-time)","Tenaga lepas (freelancer)"],"Domicile":["Jakarta Barat","Jakarta Pusat","Jakarta Selatan","Jakarta Timur","Jakarta Utara","Bogor","Bandung","Surabaya","Medan","Palembang","Makassar","Semarang"]}',
      criteriaQuota:
        '{"Gender":{"Laki-Laki":{"quota":500,"available":500},"__others__":{"quota":500,"available":500}},"Domicile":{"Semarang":{"quota":100,"available":100},"Bandung":{"quota":150,"available":150},"Surabaya":{"quota":150,"available":150},"Medan":{"quota":100,"available":100},"Palembang":{"quota":100,"available":100},"Makassar":{"quota":100,"available":100},"__others__":{"quota":300,"available":300}}}',
      studyUrl: 'https://www.surveygizmo.eu/s3/999999/PortaitSurvey',
      researcherEmail: 'admin@populix.co',
      maxAllowedTime: 5,
      averageCompletionTime: 5,
      participantReward: 5000,
      requiredParticipants: 1000,
      status: 'ACTIVE',
      createdBy: 'admin@populix.co',
      updatedBy: 'admin@populix.co',
      startDate: '2020-04-16T05:02:31.000Z',
      endDate: '2020-04-23T05:02:31.000Z',
      createdAt: '2020-04-16T05:02:06.000Z',
      updatedAt: '2020-04-16T05:02:34.000Z',
      deletedAt: null,
      isOwn: true,
      whitelistEmail: null,
      type: 'poplite',
      screeningType: 'default',
      code: null,
      deviceType: ['web', 'android', 'ios'],
      guestStudyUrl:
        'https://www.populix.co/guest/study/db45e3d3781efa009607da5f3540b014',
      StudyGroupId: 1,
      StudyGroup: null,
      participantDone: 811,
    },
  },
});

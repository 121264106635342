import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from '@material-ui/core';
import { any, bool, func, oneOf, string } from 'prop-types';
import React from 'react';
import { GreenButton, OrangeButton, RedButton } from 'src/components/Buttons';
import useStyles from './styles';

const ConfirmDialog = ({
  open,
  onClose,
  title,
  content,
  variant,
  cancelText,
  confirmText,
  onConfirm,
  onCancel,
  isLoading,
  fullWidthBtn,
}) => {
  const classes = useStyles();

  const buttonContent = isLoading ? (
    <CircularProgress size={18} color="inherit" />
  ) : (
    confirmText
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{ paper: classes.dialogPaper }}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle>
        <Typography variant="h5" color="textPrimary" gutterBottom>
          {title}
        </Typography>
        <Divider />
      </DialogTitle>
      {!!content && (
        <DialogContent className={classes.dialogContent}>
          <Typography
            variant="h5"
            color="textPrimary"
            className={classes.content}
            gutterBottom
          >
            {content}
          </Typography>
          <Divider />
        </DialogContent>
      )}
      <DialogActions className={classes.dialogActions}>
        {!!cancelText && (
          <Button
            variant="text"
            onClick={onCancel || onClose}
            className={classes.button}
            disabled={isLoading}
          >
            {cancelText}
          </Button>
        )}
        {!!confirmText &&
          {
            destructive: (
              <RedButton
                id="red-button"
                className={classes.button}
                onClick={onConfirm}
                disabled={isLoading}
                fullWidth={fullWidthBtn || !cancelText}
              >
                {buttonContent}
              </RedButton>
            ),
            constructive: (
              <GreenButton
                id="green-button"
                className={classes.button}
                onClick={onConfirm}
                disabled={isLoading}
                fullWidth={fullWidthBtn}
              >
                {buttonContent}
              </GreenButton>
            ),
            neutral: (
              <OrangeButton
                id="orange-button"
                className={classes.button}
                onClick={onConfirm}
                disabled={isLoading}
              >
                {buttonContent}
              </OrangeButton>
            ),
          }[variant]}
      </DialogActions>
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  open: bool,
  onClose: func,
  title: string,
  content: any,
  variant: oneOf(['constructive', 'destructive', 'neutral']),
  cancelText: string,
  confirmText: string,
  onConfirm: func,
  onCancel: func,
  isLoading: bool,
  fullWidthBtn: bool,
};

ConfirmDialog.defaultProps = {
  variant: 'constructive',
  cancelText: 'Cancel',
  confirmText: 'Ok',
  fullWidthBtn: false,
};

export default ConfirmDialog;

import React, { useState, useRef, useEffect } from 'react';
import _ from 'lodash';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router';
import { useSnackbar } from 'src/utils/snackbar';
import { popCall, getErrorMessage } from 'src/utils/popCall';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import {
  AppBar,
  Button,
  IconButton,
  Toolbar,
  Hidden,
  colors,
  Badge,
  Typography,
  ButtonBase,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import {
  logout,
  showSnackbar,
  setWalletInfo,
  resetWalletInfo,
  getNotifications,
  setPopliteFeature,
  resetPopliteFeature,
  setCreditWalletInfo,
  resetCreditWalletInfo,
} from 'src/actions';
import {
  POPLITE,
  DOSEN_BINUS,
  POPLITE_FEATURE,
  CXTOOLS_FEATURE,
  ADMIN,
} from 'src/utils/variables';
import PopulixLogoutIcon from 'src/assets/svg/logout.svg';
import PopulixAvatarIcon from 'src/assets/svg/avatar.svg';
import PopulixMobile from 'src/assets/svg/populix-mobile.svg';
import PopulixForBusinessLogo from 'src/assets/svg/populixForBusinessNew.svg';
import NotificationsPopover from 'src/components/NotificationsPopover';
import PopliteFeatureMenu from './PopliteFeatureMenu';
import PopliteQuestionaireMobileView from './PopliteQuestionaire/PopliteQuestionaireMobileView';
import PopliteQuestionaireNonMobileView from './PopliteQuestionaire/PopliteQuestionaireNonMobileView';
import {
  PopliteContactExpert,
  LinkScheduleMeeting,
} from './PopliteContactExpert';
import { ButtonEndSession, RemoteAccessInfo } from './PopliteAccountManager';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
    backgroundImage: 'linear-gradient(180deg, #00417D 0%, #002B5A 100%)',
  },
  topFixed: {
    background: '#0060AB',
    textAlign: 'center',
    padding: 14,
    fontSize: 14,
    position: 'relative',

    '& .top-fixed__btn': {
      color: '#FFFFFF',
      fontWeight: 600,
      fontSize: 14,
      textDecoration: 'underline',
    },

    '& .top-fixed__btn-close': {
      color: '#FFFFFF',
      fontWeight: 600,
      fontSize: 14,
      textDecoration: 'underline',
      position: 'absolute',
      right: 20,
      top: 0,
      bottom: 0,
    },
  },
  toolbarContainer: {
    minHeight: 72,
  },
  flexGrow: {
    flexGrow: 1,
  },
  search: {
    backgroundColor: 'rgba(255,255,255, 0.1)',
    borderRadius: 4,
    flexBasis: 300,
    height: 36,
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center',
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: 'inherit',
  },
  searchInput: {
    flexGrow: 1,
    color: 'inherit',
    '& input::placeholder': {
      opacity: 1,
      color: 'inherit',
    },
  },
  searchPopper: {
    zIndex: theme.zIndex.appBar + 100,
  },
  searchPopperContent: {
    marginTop: theme.spacing(1),
  },
  trialButton: {
    marginLeft: theme.spacing(2),
    color: theme.palette.common.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900],
    },
  },
  trialIcon: {
    marginRight: theme.spacing(1),
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  chatButton: {
    marginLeft: theme.spacing(1),
  },
  notificationsButton: {
    marginLeft: -24,
  },
  notificationsBadge: {
    backgroundColor: (props) => (props?.TotalUnread ? '#DB3737' : 'none'),
    width: (props) =>
      props?.TotalUnread > 9 ? theme.spacing(4) : theme.spacing(2),
    maxWidth: theme.spacing(4),
    height: 20,
    marginTop: 12,
    left: (props) => (props?.TotalUnread > 9 ? 12 : 16),
  },
  notificationIcon: {
    marginRight: (props) => (!props?.TotalUnread ? -24 : 0),
  },
  populixBusinessLogo: {
    marginTop: theme.spacing(1),
    width: '50%',
    minWidth: 200,
  },
  populixMobile: {
    marginTop: theme.spacing(1),
  },
  notificationContainer: {
    borderRadius: theme.spacing(9),
    padding: (props) => (props?.TotalUnread > 9 ? '0 36px 0 24px' : '0 32px'),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginRight: theme.spacing(1.5),
    cursor: 'pointer',
    border: '1px solid #002B5A',
    backgroundColor: '#002B5A',
    height: 40,
    '&:hover': {
      border: '1px solid #ffffff',
    },
  },
  logoutButton: {
    height: 40,
    minWidth: 60,
    borderRadius: theme.spacing(9),
    padding: '10px 12px',
    textTransform: 'capitalize',
    backgroundColor: '#002B5A',
    border: '1px solid #002B5A',
    '&:hover': {
      border: '1px solid #ffffff',
    },
    '& .MuiButton-startIcon': {
      marginLeft: 0,
      marginRight: 5,
    },
  },
  activeButton: {
    backgroundColor: '#00417D',
    border: '1px solid #ffffff',
  },
  avatarButton: {
    marginRight: theme.spacing(2),
  },
  userLabel: {
    fontSize: 14,
    fontWeight: '600',
    textTransform: 'none',
    color: '#ffffff !important',
    marginLeft: 8,
    maxWidth: 90,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  logoutIcon: {
    marginRight: theme.spacing(1),
  },
  logoSection: {
    width: 'min-content',
  },
}));

function TopBar({
  onOpenNavBarMobile,
  className,
  isShowTopFixed,
  onCloseTopFixed,
  clean,
  ...rest
}) {
  const snackbar = useSnackbar();
  const { roles, nonAuth } = rest;
  const isCxToolsFeature = useSelector(
    (state) => state.popliteDrawer.isCxToolsFeature,
  );
  const isPoplite = roles?.includes(POPLITE);
  const isAdmin = roles?.includes(ADMIN);
  const isAllowedToAccess =
    roles?.includes(POPLITE) || roles?.includes(DOSEN_BINUS);
  const popliteRedirect = isCxToolsFeature
    ? '/poplite/cx-tools/survey'
    : '/poplite/projects';
  const url = isPoplite ? popliteRedirect : '/projects/browse';
  const rootUrl = roles?.includes('retailAuditQC')
    ? '/retail-audit/browse'
    : url;
  const history = useHistory();
  const location = useLocation();
  const bypassAuth = location.pathname.includes('example');
  const isProfileRoute = location.pathname.includes('profile');
  const notificationsRef = useRef(null);
  const [notifications, setNotifications] = useState([]);
  const classes = useStyles(notifications || rest);
  const [openNotifications, setOpenNotifications] = useState(false);
  const [pagination, setPagination] = useState({ page: 1, limit: 1000 });
  const dispatch = useDispatch();
  const session = useSelector((state) => state.session);
  const popliteBalance = useSelector((state) => state.popliteWallet.balance);
  const popliteSelectedFeature = useSelector(
    (state) => state.popliteDrawer.popliteSelectedFeature,
  );
  const { loggedIn, user } = session;
  const isPopliteCxTools = user?.isPopliteCxTools; // user has cxToolsClient role or not
  const isOnPopliteFeature = popliteSelectedFeature === POPLITE_FEATURE;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const isAMRemoting = useSelector((state) => state.amRemoteAccess.isRemoting);

  const [isOnboarding, setIsOnboarding] = useState(0);
  const [dataQuestionaire, setDataQuestionaire] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [isShowDialog, setIsShowDialog] = useState(true);
  const [countUserQuestionaire, setCountUserQuestionaire] = useState(0);

  const [openContact, toggleOpenContact] = React.useReducer(
    (open) => !open,
    false,
  );

  const contactExpertProps = {
    openContact,
    toggleOpenContact,
  };

  const isUserQuestionaire =
    isOnboarding > 0 && isPoplite && !isAdmin && !isCxToolsFeature;

  const formik = useFormik({
    initialValues: {
      selectedInterest: [],
      selectedInterestId: '',
      selectedQuestionaire: [],
    },
    validationSchema: Yup.object().shape({
      selectedQuestionaire: Yup.array().of(
        Yup.object().shape({
          answer: Yup.array()
            .of(Yup.string())
            .min(1, 'Jawabanmu tidak boleh kosong'),
        }),
      ),
    }),
    onSubmit: async (values) => {
      handleSubmit(values);
    },
    validateOnChange: true,
    validateOnBlur: true,
  });

  const fetchDataUserQuestionaire = async () => {
    setIsFetching(true);
    const path = `/popliteOnboarding/questionnaires`;
    try {
      const res = await popCall.get(path);
      setDataQuestionaire(res?.data);
    } catch (error) {
      const reason = getErrorMessage(error);
      snackbar({ type: 'error', message: reason });
    } finally {
      setIsFetching(false);
    }
  };

  const fetchProfile = async () => {
    setIsFetching(true);
    try {
      const { data } = await popCall.get('/profile');
      setIsOnboarding(data?.isOnboarding);
    } catch (error) {
      const reason = getErrorMessage(error);
      snackbar({ type: 'error', message: reason });
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    const allowedRole = isPoplite && !isAdmin && !isCxToolsFeature;
    if (allowedRole) {
      fetchDataUserQuestionaire();
      fetchProfile();
    }
  }, []);

  useEffect(() => {
    const dataTypeSelected = dataQuestionaire.filter(
      (value) => value.type === 'SELECT',
    );
    const dataSelectedQuestionaire = dataTypeSelected.map((value) => {
      return {
        questionId: value.id,
        answer: [],
      };
    });
    formik.setFieldValue('selectedQuestionaire', dataSelectedQuestionaire);
  }, [dataQuestionaire]);

  const handleChangeSelectedQuestionaire = (event, id, index) => {
    const value = event.target.value;
    const updatedSelectedQuestionaire = [...formik.values.selectedQuestionaire];
    const foundIndex = updatedSelectedQuestionaire.findIndex(
      (x) => x.questionId === id,
    );
    if (foundIndex > -1) {
      updatedSelectedQuestionaire[foundIndex] = {
        questionId: id,
        answer: [value],
      };
      formik.setFieldValue('selectedQuestionaire', updatedSelectedQuestionaire);
    } else {
      updatedSelectedQuestionaire[index] = {
        questionId: id,
        answer: [value],
      };
    }
  };

  const handleChangeSelectedInterest = (event, value, id) => {
    event.preventDefault();
    const hasInclude = formik.values.selectedInterest.includes(value);

    if (hasInclude) {
      const updatedSelected = _.remove(
        formik.values.selectedInterest,
        (k) => k !== value,
      );
      formik.setFieldValue('selectedInterest', updatedSelected);
    } else {
      const updatedSelectedInterest = [...formik.values.selectedInterest];
      updatedSelectedInterest.push(value);
      formik.setFieldValue('selectedInterest', updatedSelectedInterest);
    }
    formik.setFieldValue('selectedInterestId', id);
  };

  const handleSubmit = async (values) => {
    setIsFetching(true);
    const path = `/popliteOnboarding/submitQuestionnaire`;

    const body = {
      questionnaires: [
        ...values.selectedQuestionaire,
        {
          questionId: values.selectedInterestId,
          answer: values.selectedInterest,
        },
      ],
    };
    const isValid =
      values.selectedInterest.length &&
      dataQuestionaire.length === body?.questionnaires.length;
    try {
      if (isValid) {
        await popCall.post(path, body);
        snackbar({ type: 'success', message: 'Jawabanmu berhasil disimpan' });
        setIsShowDialog(false);
      } else {
        snackbar({ type: 'error', message: 'Mohon isi jawabanmu' });
      }
    } catch (error) {
      const reason = getErrorMessage(error);
      snackbar({ type: 'error', message: reason });
    } finally {
      setIsFetching(false);
    }
  };

  const handleLogout = async () => {
    dispatch(resetPopliteFeature());
    dispatch(resetCreditWalletInfo());
    dispatch(logout());
    dispatch(resetWalletInfo());

    if (bypassAuth) {
      history.push('/auth/login');
    }
  };

  const handleNotificationsOpen = () => {
    setOpenNotifications(true);
  };

  const handleNotificationsClose = () => {
    setOpenNotifications(false);
  };

  const reduceNotifications = (items) => {
    const notificationList = Array.from(
      new Set(items.map((a) => a.NotificationId)),
    ).map((id) => {
      return items.find((a) => a.NotificationId === id);
    });

    return notificationList;
  };

  const handleReadNotification = async (notificationId) => {
    try {
      const prevNotifications = session?.notifications?.Notifications;
      await popCall.put(`/notifications/participant/${notificationId}`);

      const refreshNotification = await popCall.get(
        `/notifications/participant?page=${pagination.page}&limit=${pagination.limit}`,
      );

      const newNotifications = reduceNotifications(
        refreshNotification?.data?.Notifications?.concat(prevNotifications),
      );

      dispatch(
        getNotifications({
          ...refreshNotification?.data,
          isLoadMore: session?.notifications?.isLoadMore,
          Notifications: newNotifications,
        }),
      );
    } catch (error) {
      dispatch(showSnackbar('Failed to read notification.', 'error'));
    }
  };

  const handleLoadMoreNotifications = async () => {
    try {
      // const prevNotifications = session?.notifications?.Notifications;
      // const result = await popCall.get(
      //   `/notifications/participant?page=${pagination.page}&limit=${pagination.limit}`,
      // );
      // const newNotifications = reduceNotifications(
      //   result?.data?.Notifications?.concat(prevNotifications),
      // );
      // if (!result?.data?.Notifications?.length) {
      //   dispatch(
      //     getNotifications({
      //       ...result?.data,
      //       isLoadMore: false,
      //       Notifications: newNotifications,
      //     }),
      //   );
      // }
      // if (result?.data?.Notifications?.length) {
      //   dispatch(
      //     getNotifications({
      //       ...result?.data,
      //       isLoadMore: true,
      //       Notifications: newNotifications,
      //     }),
      //   );
      //   setPagination({ page: pagination.page + 1, limit: pagination.limit });
      // }
    } catch (error) {
      dispatch(showSnackbar('Failed to load more notification.', 'error'));
    }
  };

  useEffect(() => {
    setNotifications(session?.notifications);
  }, [session?.notifications]);

  useEffect(() => {
    const isPopliteRoute = location.pathname.includes('poplite');
    const shouldFetchPopliteWallet = !popliteBalance;

    const loginRoute = isPopliteRoute ? '/auth/login-poplite' : '/auth/login';
    if (!nonAuth && !loggedIn && !bypassAuth) {
      history.push(loginRoute);
    }

    if (isPoplite && !popliteSelectedFeature) {
      const isCxTools = location.pathname.includes('cx-tools');

      dispatch(
        setPopliteFeature(isCxTools ? CXTOOLS_FEATURE : POPLITE_FEATURE),
      );
    }

    if (isPoplite && loggedIn && shouldFetchPopliteWallet) {
      dispatch(setWalletInfo());
    }

    if (isPoplite && loggedIn && isPopliteCxTools) {
      dispatch(setCreditWalletInfo());
    }
  }, [loggedIn]);

  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      {isShowTopFixed ? (
        <div className={classes.topFixed}>
          Mari bicara dengan Poplite Expert untuk mempelajari lebih lanjut
          tentang produk ini.{' '}
          <LinkScheduleMeeting className="top-fixed__btn">
            Jadwalkan Demo Produk
          </LinkScheduleMeeting>{' '}
          atau{' '}
          <ButtonBase className="top-fixed__btn" onClick={toggleOpenContact}>
            Email ke Poplite Expert
          </ButtonBase>
          <IconButton
            disableRipple
            disableFocusRipple
            size="small"
            color="inherit"
            className="top-fixed__btn-close"
            onClick={onCloseTopFixed}
          >
            <CloseIcon />
          </IconButton>
        </div>
      ) : null}

      <Toolbar className={classes.toolbarContainer}>
        <Hidden lgUp>
          {!clean && (
            <IconButton
              id="humberger_menu_button"
              className={classes.menuButton}
              color="inherit"
              onClick={onOpenNavBarMobile}
            >
              <MenuIcon />
            </IconButton>
          )}

          <RouterLink to={rootUrl} className={classes.logoSection}>
            <img
              src={isMobile ? PopulixMobile : PopulixForBusinessLogo}
              alt="Populix Logo"
              className={
                isMobile ? classes.populixMobile : classes.populixBusinessLogo
              }
            />
          </RouterLink>

          {isPopliteCxTools && !isAMRemoting ? <PopliteFeatureMenu /> : null}

          <div className={classes.flexGrow} />

          {!clean && (
            <IconButton
              className={clsx(
                classes.logoutButton,
                classes.avatarButton,
                isProfileRoute && classes.activeButton,
              )}
              onClick={() =>
                isAllowedToAccess && history.push('/poplite/profile')
              }
              color="inherit"
            >
              <img src={PopulixAvatarIcon} alt="username" />
              {!isMobile && (
                <Typography className={classes.userLabel}>
                  {session?.user?.firstName}
                </Typography>
              )}
            </IconButton>
          )}

          {isAllowedToAccess && isOnPopliteFeature && !isAMRemoting ? (
            <div className={classes.notificationContainer}>
              <IconButton
                className={classes.notificationsButton}
                color="inherit"
                onClick={
                  isMobile
                    ? () => history.push('/poplite/notifications')
                    : handleNotificationsOpen
                }
                ref={notificationsRef}
              >
                <Badge
                  badgeContent={notifications?.TotalUnread}
                  classes={{ badge: classes.notificationsBadge }}
                  variant="standard"
                >
                  <NotificationsIcon className={classes.notificationIcon} />
                </Badge>
              </IconButton>
            </div>
          ) : null}

          {isOnPopliteFeature && !isAMRemoting ? (
            <PopliteContactExpert {...contactExpertProps} />
          ) : null}

          {isAMRemoting ? <ButtonEndSession /> : null}
        </Hidden>
        <Hidden mdDown>
          <RouterLink to={rootUrl} className={classes.logoSection}>
            <img
              src={isMobile ? PopulixMobile : PopulixForBusinessLogo}
              alt="Populix Logo"
              className={
                isMobile ? classes.populixMobile : classes.populixBusinessLogo
              }
              style={{ marginLeft: 40 }}
            />
          </RouterLink>

          {isPopliteCxTools && !isAMRemoting ? <PopliteFeatureMenu /> : null}

          <div className={classes.flexGrow} />

          {!clean && (
            <IconButton
              className={clsx(
                classes.logoutButton,
                classes.avatarButton,
                isProfileRoute && classes.activeButton,
              )}
              onClick={() =>
                isAllowedToAccess && history.push('/poplite/profile')
              }
              color="inherit"
            >
              <img src={PopulixAvatarIcon} alt="username" />
              {!isMobile && (
                <Typography className={classes.userLabel}>
                  {session?.user?.firstName}
                </Typography>
              )}
            </IconButton>
          )}

          {isOnPopliteFeature && isAMRemoting ? <RemoteAccessInfo /> : null}

          {isAllowedToAccess && isOnPopliteFeature && !isAMRemoting ? (
            <div
              className={classes.notificationContainer}
              onClick={handleNotificationsOpen}
            >
              <IconButton
                className={classes.notificationsButton}
                color="inherit"
                ref={notificationsRef}
              >
                <Badge
                  badgeContent={notifications?.TotalUnread}
                  classes={{ badge: classes.notificationsBadge }}
                  variant="standard"
                >
                  <NotificationsIcon className={classes.notificationIcon} />
                </Badge>
              </IconButton>
            </div>
          ) : null}

          {isOnPopliteFeature && !isAMRemoting ? (
            <PopliteContactExpert {...contactExpertProps} />
          ) : null}

          {!nonAuth && loggedIn && !isAMRemoting && !clean ? (
            <Button
              id="logout_button"
              color="inherit"
              onClick={handleLogout}
              className={classes.logoutButton}
              startIcon={
                <img
                  src={PopulixLogoutIcon}
                  alt="logout"
                  className={classes.logoutIcon}
                />
              }
            >
              Sign Out
            </Button>
          ) : null}

          {isAMRemoting ? <ButtonEndSession /> : null}
        </Hidden>
      </Toolbar>
      {isAllowedToAccess && (
        <NotificationsPopover
          anchorEl={notificationsRef.current}
          notifications={notifications}
          onClose={handleNotificationsClose}
          open={openNotifications}
          onClick={handleReadNotification}
          isLoadMore={session?.notifications?.isLoadMore}
          handleLoadMoreNotifications={handleLoadMoreNotifications}
        />
      )}
      {isUserQuestionaire &&
        (isMobile ? (
          <PopliteQuestionaireMobileView
            isShow={isShowDialog}
            onSubmit={handleSubmit}
            dataQuestionaire={dataQuestionaire}
            handleChangeSelect={handleChangeSelectedQuestionaire}
            handleChangeSelectChip={handleChangeSelectedInterest}
            countUserQuestionaire={countUserQuestionaire}
            setCountUserQuestionaire={setCountUserQuestionaire}
            formik={formik}
            isFetching={isFetching}
          />
        ) : (
          <PopliteQuestionaireNonMobileView
            isShow={isShowDialog}
            onSubmit={handleSubmit}
            dataQuestionaire={dataQuestionaire}
            handleChangeSelect={handleChangeSelectedQuestionaire}
            handleChangeSelectChip={handleChangeSelectedInterest}
            formik={formik}
            isFetching={isFetching}
          />
        ))}
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func,
  isShowTopFixed: PropTypes.bool,
  onCloseTopFixed: PropTypes.func,
  clean: PropTypes.bool,
};

export default TopBar;

import * as actionTypes from 'src/actions';
import { POPLITE_FEATURE, CXTOOLS_FEATURE } from 'src/utils/variables';

const initialState = {
  isPopliteDrawerOpen: true,
  isFeatureMenuOpen: false,
  popliteSelectedFeature: null,
  isPopliteFeature: false,
  isCxToolsFeature: false,
};

const popliteDrawerReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SET_OPEN_POPLITE_DRAWER: {
      return {
        ...state,
        isPopliteDrawerOpen: payload,
      };
    }
    case actionTypes.SET_OPEN_FEATURE_MENU: {
      return {
        ...state,
        isFeatureMenuOpen: payload,
      };
    }
    case actionTypes.SET_POPLITE_FEATURE: {
      return {
        ...state,
        popliteSelectedFeature: payload,
        isPopliteFeature: payload === POPLITE_FEATURE,
        isCxToolsFeature: payload === CXTOOLS_FEATURE,
      };
    }
    case actionTypes.RESET_POPLITE_FEATURE: {
      return {
        ...state,
        popliteSelectedFeature: null,
        isPopliteFeature: false,
        isCxToolsFeature: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default popliteDrawerReducer;

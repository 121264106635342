import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const DoubleArrowIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg width="12" height="20" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 12.5905L1.41 14.0005L6 9.42049L10.59 14.0005L12 12.5905L6 6.59049L0 12.5905Z" fill="#FF8D3F"/>
        <path d="M0 6.00049L1.41 7.41049L6 2.83049L10.59 7.41049L12 6.00049L6 0.000488281L0 6.00049Z" fill="#FF8D3F"/>
      </svg>
    </SvgIcon>
  );
};

export default DoubleArrowIcon;

/* eslint-disable implicit-arrow-linebreak */

import axios from 'axios';
import Auth from '@aws-amplify/auth';
import { getPopliteClientEmail } from './remoteClient';

const methods = ['get', 'post', 'put', 'patch', 'delete'];

export default class PopCall {
  constructor(isExternal, externalUrl) {
    this.apiclient = axios.create({
      baseURL: isExternal ? externalUrl : `${process.env.REACT_APP_API_HOST}`, // use env
      headers: {
        'Content-Type': 'application/json',
      },
    });
    this.defineInterceptor();
    methods.forEach((method) => {
      this[method] = (targetUrl, body, config) =>
        new Promise((resolve, reject) => {
          this.apiclient[method](targetUrl, body, config)
            .then((res) => {
              resolve(res.data); // Note, might want to adjust the resolved data
            })
            .catch((error) => reject(error));
        });
    });
  }

  defineInterceptor() {
    // We're using interceptor to inject the requests with the idToken from amplify
    this.apiclient.interceptors.request.use(async (config) => {
      try {
        const headers = { ...config.headers };

        const currentSession = await Auth.currentSession();
        if (currentSession) {
          Object.assign(headers, {
            Authorization: currentSession.idToken.jwtToken,
          });
        }

        const clientEmail = getPopliteClientEmail();
        if (clientEmail) {
          Object.assign(headers, {
            'poplite-client-email': clientEmail,
          });
        }

        return { ...config, headers };
      } catch (error) {
        return config;
      }
    });
  }
}
export const popCall = new PopCall();

export function getErrorMessage(error) {
  const fallbackMessage = 'Something went wrong in server';

  // catch axios error response
  if ('response' in error) {
    // return the status text went server send HTML
    if (error?.response?.headers['content-type'].includes('text/html')) {
      return error?.response?.statusText;
    }

    const responseError =
      error?.response?.data?.message ?? // reason error
      error?.response?.data?.errors[0]?.msg; // validation message

    if (responseError) {
      return responseError;
    }
  }

  return error?.message ?? fallbackMessage;
}

import mock from 'src/utils/mock';

const numberOfQuestions = Math.floor(Math.random() * 5 + 2); // random value from 2 to 6
function numberOfAnswers() {
  return Math.floor(Math.random() * 5 + 2); // random value from 2 to 6
}
function answersValue() {
  return Math.floor(Math.random() * 1000); // random value from 0 to 1000
}

const questions = [];
for (let i = 1; i <= numberOfQuestions; i++) {
  const data = [];
  const labels = [];
  const prepQuestions = [];
  for (let j = 1; j <= numberOfAnswers(); j++) {
    prepQuestions.push({ data: answersValue(), labels: `Answer ${j}` });
  }
  prepQuestions.sort((a, b) => (a.data > b.data ? 1 : -1)); // sort from lowest
  prepQuestions.forEach((question) => {
    data.push(question.data);
    labels.push(question.labels);
  });
  questions.push({ title: `Question ${i}`, data, labels });
}

mock.onGet('/researchers/studyGroups/2/result').reply(200, {
  data: questions,
});

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './amplify/amplify-configure';

if (process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID) {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
  };
  TagManager.initialize(tagManagerArgs);
}

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();

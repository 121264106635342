import * as actionTypes from 'src/actions';

const initialState = {
  status: null,
  isSubmittingDraft: false,
  isFetching: true,
  isEditing: false,
  demographics: [],
  studyGroups: [],
};

const clientManagmentReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.SET_STATUS_CLIENT_MANAGEMENT:
      return {
        ...state,
        status: payload,
      };

    case actionTypes.SET_DEMOGRAPHICS_CLIENT_MANAGEMENT:
      return {
        ...state,
        isEditing: true,
        demographics: payload,
      };

    case actionTypes.SET_STUDYGROUPS_CLIENT_MANAGEMENT:
      return {
        ...state,
        isEditing: true,
        studyGroups: payload,
      };
    case actionTypes.INIT_CLIENT_MANAGEMENT:
      return {
        ...state,
        demographics: payload.demographics,
        studyGroups: payload.studyGroups,
      };
    case actionTypes.SET_PUBLISHING_CLIENT_MANAGEMENT:
      return {
        ...state,
        isPublishing: payload,
      };
    case actionTypes.SET_FETCHING_CLIENT_MANAGEMENT:
      return {
        ...state,
        isFetching: payload,
      };
    case actionTypes.SET_SUBMITTING_DRAFT_CLIENT_MANAGEMENT:
      return {
        ...state,
        isSubmittingDraft: payload,
      };
    case actionTypes.SET_EDITING_CLIENT_MANAGEMENT:
      return {
        ...state,
        isEditing: payload,
      };
    case actionTypes.RESET_CLIENT_MANAGEMENT:
      return initialState;
    default:
      return state;
  }
};

export default clientManagmentReducer;

import * as actionTypes from '../actions/remoteConfigActions';

const initialState = {};

export default function remoteConfigReducer(state = initialState, actions) {
  const { payload, type } = actions;
  switch (type) {
    case actionTypes.SET_REMOTE_CONFIG:
      return { ...state, ...payload };
    default:
      return state;
  }
}

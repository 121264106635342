import { white } from '../color';

export default {
  outlined: {
    '&.MuiInputLabel-shrink': {
      padding: '4px',
      backgroundColor: white,
    },
  },
};

import mock from 'src/utils/mock';

mock.onGet('/researchers/studyGroups/1/panel').reply(200, {
  message: 'Success',
  code: 200,
  data: {
    Age: {
      label: [
        '<18',
        '18-24',
        '25-30',
        '31-35',
        '36-40',
        '41-45',
        '46-50',
        '51-55',
        '>55',
      ],
      value: [1, 1, 1, 0, 0, 0, 0, 0, 0],
    },
    Gender: {
      label: ['Laki-Laki', 'Perempuan'],
      value: [
        {
          id: 'Laki-Laki',
          label: 'Laki-Laki',
          value: 1,
        },
        {
          id: 'Perempuan',
          label: 'Perempuan',
          value: 2,
        },
      ],
    },
    SocialEconomicStatus: {
      label: ['A', 'B', 'C', 'D', 'E'],
      value: [0, 1, 1, 1, 0],
    },
    JobStatus: {
      label: [
        'Bekerja penuh waktu (full-time)',
        'Bekerja paruh waktu (part-time)',
        'Tenaga lepas (freelancer)',
        'Tidak bekerja (sedang mencari pekerjaan)',
        'Tidak bekerja (masih pelajar)',
        'Tidak bekerja (Ibu rumah tangga, pensiunan, penyandang disabilitas)',
      ],
      value: [0, 1, 1, 0, 1, 0],
    },
    Domicile: {
      'Jakarta Selatan': 1,
      'Jakarta Barat': 1,
      'Jakarta Pusat': 1,
    },
  },
});

// TODO: get study group panel for DQ
mock.onGet('/researchers/studyGroups/2/panel').reply(200, {
  message: 'Success',
  code: 200,
  data: {
    Age: {
      label: [
        '<18',
        '18-24',
        '25-30',
        '31-35',
        '36-40',
        '41-45',
        '46-50',
        '51-55',
        '>55',
      ],
      value: [1, 1, 1, 0, 0, 0, 0, 0, 0],
    },
    Gender: {
      label: ['Laki-Laki', 'Perempuan'],
      value: [
        {
          id: 'Laki-Laki',
          label: 'Laki-Laki',
          value: 1,
        },
        {
          id: 'Perempuan',
          label: 'Perempuan',
          value: 2,
        },
      ],
    },
    SocialEconomicStatus: {
      label: ['A', 'B', 'C', 'D', 'E'],
      value: [0, 1, 1, 1, 0],
    },
    JobStatus: {
      label: [
        'Bekerja penuh waktu (full-time)',
        'Bekerja paruh waktu (part-time)',
        'Tenaga lepas (freelancer)',
        'Tidak bekerja (sedang mencari pekerjaan)',
        'Tidak bekerja (masih pelajar)',
        'Tidak bekerja (Ibu rumah tangga, pensiunan, penyandang disabilitas)',
      ],
      value: [0, 1, 1, 0, 1, 0],
    },
  },
});

mock
  .onGet(
    '/researchers/studyGroups/1/panel?filter[by]=question&filter[title]=Favorite drink?&filter[values]=Air Mineral',
  )
  .reply(200, {
    message: 'Success',
    code: 200,
    data: {
      Age: {
        label: [
          '<18',
          '18-24',
          '25-30',
          '31-35',
          '36-40',
          '41-45',
          '46-50',
          '51-55',
          '>55',
        ],
        value: [1, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      Gender: {
        label: ['Laki-Laki', 'Perempuan'],
        value: [
          {
            id: 'Laki-Laki',
            label: 'Laki-Laki',
            value: 0,
          },
          {
            id: 'Perempuan',
            label: 'Perempuan',
            value: 1,
          },
        ],
      },
      SocialEconomicStatus: {
        label: ['A', 'B', 'C', 'D', 'E'],
        value: [0, 0, 0, 1, 0],
      },
      JobStatus: {
        label: [
          'Bekerja penuh waktu (full-time)',
          'Bekerja paruh waktu (part-time)',
          'Tenaga lepas (freelancer)',
          'Tidak bekerja (sedang mencari pekerjaan)',
          'Tidak bekerja (masih pelajar)',
          'Tidak bekerja (Ibu rumah tangga, pensiunan, penyandang disabilitas)',
        ],
        value: [0, 0, 0, 0, 1, 0],
      },
      Domicile: {
        'Jakarta Pusat': 1,
      },
    },
  });

mock
  .onGet(
    '/researchers/studyGroups/1/studies/1/panel?filter[by]=question&filter[title]=Favorite drink?&filter[values]=Air Mineral,Kopi,Teh',
  )
  .reply(200, {
    message: 'Success',
    code: 200,
    data: {
      Age: {
        label: [
          '<18',
          '18-24',
          '25-30',
          '31-35',
          '36-40',
          '41-45',
          '46-50',
          '51-55',
          '>55',
        ],
        value: [0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      Gender: {
        label: ['Laki-Laki', 'Perempuan'],
        value: [
          {
            id: 'Laki-Laki',
            label: 'Laki-Laki',
            value: 0,
          },
          {
            id: 'Perempuan',
            label: 'Perempuan',
            value: 0,
          },
        ],
      },
      SocialEconomicStatus: {
        label: ['A', 'B', 'C', 'D', 'E'],
        value: [0, 0, 0, 0, 0],
      },
      JobStatus: {
        label: [
          'Bekerja penuh waktu (full-time)',
          'Bekerja paruh waktu (part-time)',
          'Tenaga lepas (freelancer)',
          'Tidak bekerja (sedang mencari pekerjaan)',
          'Tidak bekerja (masih pelajar)',
          'Tidak bekerja (Ibu rumah tangga, pensiunan, penyandang disabilitas)',
        ],
        value: [0, 0, 0, 0, 0, 0],
      },
      Domicile: null,
    },
  });

/**
 * type: daily questions
 */
mock.onGet('/researchers/studyGroups/3/panel').reply(200, {
  message: 'Success',
  code: 200,
  data: {
    Age: { label: ['<18', '18-24', '25-30', '31-35'], value: [2, 1, 1, 1] },
    Gender: {
      label: ['Laki-Laki', 'Perempuan'],
      value: [
        { id: 'Laki-Laki', label: 'Laki-Laki', value: 3 },
        { id: 'Perempuan', label: 'Perempuan', value: 2 },
      ],
    },
    SocialEconomicStatus: { label: ['A', 'B'], value: [4, 1] },
    JobStatus: {
      label: [
        'Bekerja penuh waktu (full-time)',
        'Tidak bekerja (sedang mencari pekerjaan)',
        'Bekerja paruh waktu (part-time)',
      ],
      value: [3, 1, 1],
    },
    Domicile: {
      'Jakarta Pusat': 2,
      'Jakarta Timur': 2,
      'Jakarta Utara': 1,
    },
  },
});

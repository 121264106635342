import * as actionTypes from 'src/actions';

const initialState = {
  data: [],
  selectedPromoCode: '',
};

const poplitePromoReducer = (state = initialState, actions) => {
  const { payload, type } = actions;

  switch (type) {
    case actionTypes.SET_POPLITE_PROMO:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export default poplitePromoReducer;

import uuid from 'uuid/v1';
import moment from 'moment';
import mock from 'src/utils/mock';

const total = (Math.floor(Math.random() * 11) + 1) * 100;

mock.onGet('/api/researchers/studyGroups').reply(200, {
  total: 12,
  page: 1,
  limit: 10,
  data: [
    {
      id: uuid(),
      title: `P. Quarto ${Math.floor(Math.random() * Math.floor(total))}`,
      studies: [
        {
          title: `Study Mock ${Math.floor(Math.random() * Math.floor(total))}`,
          participantDone: total,
          requiredParticipants: total,
          status: 'STOP',
        },
        {
          title: `Study Mock ${Math.floor(Math.random() * Math.floor(total))}`,
          participantDone: total - 100,
          requiredParticipants: total,
          status: 'ACTIVE',
        },
        {
          title: `Study Mock ${Math.floor(Math.random() * Math.floor(total))}`,
          participantDone: total - 100,
          requiredParticipants: total,
          status: 'ACTIVE',
        },
      ],
      type: 'Online Survey',
      startDate: moment(),
      endDate: moment().add(7, 'day'),
    },
    {
      id: uuid(),
      title: `P. Quarto ${Math.floor(Math.random() * Math.floor(total))}`,
      studies: [
        {
          title: `Study Mock ${Math.floor(Math.random() * Math.floor(total))}`,
          participantDone: 0,
          requiredParticipants: total,
          status: 'NEED_CONFIRMATION',
        },
        {
          title: `Study Mock ${Math.floor(Math.random() * Math.floor(total))}`,
          participantDone: 0,
          requiredParticipants: total,
          status: 'NEED_CONFIRMATION',
        },
      ],
      type: 'Forum Group Discussion',
      startDate: null,
      endDate: null,
    },
    {
      id: uuid(),
      title: `P. Quarto ${Math.floor(Math.random() * Math.floor(total))}`,
      studies: [
        {
          title: `Study Mock ${Math.floor(Math.random() * Math.floor(total))}`,
          participantDone: Math.floor(Math.random() * Math.floor(total)),
          requiredParticipants: total,
          status: 'STOP',
        },
        {
          title: `Study Mock ${Math.floor(Math.random() * Math.floor(total))}`,
          participantDone: Math.floor(Math.random() * Math.floor(total)),
          requiredParticipants: total,
          status: 'STOP',
        },
        {
          title: `Study Mock ${Math.floor(Math.random() * Math.floor(total))}`,
          participantDone: Math.floor(Math.random() * Math.floor(total)),
          requiredParticipants: total,
          status: 'STOP',
        },
      ],
      type: 'Online Survey',
      startDate: moment().subtract(7, 'day'),
      endDate: moment(),
    },
    {
      id: uuid(),
      title: `P. Quarto ${Math.floor(Math.random() * Math.floor(total))}`,
      studies: [
        {
          title: `Study Mock ${Math.floor(Math.random() * Math.floor(total))}`,
          participantDone: total,
          requiredParticipants: total,
          status: 'STOP',
        },
        {
          title: `Study Mock ${Math.floor(Math.random() * Math.floor(total))}`,
          participantDone: total - 100,
          requiredParticipants: total,
          status: 'ACTIVE',
        },
        {
          title: `Study Mock ${Math.floor(Math.random() * Math.floor(total))}`,
          participantDone: total - 100,
          requiredParticipants: total,
          status: 'ACTIVE',
        },
      ],
      type: 'Online Survey',
      startDate: moment(),
      endDate: moment().add(7, 'day'),
    },
    {
      id: uuid(),
      title: `P. Quarto ${Math.floor(Math.random() * Math.floor(total))}`,
      studies: [
        {
          title: `Study Mock ${Math.floor(Math.random() * Math.floor(total))}`,
          participantDone: 0,
          requiredParticipants: total,
          status: 'NEED_CONFIRMATION',
        },
        {
          title: `Study Mock ${Math.floor(Math.random() * Math.floor(total))}`,
          participantDone: 0,
          requiredParticipants: total,
          status: 'NEED_CONFIRMATION',
        },
      ],
      type: 'Forum Group Discussion',
      startDate: null,
      endDate: null,
    },
    {
      id: uuid(),
      title: `P. Quarto ${Math.floor(Math.random() * Math.floor(total))}`,
      studies: [
        {
          title: `Study Mock ${Math.floor(Math.random() * Math.floor(total))}`,
          participantDone: Math.floor(Math.random() * Math.floor(total)),
          requiredParticipants: total,
          status: 'STOP',
        },
        {
          title: `Study Mock ${Math.floor(Math.random() * Math.floor(total))}`,
          participantDone: Math.floor(Math.random() * Math.floor(total)),
          requiredParticipants: total,
          status: 'STOP',
        },
        {
          title: `Study Mock ${Math.floor(Math.random() * Math.floor(total))}`,
          participantDone: Math.floor(Math.random() * Math.floor(total)),
          requiredParticipants: total,
          status: 'STOP',
        },
      ],
      type: 'Online Survey',
      startDate: moment().subtract(7, 'day'),
      endDate: moment(),
    },
    {
      id: uuid(),
      title: `P. Quarto ${Math.floor(Math.random() * Math.floor(total))}`,
      studies: [
        {
          title: `Study Mock ${Math.floor(Math.random() * Math.floor(total))}`,
          participantDone: total,
          requiredParticipants: total,
          status: 'STOP',
        },
        {
          title: `Study Mock ${Math.floor(Math.random() * Math.floor(total))}`,
          participantDone: total - 100,
          requiredParticipants: total,
          status: 'ACTIVE',
        },
        {
          title: `Study Mock ${Math.floor(Math.random() * Math.floor(total))}`,
          participantDone: total - 100,
          requiredParticipants: total,
          status: 'ACTIVE',
        },
      ],
      type: 'Online Survey',
      startDate: moment(),
      endDate: moment().add(7, 'day'),
    },
    {
      id: uuid(),
      title: `P. Quarto ${Math.floor(Math.random() * Math.floor(total))}`,
      studies: [
        {
          title: `Study Mock ${Math.floor(Math.random() * Math.floor(total))}`,
          participantDone: 0,
          requiredParticipants: total,
          status: 'NEED_CONFIRMATION',
        },
        {
          title: `Study Mock ${Math.floor(Math.random() * Math.floor(total))}`,
          participantDone: 0,
          requiredParticipants: total,
          status: 'NEED_CONFIRMATION',
        },
      ],
      type: 'Forum Group Discussion',
      startDate: null,
      endDate: null,
    },
    {
      id: uuid(),
      title: `P. Quarto ${Math.floor(Math.random() * Math.floor(total))}`,
      studies: [
        {
          title: `Study Mock ${Math.floor(Math.random() * Math.floor(total))}`,
          participantDone: Math.floor(Math.random() * Math.floor(total)),
          requiredParticipants: total,
          status: 'STOP',
        },
        {
          title: `Study Mock ${Math.floor(Math.random() * Math.floor(total))}`,
          participantDone: Math.floor(Math.random() * Math.floor(total)),
          requiredParticipants: total,
          status: 'STOP',
        },
        {
          title: `Study Mock ${Math.floor(Math.random() * Math.floor(total))}`,
          participantDone: Math.floor(Math.random() * Math.floor(total)),
          requiredParticipants: total,
          status: 'STOP',
        },
      ],
      type: 'Online Survey',
      startDate: moment().subtract(7, 'day'),
      endDate: moment(),
    },
    {
      id: uuid(),
      title: `P. Quarto ${Math.floor(Math.random() * Math.floor(total))}`,
      studies: [
        {
          title: `Study Mock ${Math.floor(Math.random() * Math.floor(total))}`,
          participantDone: total,
          requiredParticipants: total,
          status: 'STOP',
        },
        {
          title: `Study Mock ${Math.floor(Math.random() * Math.floor(total))}`,
          participantDone: total - 100,
          requiredParticipants: total,
          status: 'ACTIVE',
        },
        {
          title: `Study Mock ${Math.floor(Math.random() * Math.floor(total))}`,
          participantDone: total - 100,
          requiredParticipants: total,
          status: 'ACTIVE',
        },
      ],
      type: 'Online Survey',
      startDate: moment(),
      endDate: moment().add(7, 'day'),
    },
    {
      id: uuid(),
      title: `P. Quarto ${Math.floor(Math.random() * Math.floor(total))}`,
      studies: [
        {
          title: `Study Mock ${Math.floor(Math.random() * Math.floor(total))}`,
          participantDone: 0,
          requiredParticipants: total,
          status: 'NEED_CONFIRMATION',
        },
        {
          title: `Study Mock ${Math.floor(Math.random() * Math.floor(total))}`,
          participantDone: 0,
          requiredParticipants: total,
          status: 'NEED_CONFIRMATION',
        },
      ],
      type: 'Forum Group Discussion',
      startDate: null,
      endDate: null,
    },
    {
      id: uuid(),
      title: `P. Quarto ${Math.floor(Math.random() * Math.floor(total))}`,
      studies: [
        {
          title: `Study Mock ${Math.floor(Math.random() * Math.floor(total))}`,
          participantDone: Math.floor(Math.random() * Math.floor(total)),
          requiredParticipants: total,
          status: 'STOP',
        },
        {
          title: `Study Mock ${Math.floor(Math.random() * Math.floor(total))}`,
          participantDone: Math.floor(Math.random() * Math.floor(total)),
          requiredParticipants: total,
          status: 'STOP',
        },
        {
          title: `Study Mock ${Math.floor(Math.random() * Math.floor(total))}`,
          participantDone: Math.floor(Math.random() * Math.floor(total)),
          requiredParticipants: total,
          status: 'STOP',
        },
      ],
      type: 'Online Survey',
      startDate: moment().subtract(7, 'day'),
      endDate: moment(),
    },
    {
      id: uuid(),
      title: `P. Quarto ${Math.floor(Math.random() * Math.floor(total))}`,
      studies: [
        {
          title: `Study Mock ${Math.floor(Math.random() * Math.floor(total))}`,
          participantDone: total,
          requiredParticipants: total,
          status: 'STOP',
        },
        {
          title: `Study Mock ${Math.floor(Math.random() * Math.floor(total))}`,
          participantDone: total - 100,
          requiredParticipants: total,
          status: 'ACTIVE',
        },
        {
          title: `Study Mock ${Math.floor(Math.random() * Math.floor(total))}`,
          participantDone: total - 100,
          requiredParticipants: total,
          status: 'ACTIVE',
        },
      ],
      type: 'Online Survey',
      startDate: moment(),
      endDate: moment().add(7, 'day'),
    },
    {
      id: uuid(),
      title: `P. Quarto ${Math.floor(Math.random() * Math.floor(total))}`,
      studies: [
        {
          title: `Study Mock ${Math.floor(Math.random() * Math.floor(total))}`,
          participantDone: 0,
          requiredParticipants: total,
          status: 'NEED_CONFIRMATION',
        },
        {
          title: `Study Mock ${Math.floor(Math.random() * Math.floor(total))}`,
          participantDone: 0,
          requiredParticipants: total,
          status: 'NEED_CONFIRMATION',
        },
      ],
      type: 'Forum Group Discussion',
      startDate: null,
      endDate: null,
    },
    {
      id: uuid(),
      title: `P. Quarto ${Math.floor(Math.random() * Math.floor(total))}`,
      studies: [
        {
          title: `Study Mock ${Math.floor(Math.random() * Math.floor(total))}`,
          participantDone: Math.floor(Math.random() * Math.floor(total)),
          requiredParticipants: total,
          status: 'STOP',
        },
        {
          title: `Study Mock ${Math.floor(Math.random() * Math.floor(total))}`,
          participantDone: Math.floor(Math.random() * Math.floor(total)),
          requiredParticipants: total,
          status: 'STOP',
        },
        {
          title: `Study Mock ${Math.floor(Math.random() * Math.floor(total))}`,
          participantDone: Math.floor(Math.random() * Math.floor(total)),
          requiredParticipants: total,
          status: 'STOP',
        },
      ],
      type: 'Online Survey',
      startDate: moment().subtract(7, 'day'),
      endDate: moment(),
    },
  ],
});

mock.onGet('/api/researchers/studyGroups/1').reply(200, {
  data: {
    id: 2,
    title: `P. Quarto ${Math.floor(Math.random() * Math.floor(total))}`,
    studies: [
      {
        title: `Study Mock ${Math.floor(Math.random() * Math.floor(total))}`,
        participantDone: Math.floor(Math.random() * Math.floor(total)),
        requiredParticipants: total,
        status: 'STOP',
      },
      {
        title: `Study Mock ${Math.floor(Math.random() * Math.floor(total))}`,
        participantDone: Math.floor(Math.random() * Math.floor(total)),
        requiredParticipants: total,
        status: 'STOP',
      },
      {
        title: `Study Mock ${Math.floor(Math.random() * Math.floor(total))}`,
        participantDone: Math.floor(Math.random() * Math.floor(total)),
        requiredParticipants: total,
        status: 'STOP',
      },
    ],
    type: 'ONLINE_SURVEY',
    startDate: moment().subtract(7, 'day'),
    endDate: moment(),
  },
});

mock.onGet('/api/researchers/studyGroups/2').reply(200, {
  code: 200,
  message: 'Success',
  data: {
    id: 2,
    title: 'Study Group DQ 1',
    description: 'Study Group DQ 1',
    ownerEmail: 'local-pop@getnada.com',
    status: 'IN_PROGRESS',
    startDate: '2020-04-01T15:45:58.000Z',
    endDate: '2020-04-05T15:46:18.000Z',
    type: 'DAILY_QUESTION',
    createdBy: 'local-pop@getnada.com',
    updatedBy: 'local-pop@getnada.com',
    excelReportState: 'UNDEFINED',
    excelReportName: null,
    tags: null,
    studyUrl: null,
    Studies: [
      {
        id: 16,
        title: 'Study Group DQ 1',
        description: 'Study Group DQ 1',
        studyUrl: null,
        researcherEmail: 'local-pop@getnada.com',
        maxAllowedTime: null,
        averageCompletionTime: null,
        participantReward: null,
        requiredParticipants: null,
        status: 'ACTIVE',
        criteria: null,
        criteriaQuota: null,
        createdBy: null,
        updatedBy: null,
        startDate: null,
        endDate: null,
        type: 'custom',
        screeningType: 'allUser',
        whitelistEmail: null,
        excelReportState: 'UNDEFINED',
        excelReportName: null,
        excelReportCreatedAt: null,
        code: null,
        deviceType: 'web,android,ios',
        guestStudyUrl: null,
        StudyGroupId: 2,
        createdAt: '2020-04-03T08:41:19.000Z',
        updatedAt: '2020-04-03T08:41:19.000Z',
        deletedAt: null,
      },
    ],
    StudyGroupCollaborators: [],
  },
});

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import LoginTop from 'src/assets/svg/loginTop.svg';
import LoginBottom from 'src/assets/svg/loginBottom.svg';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100vh',
    width: '100vw',
    backgroundImage: 'linear-gradient(180deg, #00417D 0%, #002B5A 100%)',
    display: 'flex',
    '@media all and (-ms-high-contrast:none)': {
      height: 0, // IE11 fix
    },
  },
  content: {
    flexGrow: 1,
    width: '100%',
    height: '100%',
    overflowX: 'hidden',
  },
  center: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    zIndex: 1001,
  },
  loginTop: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
    height: '100%',
    zIndex: 1000,
  },
  loginBottom: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    width: '100%',
    height: '100%',
    zIndex: 1000,
  },
  imageLoginTop: {
    width: '774px',
    height: '580px',
    transform: 'rotate(90deg)',
  },
  imageLoginBottom: {
    width: '720px',
    height: '540px',
    transform: 'matrix(-1, 0, 0, 1, 0, 0)',
  },
  imgWave: {
    [theme.breakpoints.down('xs')]: {
      width: '60%',
    },
  },
}));

function Auth({ children }) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.container}>
        <div className={classes.content}>
          <div className={classes.loginTop}>
            <img src={LoginTop} alt="error" className={classes.imgWave} />
          </div>
          <div className={classes.center}>{children}</div>
          <div className={classes.loginBottom}>
            <img src={LoginBottom} alt="error" className={classes.imgWave} />
          </div>
        </div>
      </div>
    </>
  );
}

Auth.propTypes = {
  children: PropTypes.node,
};

export default Auth;

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import PropTypes from 'prop-types';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import { setCriteriaGroups, checkUserLaunchedSurvey } from 'src/actions';
import NavBar from './NavBar';
import TopBar from './TopBar';
import clsx from 'clsx';
import { checkUserIsCorporate } from 'src/utils/helper';
import { activateRemoteClient } from 'src/actions/amRemoteAccessActions';

const lastedShowTopFixedStorageKey = 'lasted-show-top-fixed';
const storageLasteedShownTopFixed = {
  get: () => {
    try {
      const lastedShow = localStorage.getItem(lastedShowTopFixedStorageKey);
      const parsed = JSON.parse(lastedShow);
      const diffUpdatedAt = moment().diff(parsed.updatedAt, 'days');

      return {
        shown: parsed.shown,
        updatedAt: parsed.updatedAt,
        diffUpdatedAt,
      };
    } catch (error) {
      return {};
    }
  },

  set: ({ shown = false, updatedAt }) => {
    localStorage.setItem(
      lastedShowTopFixedStorageKey,
      JSON.stringify({
        shown,
        updatedAt: updatedAt ?? moment().format('YYYY-MM-DD'),
      }),
    );
  },

  del: () => localStorage.removeItem(lastedShowTopFixedStorageKey),
};

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '100vh',
    display: 'flex',
    '@media all and (-ms-high-contrast:none)': {
      height: 0, // IE11 fix
    },
  },
  drawerOpen: {
    transition: theme.transitions.create('padding', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('padding', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  content: {
    paddingTop: 72,
    flexGrow: 1,
    maxWidth: '100%',
    backgroundColor: '#F2F9FE',
    [theme.breakpoints.between('lg', theme.breakpoints.hlg)]: {
      paddingLeft: ({ isPopliteDrawerOpen }) =>
        !isPopliteDrawerOpen ? 120 : 220,
    },
    [theme.breakpoints.up(theme.breakpoints.hlg)]: {
      paddingLeft: ({ isPopliteDrawerOpen }) =>
        !isPopliteDrawerOpen ? 120 : 275,
    },
  },
}));

function Dashboard({ children }) {
  const isPopliteDrawerOpen = useSelector(
    (state) => state.popliteDrawer.isPopliteDrawerOpen,
  );
  const isPopliteFeature = useSelector(
    (state) => state.popliteDrawer.isPopliteFeature,
  );

  const classes = useStyles({ isPopliteDrawerOpen });
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);
  const criteriaGroupsReducer = useSelector((state) => state.criteriaGroups);
  const user = useSelector((state) => state.session?.user);
  const roles = (user && user.roles) || [];
  // since the poplite feature doesn't use the RESEARCH role, so just comment on it
  // Remove researcher role to only show specific poplite menu
  // if (roles.indexOf(POPLITE) >= 0 && roles.indexOf(RESEARCHER) >= 0) {
  //   roles.splice(roles.indexOf(RESEARCHER));
  // }
  const dispatch = useDispatch();
  const { search: queryString } = useLocation();

  const hasLaunchedSurvey = user?.hasLaunchedSurvey;
  const [showTopFixed, setShowTopFixed] = useState(false);

  const isAccountManager = user?.isAccountManager;
  useEffect(() => {
    const b64UserEmail = new URLSearchParams(queryString).get('access');

    if (isAccountManager && b64UserEmail) {
      dispatch(activateRemoteClient(b64UserEmail));
    }
  }, [queryString, isAccountManager]);

  useEffect(() => {
    if (isPopliteFeature) {
      dispatch(checkUserLaunchedSurvey());
    }
  }, [isPopliteFeature]);

  useEffect(() => {
    if (!criteriaGroupsReducer) {
      dispatch(setCriteriaGroups());
    }
  }, [criteriaGroupsReducer]);

  useEffect(() => {
    if (
      isPopliteFeature &&
      !hasLaunchedSurvey &&
      checkUserIsCorporate(user?.email)
    ) {
      const { diffUpdatedAt, shown = true } = storageLasteedShownTopFixed.get();
      const isNextDay = diffUpdatedAt >= 1;
      const shouldShown = isNextDay ? true : shown;

      if (shouldShown) {
        setShowTopFixed(true);
      }

      if (isNextDay) {
        storageLasteedShownTopFixed.set({ shown: true });
      }
    }
  }, [isPopliteFeature, hasLaunchedSurvey]);

  useEffect(() => {
    if (hasLaunchedSurvey) {
      storageLasteedShownTopFixed.del();
      setShowTopFixed(false);
    }
  }, [hasLaunchedSurvey]);

  function handleCloseTopFixed() {
    storageLasteedShownTopFixed.set({ shown: false });
    setShowTopFixed(false);
  }

  const isShowTopFixed = isAccountManager ? false : showTopFixed;

  return (
    <div
      style={{
        '--drawer-top-height': !isShowTopFixed ? '72px' : '121px', // 72px (topbar) + 49px (navbar)
      }}
    >
      <TopBar
        onOpenNavBarMobile={() => setOpenNavBarMobile(true)}
        roles={roles}
        onCloseTopFixed={handleCloseTopFixed}
        isShowTopFixed={isShowTopFixed}
      />
      <NavBar
        onMobileClose={() => setOpenNavBarMobile(false)}
        openMobile={openNavBarMobile}
        roles={roles}
      />
      <div className={classes.container}>
        <div
          className={clsx(classes.content, {
            [classes.drawerOpen]: isPopliteDrawerOpen,
            [classes.drawerClose]: !isPopliteDrawerOpen,
          })}
        >
          {children}
        </div>
      </div>
    </div>
  );
}

Dashboard.propTypes = {
  children: PropTypes.node,
};

export default Dashboard;

import { SET_ELIGIBLE_PARTICIPANT } from 'src/actions/eligibleParticipantAction';

const initialState = {
  totalParticipant: 0,
};

const eligibleParticipantReducer = (state = initialState, actions) => {
  const { type, payload } = actions;

  switch (type) {
    case SET_ELIGIBLE_PARTICIPANT:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export default eligibleParticipantReducer;

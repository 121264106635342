import React from 'react';
import { Helmet } from 'react-helmet';

const PROJECT_ID = process.env.REACT_APP_MOUSEFLOW;

function MouseFlow() {
  return (
    <Helmet>
      <script async />
      <script>
        {`window._mfq = window._mfq || [];
        (function() {
          var mf = document.createElement("script");
          mf.type = "text/javascript"; mf.defer = true;
          mf.src = "//cdn.mouseflow.com/projects/${PROJECT_ID}.js";
          document.getElementsByTagName("head")[0].appendChild(mf);
        })();`}
      </script>
    </Helmet>
  );
}

export default MouseFlow;

import React from 'react';
import clsx from 'clsx';
import ReactGA from 'react-ga';
import { NavLink as RouterLink } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.icon,
    display: 'flex',
    alignItems: 'center',
    marginRight: 0,
  },
  activeItem: {
    color: '#333333',
    background: '#F2F2F2',
    fontWeight: 600,
  },
  activeItemCxTools: {
    '& .MuiListItemText-primary': {
      fontWeight: 600,
    },
  },
  miniLabel: {
    '&&': {
      display: 'none !important',
      justifyContent: 'flex-start',
    },
  },
  miniButton: {
    padding: theme.spacing(0, 2),
    borderRadius: 16,
    minHeight: 48,
    '& .MuiListItemText-primary': {
      color: theme.palette.textGrey.main,
    },
  },
  noMinWidth: {
    '&&': {
      minWidth: 'unset',
    },
  },
  itemText: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    textTransform: 'capitalize',
    marginLeft: 11,
  },
  tooltip: {
    backgroundColor: '#333333',
    boxShadow: '2px 4px 10px rgba(0, 0, 0, 0.1)',
    fontSize: 12,
    borderRadius: 50,
    padding: '4px 12px',
    left: '-8px !important',
  },
}));

const PopliteNavItem = ({ isDrawerOpen, title, href, icon, isCxTools }) => {
  const classes = useStyles();
  const onPaymentPage = href === '/poplite/payments/browse';
  const isInfoNav = href === 'https://info.populix.co/poplite';

  const handleClickItem = () => {
    if (!onPaymentPage) {
      return;
    }

    ReactGA.event({
      category: 'Payment Poplite',
      action: 'dashboard_saldo_transaction',
    });
  };

  return (
    <ListItem disableGutters>
      <Tooltip
        classes={{
          tooltip: classes.tooltip,
        }}
        disableHoverListener={isDrawerOpen}
        title={title}
        placement="right"
      >
        <Button
          exact
          fullWidth
          onClick={handleClickItem}
          className={classes.miniButton}
          activeClassName={clsx(classes.activeItem, { [classes.activeItemCxTools]: isCxTools })}
          classes={{
            root: clsx({ [classes.noMinWidth]: !isDrawerOpen }),
          }}
          {...(isInfoNav && { href, target: '_blank' })}
          {...(!isInfoNav && { to: href, component: RouterLink })}
        >
          <ListItemIcon
            classes={{
              root: clsx({ [classes.noMinWidth]: !isDrawerOpen }),
            }}
          >
            <img src={icon} alt={title} className={classes.icon} />
          </ListItemIcon>

          <ListItemText
            primary={title}
            className={clsx({
              [classes.miniLabel]: !isDrawerOpen,
            })}
            style={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: 'auto',
              textTransform: 'capitalize',
              marginLeft: 11,
            }}
          />
        </Button>
      </Tooltip>
    </ListItem>
  );
};

export default PopliteNavItem;

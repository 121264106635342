import React from 'react';

import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import PopButton from 'src/components/PopButton';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles((theme) => ({
  modal: {
    width: '60%',
    borderRadius: '24px',
    padding: '24px',
  },
  title: {
    padding: 0,
    borderBottom: 0,
  },
  titleText: {
    fontSize: '20px',
    lineHeight: '24px',
    fontWeight: 600,
    color: '#333333',
  },
  subTitleText: {
    fontSize: '12px',
    lineHeight: '24px',
    fontWeight: 300,
    color: '#4F4F4F',
  },
  titleQuestion: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 600,
    color: '#4F4F4F',
  },
  dialogHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  formSpacing: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  chipSelected: {
    margin: 3,
    borderColor: '#00417D',
    fontSize: '14px',
  },
  chipDefault: {
    margin: 3,
    fontSize: '14px',
  },
}));

const UserQuestionaireDialog = ({
  isShow,
  dataQuestionaire,
  handleChangeSelect,
  handleChangeSelectChip,
  formik,
}) => {
  const classes = useStyles();
  return (
    <>
      <Dialog
        maxWidth="60%"
        open={isShow}
        classes={{
          paperScrollPaper: classes.modal,
        }}
      >
        <DialogTitle className={classes.title}>
          <div className={classes.dialogHeader}>
            <Typography component="div" className={classes.titleText}>
              Halo, jawab survei kami dulu yuk!
            </Typography>
          </div>
          <Typography component="div" className={classes.subTitleText}>
            Jawabanmu akan sangat membantu kami mengembangkan Poplite sesuai
            kebutuhanmu.
          </Typography>
        </DialogTitle>
        <Divider className={classes.divider} />
        <form onSubmit={formik.handleSubmit}>
          {dataQuestionaire &&
            dataQuestionaire.map((value, index) => {
              if (value.type === 'SELECT') {
                return (
                  <div key={index}>
                    <Typography className={classes.titleQuestion}>
                      {value.name}
                    </Typography>
                    <FormControl
                      variant="outlined"
                      className={classes.formSpacing}
                      fullWidth
                      error={
                        !!(
                          formik?.errors?.selectedQuestionaire &&
                          formik?.touched?.selectedQuestionaire
                        )
                      }
                    >
                      <InputLabel>
                        {index === 0
                          ? 'Pilih pekerjaan kamu saat ini'
                          : 'Pilih Sumber'}
                      </InputLabel>
                      <Select
                        onChange={(e) => handleChangeSelect(e, value.id, index)}
                        fullWidth
                        variant="outlined"
                        label={
                          index === 0
                            ? 'Pilih pekerjaan kamu saat ini'
                            : 'Pilih Sumber'
                        }
                      >
                        {value.answer &&
                          value.answer.map((answer, index) => (
                            <MenuItem
                              key={index}
                              value={answer}
                              style={{ whiteSpace: 'normal' }}
                            >
                              {answer}
                            </MenuItem>
                          ))}
                      </Select>
                      {formik?.errors?.selectedQuestionaire &&
                      formik?.touched?.selectedQuestionaire ? (
                        <FormHelperText error>
                          {formik.errors.selectedQuestionaire?.[index]?.answer}
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                  </div>
                );
              } else {
                return (
                  <>
                    <Typography
                      className={classes.titleQuestion}
                      style={{ marginBottom: '10px' }}
                    >
                      {value.name}
                    </Typography>
                    <Box>
                      {value.answer.map((answer, index) => (
                        <Chip
                          key={index}
                          label={answer}
                          clickable
                          variant="outlined"
                          className={
                            formik?.values?.selectedInterest.includes(answer)
                              ? classes.chipSelected
                              : classes.chipDefault
                          }
                          deleteIcon={
                            formik.values.selectedInterest.includes(answer) ? (
                              <CheckCircleIcon style={{ color: '#00417D' }} />
                            ) : null
                          }
                          onDelete={
                            formik.values.selectedInterest.includes(answer)
                              ? (e) => handleChangeSelectChip(e, answer)
                              : null
                          }
                          onClick={(e) =>
                            handleChangeSelectChip(e, answer, value.id)
                          }
                        />
                      ))}
                      {formik?.errors?.selectedInterest && (
                        <FormHelperText error>
                          {formik.errors.selectedInterest}
                        </FormHelperText>
                      )}
                    </Box>
                  </>
                );
              }
            })}
        </form>
        <Box
          mt="10px"
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          <PopButton
            style={{ width: 130 }}
            onClick={formik.handleSubmit}
            color="success"
            type="submit"
            variant="contained"
          >
            {'Simpan'}
          </PopButton>
        </Box>
      </Dialog>
    </>
  );
};

export default UserQuestionaireDialog;

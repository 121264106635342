import { node } from 'prop-types';
import React, { createContext, useCallback, useMemo, useState } from 'react';
import Dialog from './Dialog';

const defaultState = {
  open: false,
  title: '',
  content: '',
  variant: 'constructive',
  confirmText: '',
  cancelText: '',
  onConfirm: null,
  onCancel: null,
  isLoading: false,
  fullWidthBtn: false,
};

export const ConfirmationDialogContext = createContext(defaultState);

const ConfirmationDialogProvider = ({ children }) => {
  const [state, setState] = useState(defaultState);

  const close = useCallback(() => {
    setState((prev) => ({ ...prev, open: false }));
  }, [state]);

  const values = useMemo(() => ({ state, close, setState }), [
    state,
    close,
    setState,
  ]);

  return (
    <ConfirmationDialogContext.Provider value={values}>
      {children}
      <Dialog {...state} onClose={close} />
    </ConfirmationDialogContext.Provider>
  );
};

ConfirmationDialogProvider.propTypes = {
  children: node,
};

export default ConfirmationDialogProvider;

import Auth from '@aws-amplify/auth';
import {
  POPLITE,
  RESEARCHER,
  PARTICIPANT,
  ACCOUNT_MANAGER,
  ADMIN,
  DOSEN_BINUS,
  POPLITE_PRO,
  POPLITE_CX_CLIENT,
  ENTERPRISE_CLIENT,
  FINANCE,
} from 'src/utils/variables';
import { Mixpanel } from 'src/components/Mixpanel';
import PopCall from '../utils/popCall';
import reduxStore from '../store';

export const SESSION_LOGIN = 'SESSION_LOGIN';
export const SESSION_LOGIN_SUCCESS = 'SESSION_LOGIN_SUCCESS';
export const SESSION_LOGIN_FAILED = 'SESSION_LOGIN_FAILED';
export const SESSION_LOGIN_INIT = 'SESSION_LOGIN_INIT';
export const SESSION_LOGOUT = 'SESSION_LOGOUT';
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const CHECK_USER_LAUNCHED_SURVEY = 'CHECK_USER_LAUNCHED_SURVEY';
export const SET_AS_POPLITE_PRO = 'SET_AS_POPLITE_PRO';

/**
 * Login function
 * @param {string} username
 * @param {string} password
 */
export const login = (username, password, isSosmed) => async (dispatch) => {
  dispatch({
    type: SESSION_LOGIN,
  });

  // Best practice
  const tryLogin = async (retry = 0) => {
    try {
      // sign in on AWS Cognito
      if (!isSosmed) {
        await Auth.signIn(username, password);
      }

      const popCall = new PopCall();

      // get profile from backend
      const { data } = await popCall.get('/profile');

      if (
        !data.groups.includes('researcher') &&
        !data.groups.includes('admin') &&
        !data.groups.includes('retailAuditQC') &&
        !data.groups.includes('guest') &&
        !data.groups.includes('participant') &&
        !data.groups.includes('dosenBinus') &&
        !data.groups.includes('poplitePro') &&
        !data.groups.includes('enterpriseClient')
      ) {
        const errorException = { code: 'ParticipantNotAuthorizedException' };
        throw errorException;
      } else {
        let dataGroups = data?.groups;

        // Check if loggedin user only have participant group, then update group data
        if (
          data?.groups?.includes('participant') ||
          data?.groups?.includes('popliteClient')
        ) {
          dataGroups = [
            ...new Set([...data.groups, ...[POPLITE, RESEARCHER, PARTICIPANT]]),
          ];

          // set priority to admin first
          if (dataGroups.indexOf(ADMIN) > -1) {
            dataGroups = dataGroups.filter((item) => item === ADMIN);
          }

          // set priority to enterpriseClient
          if (dataGroups.indexOf(ENTERPRISE_CLIENT) > -1) {
            dataGroups = dataGroups.filter(
              (item) => item === ENTERPRISE_CLIENT,
            );
          }

          await popCall.put('/participant/group', {
            groups: [...new Set([...data.groups, ...dataGroups])],
          });
        }

        let hasLaunchedSurvey = true;

        try {
          const checkLaunchedSurvey = await popCall.get(
            '/popliteDemo/checkLaunchedSurvey',
          );
          hasLaunchedSurvey = checkLaunchedSurvey.data.alreadyLaunchedSurvey;
        } catch {
          // continue to next login when redirect login from register
        }

        const payload = {
          id: data?.id,
          email: data?.email,
          firstName: data?.name,
          phoneNumber: data?.phone_number,
          lastName: data && data.family_name,
          companyName: data && data.company_name,
          roles: dataGroups,
          isAdmin: dataGroups.includes(ADMIN),
          isEnterpriseClient: dataGroups.includes(ENTERPRISE_CLIENT),
          isPoplite: dataGroups.includes(POPLITE),
          isDosenBinus: dataGroups.includes(DOSEN_BINUS),
          isPoplitePro: dataGroups.includes(POPLITE_PRO),
          isPopliteCxTools: dataGroups.includes(POPLITE_CX_CLIENT),
          isAccountManager: dataGroups.includes(ACCOUNT_MANAGER),
          isFinance: data?.groups?.includes(FINANCE),
          picture: data?.picture,
          popliteReferralCode: data?.popliteReferralCode,
          hasLaunchedSurvey,
        };

        Mixpanel.identify(data?.email);
        Mixpanel.track('Login Successful', {
          email: data?.email,
          // eslint-disable-next-line @typescript-eslint/camelcase
          signin_date: new Date(),
        });
        Mixpanel.people.set({
          $email: data?.email,
        });

        try {
          const result = await popCall.get('/notifications/participant');

          if (result) {
            dispatch({
              type: GET_NOTIFICATIONS,
              payload: { ...result?.data, isLoadMore: true },
            });
          }
        } catch {
          // continue to next login when redirect login from register
        }

        dispatch({
          type: SESSION_LOGIN_SUCCESS,
          payload,
        });
      }
    } catch (error) {
      if (error.code === 'UserNotFoundException') {
        if (retry < 5) {
          await tryLogin(retry + 1);
        } else {
          const errorData = {
            errorCode: error.code,
            errorMessage: 'User not found',
            // We have tried logging in ${retry} times.
            // but still got ${error.code} error from Cognito.
          };

          dispatch({
            type: SESSION_LOGIN_FAILED,
            payload: errorData,
          });
        }
      } else {
        const errorData = {
          errorCode: error.code,
          errorMessage: error.message,
        };

        if (error.code === 'ParticipantNotAuthorizedException') {
          errorData.errorCode = '';
          errorData.errorMessage =
            'This account is not registered in Researcher app. Please use another account / email.';
        }

        dispatch({
          type: SESSION_LOGIN_FAILED,
          payload: errorData,
        });
      }
    }
  };
  await tryLogin();
};

export const logout = () => async (dispatch) => {
  await Auth.signOut();
  reduxStore.persistor.purge();
  localStorage.clear();
  dispatch({
    type: SESSION_LOGOUT,
  });
};

/**
 * Get realtime data notification on every dispatch triggered
 * @param {*} payload
 * @returns
 */
export const getNotifications = (payload) => (dispatch) => {
  dispatch({ type: GET_NOTIFICATIONS, payload });
};

/**
 * Bypass poplite pro feature for account manager purpose
 * @param {boolean} payload
 */
export const setAsPoplitePro = (payload) => (dispatch) => {
  dispatch({ type: SET_AS_POPLITE_PRO, payload });
};

export const checkUserLaunchedSurvey = () => async (dispatch) => {
  try {
    const popCall = new PopCall();

    const checkLaunchedSurvey = await popCall.get(
      '/popliteDemo/checkLaunchedSurvey',
    );

    dispatch({
      type: CHECK_USER_LAUNCHED_SURVEY,
      payload: checkLaunchedSurvey.data.alreadyLaunchedSurvey,
    });
  } catch {
    // dont do anything
  }
};

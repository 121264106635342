import mock from 'src/utils/mock';

mock.onGet('/projects/1').reply(200, {
  code: 200,
  message: 'Success',
  data: {
    title: 'Brand Health Index',
    objective: 'Demographic User and Customer Behavior',
    type: 'POPLITE_QUANTITATIVE',
    totalStudy: 3,
    clientName: 'Hansen Ja',
    clientEmail: 'heyhansen@populix.co',
    companyName: 'Populix',
    isInternalProject: 1,
  },
});

mock.onGet('/projects/2').reply(200, {
  message: 'Success',
  code: 200,
  data: {
    title: 'Brand Health Index 2',
    objective: 'Demographic User and Customer Behavior 2',
    type: 'POPLITE_QUANTITATIVE',
    totalStudy: 3,
    clientName: 'Hansen Ja',
    clientEmail: 'hirzanalhakim@populix.co',
    companyName: 'Populix',
    isInternalProject: 0,
  },
});

mock.onGet('/projects/3').reply(200, {
  code: 200,
  message: 'Success',
  data: {
    title: 'Brand Health Index 3',
    objective: 'Demographic User and Customer Behavior 3',
    type: 'POPLITE_QUANTITATIVE',
    totalStudy: 3,
    clientName: 'Hirzan Jundi Al Hakim',
    clientEmail: 'hirzan@populix.co',
    companyName: 'Populix',
    isInternalProject: 1,
  },
});

import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    borderRadius: theme.spacing(3),
    padding: '8px 0',
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '8px 24px',
  },
  dialogContent: {
    minHeight: 72,
  },
  button: {
    minWidth: 160,
  },
  content: {
    fontWeight: 300,
  },
}));

export default useStyles;

// #region Brand
export const brandPrimary = '#EE7824';
export const brandPrimaryLight = '#FFB808';
export const brandSecondary = '#662E94';
export const brandSecondaryLight = '#7635AC';
export const brandTertiary = '#00417D';
export const brandTertiaryLight = '#329BFF';
// #endregion

// #region UI
export const textPrimary = '#333333';
export const textSecondary = '#4F4F4F';
export const textTertiary = '#AAAAAA';
export const textSuccess = '#2DBF9C';
export const textError = '#E33546';
export const textWhite = '#FFFFFF';
export const textTextLink = '#FFB808';
export const textGrey = '#828282';
export const mainGrey = '#E0E0E0';
export const darkGrey = '#BDBDBD';
export const lightGrey = '#F2F2F2';
export const green = '#4CAF50';
export const red = '#DB3737';
export const steelBlueLight = '#78909C';
// #endregion

export const grey100 = '#EFF1F1';
export const white = '#FFFFFF';

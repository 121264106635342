import { popCall } from 'src/utils/popCall';
import { showSnackbar } from './snackbarActions';

export const SET_ELIGIBLE_PARTICIPANT = 'SET_ELIGIBLE_PARTICIPANT';

const getEligibleParticipant = async (payload) => {
  const response = await popCall.post(
    '/researchers/totalEligibleParticipant',
    payload,
  );

  return response.data.totalParticipant;
};

export const setEligibleParticipant = (payload) => async (dispatch) => {
  try {
    const totalParticipant = await getEligibleParticipant(payload);
    return dispatch({
      type: SET_ELIGIBLE_PARTICIPANT,
      payload: { totalParticipant },
    });
  } catch (error) {
    return dispatch(
      showSnackbar(
        'Something went wrong when trying to get an eligible participant.',
        'error',
      ),
    );
  }
};

/* eslint-disable react/prop-types */
/* eslint-disable function-paren-newline */
/* eslint-disable react/no-multi-comp */
import React, { useEffect } from 'react';
import { useLocation, matchPath, useHistory } from 'react-router';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/styles';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import { colors, useMediaQuery, useTheme } from '@material-ui/core';

import HelpIcon from '@material-ui/icons/HelpOutline';
import AddIcon from '@material-ui/icons/AddCircleOutline';

import PopulixLogoutIcon from 'src/assets/svg/logout.svg';
import WebsiteIcon from 'src/assets/svg/websiteMenu.svg';
import NavItem from 'src/components/NavItem';
import PopButton from 'src/components/PopButton';
import PopliteSeeResultCard from 'src/components/PopliteSeeResultCard';

import { POPLITE, productHandbookURL } from 'src/utils/variables';
import { Mixpanel } from 'src/components/Mixpanel';
import { logout, resetCreditWalletInfo, resetWalletInfo } from 'src/actions';
import { lighten } from '@material-ui/core/styles';
import BalanceCard from './BalanceCard';
import GuestCreateProjectCard from './GuestCreateProjectCard';
import PopliteDrawer from './PopliteDrawer';
import CxToolsDrawer from './CxToolsDrawer';
import useGetNavConfig from './useGetNavConfig';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 72,
    height: 'calc(100% - 72px)',
    boxShadow: '2px 0px 10px rgba(0, 0, 0, 0.1)',
    [theme.breakpoints.down(theme.breakpoints.hlg)]: {
      width: 220,
    },
  },
  navigation: {
    overflow: 'auto',
    padding: theme.spacing(0, 2, 2, 2),
    flexGrow: 1,
  },
  profile: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  badge: {
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  },
  badgeDot: {
    height: 9,
    minWidth: 9,
  },
  onlineBadge: {
    backgroundColor: colors.green[600],
  },
  awayBadge: {
    backgroundColor: colors.orange[600],
  },
  busyBadge: {
    backgroundColor: colors.red[600],
  },
  offlineBadge: {
    backgroundColor: '#F2F9FE',
  },
  avatar: {
    cursor: 'pointer',
    width: 40,
    height: 40,
  },
  details: {
    marginLeft: theme.spacing(2),
  },
  moreButton: {
    marginLeft: 'auto',
    color: colors.blueGrey[200],
  },
  helpButton: {
    textTransform: 'capitalize',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.icon,
    margin: `0px 8px`,
  },
  buttonWebsiteMenu: {
    padding: '0px 8px',
    fontWeight: 300,
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    height: 43,
    borderRadius: 16,
    [theme.breakpoints.between('lg', theme.breakpoints.hlg)]: {
      fontSize: '12px',
    },
  },
  divider: {
    backgroundColor: '#F2F2F2',
    margin: theme.spacing(2.5, 0, 1.5),
  },
  createSurvey: {
    margin: theme.spacing(2.5, 0, 0, 0),
    textTransform: 'capitalize',
  },
  logoutButton: {
    color: 'white',
    bottom: 20,
    left: '5%',
    position: 'absolute',
    width: '90%',
    textTransform: 'capitalize',
    backgroundColor: '#002B5A',
    border: '1px solid #002B5A',
    '&:hover': {
      border: '1px solid #ffffff',
      backgroundColor: lighten('#002B5A', 0.1),
    },
    '& .MuiButton-startIcon': {
      marginLeft: 0,
      marginRight: 5,
    },
  },
  logoutIcon: {
    marginRight: theme.spacing(1),
  },
}));

function convertTitleWithoutSpace(name) {
  let newName = name;

  while (newName && newName.includes(' ')) {
    newName = newName.replace(' ', '');
  }

  return newName;
}

function renderNavItems({
  items,
  parent,
  subheader,
  key,
  isPoplite,
  isCxToolsFeature,
  ...rest
}) {
  return (
    <List key={key}>
      {subheader && (
        <ListSubheader disableSticky>
          <Typography
            variant="h5"
            style={{
              padding: '16px 0px',
              ...(isCxToolsFeature && {
                color: '#333333',
                fontSize: 12,
                borderBottom: '1px solid #E0E0E0',
                marginBottom: 16,
              }),
            }}
          >
            {subheader}
          </Typography>
        </ListSubheader>
      )}
      {/* eslint-disable-next-line react/prop-types */}
      {items.reduce(
        // eslint-disable-next-line no-use-before-define
        (acc, item) =>
          reduceChildRoutes({
            acc,
            parent,
            item,
            isPoplite,
            isCxToolsFeature,
            ...rest,
          }),
        [],
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  parent,
  pathname,
  item,
  depth = 0,
  isPoplite,
  isCxToolsFeature,
}) {
  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        id={`parent_${convertTitleWithoutSpace(item.title)}_menu`}
        depth={depth}
        icon={item.icon}
        key={item.href}
        label={item.label}
        open={Boolean(open)}
        title={item.title}
        isPoplite={isPoplite}
      >
        {renderNavItems({
          parent: item.title,
          depth: depth + 1,
          pathname,
          items: item.items,
          isPoplite,
          isCxToolsFeature,
        })}
      </NavItem>,
    );
  } else {
    acc.push(
      <NavItem
        id={`${parent || 'parent'}_${convertTitleWithoutSpace(
          item.title,
        )}_menu`}
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={item.href}
        label={item.label}
        title={item.title}
        isPoplite={isPoplite}
      />,
    );
  }

  return acc;
}

function NavBar({ openMobile, onMobileClose, className, ...rest }) {
  const { roles, nonAuth } = rest;
  const { getNavConfig } = useGetNavConfig();

  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const isRemote = useSelector((state) => state.amRemoteAccess.isRemoting);
  const isPoplite = isRemote ? true : roles.indexOf(POPLITE) >= 0;
  const session = useSelector((state) => state.session);
  const popliteDrawer = useSelector((state) => state.popliteDrawer);
  const dispatch = useDispatch();
  const bypassAuth = location.pathname.includes('example');
  const { loggedIn } = session;
  const {
    isPopliteDrawerOpen,
    isCxToolsFeature,
    isPopliteFeature,
  } = popliteDrawer;
  const isPopliteFeatureSelected = isPoplite && isPopliteFeature;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const isMobileSignOut = useMediaQuery(theme.breakpoints.down('md'));

  const user = useSelector((state) => state?.session?.user) || {};
  const featureFlag = useSelector((state) => state.featureFlag);

  const navConfig = getNavConfig(
    roles,
    isCxToolsFeature,
    featureFlag?.disableProjectPage ?? true,
    featureFlag?.disableStudyPage ?? true,
    isRemote,
  );

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }

    // eslint-disable-next-line
  }, [location.pathname]);

  const handleOnCreateSurvey = () => {
    Mixpanel.identify(user?.email);
    Mixpanel.track('New Survey', {
      email: user?.email,
    });
    Mixpanel.people.set({
      $email: user?.email,
    });
    history.push('/poplite/projects/browse');
  };

  const handleLogout = async () => {
    dispatch(logout());
    dispatch(resetWalletInfo());
    dispatch(resetCreditWalletInfo());

    if (bypassAuth) {
      history.push('/auth/login-poplite');
    }
  };

  const content = (
    <div {...rest} className={clsx(classes.root, className)}>
      <nav className={classes.navigation}>
        {isPopliteFeatureSelected && (
          <>
            <BalanceCard isPopliteDrawerOpen={isPopliteDrawerOpen} />
            <PopButton
              fullWidth
              color="primary"
              variant="contained"
              startIcon={<AddIcon />}
              onClick={handleOnCreateSurvey}
              className={classes.createSurvey}
            >
              Buat Survei Baru
            </PopButton>
            <Divider className={classes.divider} />
          </>
        )}

        {navConfig.map((list) =>
          renderNavItems({
            items: list.items,
            subheader: list.subheader,
            pathname: location.pathname,
            key: list.subheader,
            isPoplite,
            isCxToolsFeature,
          }),
        )}

        {(user?.isAdmin || user?.isEnterpriseClient) && (
          <PopButton
            fullWidth
            target="__blank"
            color="warning"
            variant="outlined"
            endIcon={<HelpIcon />}
            className={classes.helpButton}
            href={productHandbookURL}
          >
            Help
          </PopButton>
        )}

        {isPopliteFeatureSelected && (
          <Button
            id="btn-website-menu"
            className={classes.buttonWebsiteMenu}
            href="https://info.populix.co/poplite"
            target="_blank"
            startIcon={
              <img src={WebsiteIcon} alt="menu" className={classes.icon} />
            }
          >
            Info Poplite
          </Button>
        )}

        {!nonAuth && loggedIn && isMobileSignOut && (
          <Button
            id="logout_button"
            color="inherit"
            onClick={handleLogout}
            className={classes.logoutButton}
            variant="contained"
            startIcon={
              <img
                src={PopulixLogoutIcon}
                alt="logout"
                className={classes.logoutIcon}
              />
            }
          >
            Sign Out
          </Button>
        )}

        {isPopliteFeatureSelected && !isMobile && (
          <>
            <Divider className={classes.divider} />
            <PopliteSeeResultCard />
          </>
        )}

        <GuestCreateProjectCard userRoles={user?.roles} />
      </nav>

      {/* Hidden Profile temporary
      <Divider className={classes.divider} />
      {user && user.isAdmin && (
        <div className={classes.profile}>
          <Badge
            overlap="circle"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            classes={{
              dot: classes.badgeDot,
              badge: clsx({
                [classes.badge]: true,
                [classes.onlineBadge]: status === 'online',
                [classes.awayBadge]: status === 'away',
                [classes.busyBadge]: status === 'busy',
                [classes.offlineBadge]: status === 'offline',
              }),
            }}
            variant="dot"
          >
            <Avatar
              alt="Person"
              onClick={handleStatusToggle}
              className={classes.avatar}
              src="/images/avatars/avatar_2.png"
            />
          </Badge>
          <div className={classes.details}>
            <Link
              component={RouterLink}
              to="/profile/1/timeline"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {(firstName && `${firstName} ${lastName}`) || ''}
            </Link>
          </div>
          <IconButton className={classes.moreButton} size="small">
            <MoreIcon />
          </IconButton>
        </div>
      )} */}
    </div>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{
            paper: classes.mobileDrawer,
          }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        {isPoplite && isPopliteFeature && (
          <PopliteDrawer navs={navConfig?.[0]?.items} />
        )}
        {isPoplite && isCxToolsFeature && (
          <CxToolsDrawer navs={navConfig?.[0]?.items} />
        )}
        {!isPoplite && (
          <Drawer
            anchor="left"
            classes={{
              paper: classes.desktopDrawer,
            }}
            open
            variant="persistent"
          >
            {content}
          </Drawer>
        )}
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;

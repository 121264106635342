import moment from 'moment';
import mock from 'src/utils/mock';

mock.onGet('/researchers/studyGroups/1').reply(200, {
  code: 200,
  message: 'Success',
  data: {
    id: 2,
    title: 'Study Group  1',
    description: 'Study Group  1',
    ownerEmail: 'admin@populix.co',
    status: 'ACTIVE',
    startDate: moment().subtract(7, 'day'),
    endDate: moment().add(7, 'day'),
    type: 'ONLINE_SURVEY',
    createdBy: null,
    updatedBy: null,
    excelReportState: 'UNDEFINED',
    excelReportName: null,
    tags: null,
    studyUrl: '',
    deviceType: 'Web, Android, Ios',
    totalParticipant: 0,
    Studies: [
      {
        id: 208,
        title: 'Study Group  1',
        description: 'Study Group  1',
        studyUrl: null,
        researcherEmail: 'admin@populix.co',
        maxAllowedTime: null,
        averageCompletionTime: null,
        participantReward: null,
        requiredParticipants: null,
        status: 'NEED_CONFIRMATION',
        criteria: null,
        criteriaQuota: null,
        createdBy: null,
        updatedBy: null,
        startDate: null,
        endDate: null,
        type: 'custom',
        screeningType: 'allUser',
        whitelistEmail: null,
        excelReportState: 'UNDEFINED',
        excelReportName: null,
        excelReportCreatedAt: null,
        code: null,
        deviceType: 'web,android,ios',
        guestStudyUrl: null,
        StudyGroupId: 2,
        createdAt: '2020-04-01T13:32:47.000Z',
        updatedAt: '2020-04-01T13:32:47.000Z',
        deletedAt: null,
      },
    ],
    StudyGroupCollaborators: ['april@populix.co', 'hirzan@populix.co'],
  },
});

mock.onGet('/researchers/studyGroups/2').reply(200, {
  message: 'Success',
  code: 200,
  data: {
    id: 2,
    title: 'Brand Health Index - Visinema Pictures Daily Question',
    description: 'Kami ingin mengetahui pendapatmu mengenai online dating',
    status: 'ACTIVE',
    type: 'DAILY_QUESTION',
    studyUrl: 'https://www.google.com',
    startDate: moment().subtract(7, 'day'),
    endDate: moment(),
    StudyGroupCollaborators: ['april@populix.co', 'hirzan@populix.co'],
  },
});

/**
 * type: daily questions
 */
mock.onGet('/researchers/studyGroups/3').reply(200, {
  code: 200,
  message: 'Success',
  data: {
    id: 1,
    title: 'DQ - Transportasi Umum',
    description: 'DQ - Transportasi Umum',
    ownerEmail: 'admin@populix.co',
    status: 'IN_PROGRESS',
    startDate: null,
    endDate: null,
    type: 'DAILY_QUESTION',
    createdBy: 'admin@populix.co',
    updatedBy: 'admin@populix.co',
    excelReportState: 'UNDEFINED',
    excelReportName: null,
    tags: null,
    studyUrlStatus: 'PENDING',
    studyUrl: '',
    deviceType: 'Web, Android, Ios',
    totalParticipant: 0,
    studies: [
      {
        id: 347,
        title: 'DQ - Transportasi Umum',
        description: 'DQ - Transportasi Umum',
        studyUrl: null,
        researcherEmail: 'admin@populix.co',
        maxAllowedTime: null,
        averageCompletionTime: null,
        participantReward: null,
        requiredParticipants: null,
        status: 'NEED_CONFIRMATION',
        criteria: null,
        criteriaQuota: null,
        createdBy: null,
        updatedBy: null,
        startDate: null,
        endDate: null,
        type: 'custom',
        screeningType: 'allUser',
        whitelistEmail: null,
        excelReportState: 'UNDEFINED',
        excelReportName: null,
        excelReportCreatedAt: null,
        code: null,
        deviceType: 'web,android,ios',
        guestStudyUrl: null,
        StudyGroupId: 1,
        createdAt: '2020-04-09T17:32:47.000Z',
        updatedAt: '2020-04-09T17:32:47.000Z',
        deletedAt: null,
        SurveyQuestions: [
          {
            id: 1,
            content:
              '{"question":"Pernah naik transportasi umum?","options":["Pernah", "Tidak Pernah"]}',
            criteriaKey: null,
            publishedAt: '2020-04-12T17:01:24.000Z',
            picture: null,
            startDate: null,
            endDate: null,
            StudyId: 347,
            createdAt: '2020-04-09T17:32:48.000Z',
            updatedAt: '2020-04-12T17:01:24.000Z',
          },
          {
            id: 2,
            content:
              '{"question":"Seberapa sering menggunakan transportasi umum?","options":["Setiap hari", "Seminggu sekali", "Sangat jarang"]}',
            criteriaKey: null,
            publishedAt: '2020-04-13T17:01:23.000Z',
            picture: null,
            startDate: null,
            endDate: null,
            StudyId: 347,
            createdAt: '2020-04-09T17:32:48.000Z',
            updatedAt: '2020-04-13T17:01:23.000Z',
          },
        ],
      },
    ],
    StudyGroupCollaborators: ['collaborator1@email.com'],
  },
});

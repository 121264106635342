import { popCall } from 'src/utils/popCall';
import { showSnackbar } from './snackbarActions';

export const SET_CREDIT_WALLET = 'SET_CREDIT_WALLET';
export const RESET_CREDIT_WALLET = 'RESET_CREDIT_WALLET';

const getCreditWallet = async () => {
  const response = await popCall.get('/cxTools/credits?type=credits');
  return response?.data?.credits;
};

export const setCreditWalletInfo = () => {
  return async (dispatch) => {
    try {
      const respondentCreditAmount = await getCreditWallet();
      return dispatch({
        type: SET_CREDIT_WALLET,
        payload: { respondentCreditAmount },
      });
    } catch (error) {
      return dispatch(showSnackbar('Credit Wallet Not Found', 'error'));
    }
  };
};

export const resetCreditWalletInfo = () => (dispatch) => {
  dispatch({ type: RESET_CREDIT_WALLET });
};

import * as actionTypes from 'src/actions';

const initialState = {
  balance: null
};

const popliteWalletReducer = (state = initialState, actions) => {
  const { payload, type } = actions;

  switch (type) {
    case actionTypes.SET_WALLET:
      return {
        ...state,
        ...payload,
      };
    case actionTypes.RESET_WALLET:
      return {
        ...state,
        balance: null,
      };
    default:
      return state;
  }
};

export default popliteWalletReducer;

import mock from 'src/utils/mock';

mock.onGet('/researchers/studyGroups/1/result').reply(200, {
  message: 'Success',
  code: 200,
  data: {
    'Industri Pekerjaan': {
      type: 'doughnut',
      label: [
        'Pabrik Gelembung',
        'Perusahaan Transportasi Luar Angkasa',
        'Manufaktur Parts HAARP',
        'Ekspedisi Monster',
      ],
      value: [99, 23, 17, 11],
    },
    'Lama Bekerja di Perusahaan': {
      type: 'horizontal-bar',
      label: [
        '< 3 Hari',
        '5 - 10 Tahun',
        'Kurang Lebih 100 Tahun',
        'Baru Dipecat',
      ],
      value: [35, 70, 30, 15],
    },
    'Seberapa suka dengan perusahaan tempat bekerja sekarang? (rating dari 1-10)': {
      type: 'vertical-bar',
      label: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
      value: [35, 70, 30, 15, 3, 2, 15, 50, 10, 20],
    },
  },
});

mock
  .onGet(
    '/researchers/studyGroups/1/result?filter[title]=Gender&filter[values]=Laki-Laki,Perempuan',
  )
  .reply(200, {
    message: 'Success',
    code: 200,
    data: {
      'Industri Pekerjaan': {
        type: 'doughnut',
        label: [
          'Pabrik Gelembung',
          'Perusahaan Transportasi Luar Angkasa',
          'Manufaktur Parts HAARP',
          'Ekspedisi Monster',
        ],
        value: [17, 15, 8, 3],
      },
      'Lama Bekerja di Perusahaan': {
        type: 'horizontal-bar',
        label: [
          '< 3 Hari',
          '5 - 10 Tahun',
          'Kurang Lebih 100 Tahun',
          'Baru Dipecat',
        ],
        value: [23, 5, 15, 0],
      },
      'Seberapa suka dengan perusahaan tempat bekerja sekarang? (rating dari 1-10)': {
        type: 'vertical-bar',
        label: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
        value: [5, 10, 3, 2, 2, 2, 10, 20, 1, 15],
      },
    },
  });

/*
 *
 * type: daily questions
 */

// mock.onGet('/researchers/studyGroups/3/result/filter-options').reply(400, {
//   message: 'Not Found.',
//   code: 400,
// });

// mock.onGet('/researchers/studyGroups/3/result').reply(400, {
//   message: 'Not Found.',
//   code: 400,
// });

mock.onGet('/researchers/studyGroups/3/result/filter-options').reply(200, {
  message: 'Success',
  code: 200,
  data: {
    Gender: {
      question: 'Jenis Kelamin',
      options: ['Laki-Laki', 'Perempuan'],
    },
    Age: {
      question: 'Umur',
      options: ['<18', '18-24', '25-30', '31-35'],
    },
    JobStatus: {
      question: 'Profesi',
      options: [
        'Bekerja penuh waktu (full-time)',
        'Tidak bekerja (sedang mencari pekerjaan)',
        'Bekerja paruh waktu (part-time)',
      ],
    },
    SocialEconomicStatus: {
      question: 'Status Ekonomi Sosial',
      options: ['A > 6.000.000', 'B 4.000.001 - 6.000.000'],
    },
    Domicile: {
      question: 'Kota tempat tinggal',
      options: ['Jakarta Pusat', 'Jakarta Timur', 'Jakarta Utara'],
    },
  },
});

mock.onGet('/researchers/studyGroups/3/result').reply(200, {
  message: 'Success',
  code: 200,
  data: {
    'Pernah naik transportasi umum?': {
      type: 'doughnut',
      label: ['Pernah', 'Tidak Pernah'],
      value: [3, 2],
    },
    'Seberapa sering menggunakan transportasi umum?': {
      type: 'horizontal-bar',
      label: ['Setiap hari', 'Seminggu sekali', 'Sangat jarang'],
      value: [3, 1, 1],
    },
  },
});

mock
  .onGet(
    '/researchers/studyGroups/3/result?filter[by]=criteria&filter[label]=Gender&filter[values]=Perempuan',
  )
  .reply(200, {
    message: 'Success',
    code: 200,
    data: {
      'Pernah naik transportasi umum?': {
        type: 'doughnut',
        label: ['Tidak Pernah'],
        value: [2],
      },
      'Seberapa sering menggunakan transportasi umum?': {
        type: 'horizontal-bar',
        label: ['Seminggu sekali', 'Sangat jarang'],
        value: [1, 1],
      },
    },
  });

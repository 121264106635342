import mock from 'src/utils/mock';

// data based on real api
mock.onGet('/surveys/allsurveys').reply(200, {
  message: 'Success',
  code: 200,
  data: [
    {
      id: '90231052',
      title: 'Sosial Issues',
      links: 'https://www.surveygizmo.eu/s3/90231052/Sosial-Issues',
      created_on: '2020-04-21 03:48:26',
      modified_on: '2020-04-21 07:24:51',
    },
    {
      id: '90230718',
      title: 'P. ISN',
      links: 'https://www.surveygizmo.eu/s3/90230718/P-ISN',
      created_on: '2020-04-20 07:48:12',
      modified_on: '2020-04-20 09:59:24',
    },
    {
      id: '90230309',
      title: 'Zodiac',
      links: 'https://www.surveygizmo.eu/s3/90230309/Zodiac',
      created_on: '2020-04-17 07:26:02',
      modified_on: '2020-04-17 09:19:24',
    },
    {
      id: '90229921',
      title: 'Appraisal ',
      links: 'https://www.surveygizmo.eu/s3/90229921/Appraisal',
      created_on: '2020-04-16 05:58:32',
      modified_on: '2020-04-21 07:17:03',
    },
    {
      id: '90229910',
      title: 'Seberapa Generasi 90an kah Kamu?',
      links:
        'https://www.surveygizmo.eu/s3/90229910/Seberapa-Generasi-90an-kah-Kamu',
      created_on: '2020-04-16 04:20:56',
      modified_on: '2020-04-16 08:30:43',
    },
    {
      id: '90229239',
      title: 'Never Have I Ever...\t - FIX',
      links: 'https://www.surveygizmo.eu/s3/90229239/Never-Have-I-Ever-FIX',
      created_on: '2020-04-14 09:20:23',
      modified_on: '2020-04-14 09:24:49',
    },
    {
      id: '90229030',
      title: 'Never Have I Ever...',
      links: 'https://www.surveygizmo.eu/s3/90229030/Never-I-have-Ever',
      created_on: '2020-04-14 03:52:13',
      modified_on: '2020-04-14 07:37:00',
    },
    {
      id: '90229029',
      title: 'P. Flash',
      links: 'https://www.surveygizmo.eu/s3/90229029/P-Flash',
      created_on: '2020-04-14 03:26:30',
      modified_on: '2020-04-14 09:16:25',
    },
    {
      id: '90228921',
      title: 'P. App Quest',
      links: 'https://www.surveygizmo.eu/s3/90228921/P-App-Quest',
      created_on: '2020-04-13 14:21:41',
      modified_on: '2020-04-13 14:42:47',
    },
    {
      id: '90228827',
      title: 'P. Showtime NM',
      links: 'https://www.surveygizmo.eu/s3/90228827/P-Showtime-NM',
      created_on: '2020-04-13 03:41:55',
      modified_on: '2020-04-16 05:14:56',
    },
    {
      id: '90228826',
      title: 'P. Koala C',
      links: 'https://www.surveygizmo.eu/s3/90228826/P-Koala-C',
      created_on: '2020-04-13 03:35:03',
      modified_on: '2020-04-13 15:48:52',
    },
    {
      id: '90228825',
      title: 'P. Showtime MO',
      links: 'https://www.surveygizmo.eu/s3/90228825/P-Showtime-MO',
      created_on: '2020-04-13 03:21:13',
      modified_on: '2020-04-16 03:16:36',
    },
    {
      id: '90228448',
      title: 'P. Koala 2',
      links: 'https://www.surveygizmo.eu/s3/90228448/P-Koala-2',
      created_on: '2020-04-09 06:28:46',
      modified_on: '2020-04-14 06:41:52',
    },
    {
      id: '90228433',
      title: 'P. Lalala',
      links: 'https://www.surveygizmo.eu/s3/90228433/P-Lalala',
      created_on: '2020-04-09 04:30:13',
      modified_on: '2020-04-09 06:02:15',
    },
    {
      id: '90227974',
      title: 'P.  Sync 1',
      links: 'https://www.surveygizmo.eu/s3/90227974/P-Folks',
      created_on: '2020-04-07 13:17:36',
      modified_on: '2020-04-07 14:42:01',
    },
    {
      id: '90227798',
      title: 'Testing Hansen Keren',
      links: 'https://www.surveygizmo.eu/s3/90227798/Testing-Hansen-Keren',
      created_on: '2020-04-07 06:15:24',
      modified_on: '2020-04-07 06:16:11',
    },
    {
      id: '90227772',
      title: 'P. Quest Ext.',
      links: 'https://www.surveygizmo.eu/s3/90227772/P-Quest-Ext',
      created_on: '2020-04-07 02:59:02',
      modified_on: '2020-04-07 15:38:32',
    },
    {
      id: '90227482',
      title: 'P. Sync_referral ',
      links: 'https://www.surveygizmo.eu/s3/90227482/P-Sync-referral',
      created_on: '2020-04-06 03:43:16',
      modified_on: '2020-04-06 04:53:02',
    },
    {
      id: '90227481',
      title: 'P. Showtime QLI',
      links: 'https://www.surveygizmo.eu/s3/90227481/P-Showtime-QLI',
      created_on: '2020-04-06 03:24:14',
      modified_on: '2020-04-16 02:42:04',
    },
    {
      id: '90226882',
      title: 'P. Quintus 9',
      links: 'https://www.surveygizmo.eu/s3/90226882/P-Quintus-9',
      created_on: '2020-04-03 05:52:57',
      modified_on: '2020-04-14 09:24:30',
    },
    {
      id: '90226440',
      title: 'P. Quintus 8',
      links: 'https://www.surveygizmo.eu/s3/90226440/P-Quintus-8',
      created_on: '2020-04-02 02:17:52',
      modified_on: '2020-04-07 06:35:50',
    },
    {
      id: '90226183',
      title: 'P. Sync',
      links: 'https://www.surveygizmo.eu/s3/90226183/P-Sync',
      created_on: '2020-04-01 07:26:23',
      modified_on: '2020-04-20 02:41:56',
    },
    {
      id: '90225901',
      title: 'Test Study 6',
      links: 'https://www.surveygizmo.eu/s3/90225901/Test-Study-6',
      created_on: '2020-03-31 10:00:12',
      modified_on: '2020-03-31 10:20:34',
    },
    {
      id: '90225886',
      title: 'Test Study Donasi',
      links: 'https://www.surveygizmo.eu/s3/90225886/Test-Study-Donasi',
      created_on: '2020-03-31 09:31:56',
      modified_on: '2020-04-02 04:16:40',
    },
    {
      id: '90225811',
      title: 'P. Quintus 7',
      links: 'https://www.surveygizmo.eu/s3/90225811/P-Quintus-7',
      created_on: '2020-03-31 05:51:47',
      modified_on: '2020-03-31 06:08:00',
    },
    {
      id: '90225799',
      title: 'CGV Capital_Test',
      links: 'https://www.surveygizmo.eu/s3/90225799/CGV-Capital-Test',
      created_on: '2020-03-31 02:44:01',
      modified_on: '2020-03-31 05:01:35',
    },
    {
      id: '90225097',
      title: 'Test Study 5',
      links: 'https://www.surveygizmo.eu/s3/90225097/Test-Study-5',
      created_on: '2020-03-27 09:08:39',
      modified_on: '2020-03-29 15:56:39',
    },
    {
      id: '90225050',
      title: 'P. Quintus 6',
      links: 'https://www.surveygizmo.eu/s3/90225050/P-Quintus-6',
      created_on: '2020-03-27 03:16:26',
      modified_on: '2020-03-27 04:03:42',
    },
    {
      id: '90224856',
      title: 'P. Koala',
      links: 'https://www.surveygizmo.eu/s3/90224856/P-Koala',
      created_on: '2020-03-26 11:51:19',
      modified_on: '2020-04-21 07:27:16',
    },
    {
      id: '90224743',
      title: 'P. Quintus 5',
      links: 'https://www.surveygizmo.eu/s3/90224743/P-Quintus-5',
      created_on: '2020-03-26 04:50:09',
      modified_on: '2020-03-26 05:41:51',
    },
    {
      id: '90224072',
      title: 'P. Quintus 4',
      links: 'https://www.surveygizmo.eu/s3/90224072/P-Quintus-4',
      created_on: '2020-03-24 06:00:10',
      modified_on: '2020-03-24 06:18:29',
    },
    {
      id: '90224058',
      title: 'P. Brown',
      links: 'https://www.surveygizmo.eu/s3/90224058/P-Brown',
      created_on: '2020-03-24 04:02:10',
      modified_on: '2020-03-26 11:49:27',
    },
    {
      id: '90223756',
      title: 'P. Quintus 2',
      links: 'https://www.surveygizmo.eu/s3/90223756/P-Quintus-2',
      created_on: '2020-03-23 07:43:15',
      modified_on: '2020-03-23 08:03:59',
    },
    {
      id: '90223738',
      title: 'P. Selancar',
      links: 'https://www.surveygizmo.eu/s3/90223738/P-Selancar',
      created_on: '2020-03-23 05:16:28',
      modified_on: '2020-03-23 05:20:17',
    },
    {
      id: '90223327',
      title: 'P. Sixtus',
      links: 'https://www.surveygizmo.eu/s3/90223327/P-Sixtus',
      created_on: '2020-03-20 06:02:54',
      modified_on: '2020-03-20 06:20:51',
    },
    {
      id: '90223130',
      title: 'Corona Updates',
      links: 'https://www.surveygizmo.eu/s3/90223130/Corona-Updates',
      created_on: '2020-03-19 10:57:35',
      modified_on: '2020-03-19 11:37:40',
    },
    {
      id: '90223028',
      title: 'P. Ohana',
      links: 'https://www.surveygizmo.eu/s3/90223028/P-Ohana',
      created_on: '2020-03-19 04:03:05',
      modified_on: '2020-03-19 04:35:38',
    },
    {
      id: '90222723',
      title: 'P. Blue 3',
      links: 'https://www.surveygizmo.eu/s3/90222723/P-Blue-3',
      created_on: '2020-03-18 02:56:30',
      modified_on: '2020-03-24 07:03:08',
    },
    {
      id: '90222722',
      title: 'P. Folks',
      links: 'https://www.surveygizmo.eu/s3/90222722/P-Folks',
      created_on: '2020-03-18 02:33:41',
      modified_on: '2020-03-18 03:10:39',
    },
    {
      id: '90222440',
      title: 'P. Saving',
      links: 'https://www.surveygizmo.eu/s3/90222440/P-Saving',
      created_on: '2020-03-17 07:31:46',
      modified_on: '2020-03-17 07:55:10',
    },
    {
      id: '90222303',
      title: 'P. Caring 2\t - W21603',
      links: 'https://www.surveygizmo.eu/s3/90222303/P-Caring-2-W21603',
      created_on: '2020-03-16 12:32:29',
      modified_on: '2020-03-16 12:32:31',
    },
    {
      id: '90222290',
      title: 'P. Kriwil',
      links: 'https://www.surveygizmo.eu/s3/90222290/P-Kriwil',
      created_on: '2020-03-16 12:15:25',
      modified_on: '2020-03-18 01:34:46',
    },
    {
      id: '90222259',
      title: 'P.  Quest',
      links: 'https://www.surveygizmo.eu/s3/90222259/P-Quest',
      created_on: '2020-03-16 10:27:24',
      modified_on: '2020-04-07 03:01:43',
    },
    {
      id: '90221848',
      title: 'Welcome to Populix',
      links: 'https://www.surveygizmo.eu/s3/90221848/Welcome-to-Populix',
      created_on: '2020-03-13 10:11:25',
      modified_on: '2020-03-16 06:39:22',
    },
    {
      id: '90221740',
      title: 'TESTING STUDY 4',
      links: 'https://www.surveygizmo.eu/s3/90221740/TESTING-STUDY-4',
      created_on: '2020-03-13 03:13:07',
      modified_on: '2020-03-16 10:26:47',
    },
    {
      id: '90221624',
      title: 'Y2Y Freq',
      links: 'https://www.surveygizmo.eu/s3/90221624/Y2Y-Freq',
      created_on: '2020-03-12 14:44:32',
      modified_on: '2020-03-12 14:44:32',
    },
    {
      id: '90221499',
      title: 'P. Quarto 2',
      links: 'https://www.surveygizmo.eu/s3/90221499/P-Quarto-2',
      created_on: '2020-03-12 10:18:29',
      modified_on: '2020-03-12 10:36:23',
    },
    {
      id: '90221496',
      title: 'P. Quintus',
      links: 'https://www.surveygizmo.eu/s3/90221496/P-Quintus',
      created_on: '2020-03-12 10:13:41',
      modified_on: '2020-03-16 03:24:05',
    },
    {
      id: '90221432',
      title: 'TESTING STUDY 3',
      links: 'https://www.surveygizmo.eu/s3/90221432/TESTING-STUDY-3',
      created_on: '2020-03-12 06:57:44',
      modified_on: '2020-03-24 08:45:00',
    },
    {
      id: '90221431',
      title: 'TESTING STUDY 2',
      links: 'https://www.surveygizmo.eu/s3/90221431/TESTING-STUDY-2',
      created_on: '2020-03-12 06:53:23',
      modified_on: '2020-03-12 06:53:25',
    },
  ],
});

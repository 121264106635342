import * as actionTypes from 'src/actions';

const initialState = {
  selectedScreening: null,
  totalOwnedCriteria: [],
  isFromPE: false,
  isFromEditCriteria: false,
};

const panelExploreReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SET_PE_SCREENING_CRITERIA: {
      return {
        ...state,
        isFromPE: true,
        isFromEditCriteria: true,
        selectedScreening: payload?.selectedScreening,
        totalOwnedCriteria: payload?.totalOwnedCriteria,
      };
    }
    case actionTypes.RESET_IS_FROM_PE: {
      return {
        ...state,
        isFromPE: false,
      };
    }
    case actionTypes.RESET_FROM_EDIT_CRITERIA: {
      return {
        ...state,
        isFromEditCriteria: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default panelExploreReducer;

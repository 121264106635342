import {
  RESET_CREDIT_WALLET,
  SET_CREDIT_WALLET,
} from 'src/actions/creditWalletActions';

const initialState = {
  respondentCreditAmount: 0,
};

const creditWalletReducer = (state = initialState, actions) => {
  const { type, payload } = actions;

  switch (type) {
    case RESET_CREDIT_WALLET:
      return {
        ...state,
        respondentCreditAmount: 0,
      };
    case SET_CREDIT_WALLET:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export default creditWalletReducer;

/* eslint-disable import/prefer-default-export */
import { createApi } from '@reduxjs/toolkit/query/react';
import { popCall } from 'src/utils/popCall';

const popBaseQuery = () => async ({
  path,
  method,
  body,
  config,
  onSuccess,
  onError,
}) => {
  try {
    const apiInstance = () =>
      method === 'get'
        ? popCall[method](path, config)
        : popCall[method](path, body, config);

    const result = await apiInstance();

    if (onSuccess) onSuccess(result);

    return {
      ...result,
      meta: result,
    };
  } catch (axiosError) {
    if (onError) onError(axiosError);
    return axiosError;
  }
};

const popApi = createApi({
  reducerPath: 'popApi',
  baseQuery: popBaseQuery(),
  endpoints: () => ({}),
});

export default popApi;

import * as actionTypes from 'src/actions';

const retailAuditResourceInitialState = {
  mainBrands: [],
  subBrands: [],
  variantDetails: [],
  variantTypes: [],
};

export const retailAuditResourceReducer = (state = null, action) => {
  const { type, payload = null } = action;
  switch (type) {
    case actionTypes.SET_RETAIL_AUDIT_RESOURCE: {
      return { ...state, ...payload };
    }
    default: {
      return state;
    }
  }
};


import mock from 'src/utils/mock';

mock.onGet('/researchers/studyGroups/1/questionnaires').reply(200, {
  message: 'Success',
  code: 200,
  data: [
    {
      id: 1,
      fileName: 'Questionnaire_Carijodoh_v1.pdf',
      fileSize: 1024,
      status: 'REVISE',
      path: 'https://google.com',
    },
    {
      id: 2,
      fileName: 'Questionnaire_Carijodoh_v1.pdf',
      fileSize: 1234,
      status: 'REVISE',
      path: 'https://google.com',
    },
    {
      id: 3,
      fileName: 'Questionnaire_Carijodoh_v1.pdf',
      fileSize: 5432,
      status: 'APPROVED',
      path: 'https://google.com',
    },
  ],
});

mock.onGet('/researchers/studyGroups/2/questionnaires').reply(500, {
  code: 500,
  message: 'Error',
});

mock.onPost('/researchers/studyGroups/1/questionnaires/upload').reply(200, {
  code: 200,
  message: 'Success',
  data: {
    id: 4,
    fileName: 'Questionnaire_Carijodoh_v1_newest.pdf',
    fileSize: 5432,
    status: 'NEW',
    path: 'https://google.com',
  },
});

mock.onPut('/researchers/studyGroups/1/questionnaires/4/approve').reply(200, {
  code: 200,
  message: 'Success',
});

/**
 * type: daily questions
 */

mock
  .onGet('/researchers/studyGroups/3/questionnaires')
  .reply(200, { code: 200, message: 'Success.', data: [] });

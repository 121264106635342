import palette from '../palette';
import { grey100 } from '../color';

export default {
  root: {
    '&.Mui-disabled': {
      backgroundColor: grey100,
    },
    '& .MuiSelect-icon.Mui-disabled': {
      color: 'initial',
    },
  },
  input: {
    '&::placeholder': {
      opacity: 1,
      color: palette.text.secondary,
    },
  },
};

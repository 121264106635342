import PopCall from '../utils/popCall';

export const SET_CRITERIA_GROUPS = 'SET_CRITERIA_GROUPS';

export const setCriteriaGroups = () => async (dispatch) => {
  try {
    const popCall = new PopCall();
    const response = await popCall.get('/criteriaGroups?format=screening');

    // TODO: Remove the hardcoded group after the API is updated
    // The hardcoded group with id: 20 should be removed once the API provides this data
    const hardcodedGroup = {
      id: 20,
      name: 'LSM Classifier',
      tags: 'LSMClassifier',
      order: 14,
    };

    const payload = response?.data ? [...response.data, hardcodedGroup] : [];

    return dispatch({
      type: SET_CRITERIA_GROUPS,
      payload,
    });
  } catch (error) {
    dispatch({
      type: SET_CRITERIA_GROUPS,
      payload: [],
    });

    throw error;
  }
};

import { useDispatch } from 'react-redux';
import { showSnackbar } from 'src/actions';

/**
 * Custom hooks to show snakcbar
 *
 * @example
 * const snackbar = useSnackbar()
 *
 * function click() {
 *    snackbar({ type: 'error', message: 'Something went wrong' })
 * }
 */
export function useSnackbar() {
  const dispatch = useDispatch();

  /**
   * @param {object} params
   * @param {string} params.message
   * @param {'success' | 'error'} params.type
   */
  return ({ message, type }) => {
    dispatch(showSnackbar(message, type));
  };
}

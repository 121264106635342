import React from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import Tooltip from '@material-ui/core/Tooltip';

import AddIcon from '@material-ui/icons/AddCircleOutline';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import WebsiteIcon from 'src/assets/svg/websiteMenu.svg';

import BalanceCard from './BalanceCard';
import PopliteSeeResultCard from 'src/components/PopliteSeeResultCard';
import PopliteNavItem from 'src/components/PopliteNavItem';

import { togglePopliteDrawer } from 'src/actions';
import { appendAMRemoteKey } from 'src/utils/remoteClient'

const drawerWidth = 256;

const useStyles = makeStyles((theme) => ({
  drawer: {
    overflow: 'visible',
    padding: 12,
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    top: 'var(--drawer-top-height)',
    height: 'calc(100% - var(--drawer-top-height))',
    boxShadow: '2px 0px 10px rgba(0, 0, 0, 0.1)',
  },
  drawerOpen: {
    width: drawerWidth,
    [theme.breakpoints.between('lg', theme.breakpoints.hlg)]: {
      width: 220,
    },
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(10) + 2,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(10) + 2,
    },
  },
  divider: {
    backgroundColor: '#F2F2F2',
    marginTop: theme.spacing(2.5),
  },
  toggle: {
    position: 'absolute',
    background: '#FFFFFF',
    right: '-33px',
    padding: 0,
    width: 32,
    height: 32,
    border: '1px solid #E0E0E0',
    '&:hover': {
      background: '#FFFFFF',
    },
  },
  btnCreateSurvei: {
    margin: theme.spacing(2.5, 0, 0, 0),
    textTransform: 'capitalize',
  },
  rootBtnCreate: {
    padding: '9px 0px',
    minWidth: '48px !important',
    borderRadius: '64px',
  },
  labelBtnCreate: {
    overflow: 'hidden',
    justifyContent: 'start',
    paddingLeft: '6px',
    width: '24px',
  },
  tooltip: {
    backgroundColor: '#333333',
    boxShadow: '2px 4px 10px rgba(0, 0, 0, 0.1)',
    fontSize: 12,
    borderRadius: 50,
    padding: '4px 12px',
    left: '-8px !important',
  },
}));

const selectorAmRemote = (state) => [state.amRemoteAccess.isRemoting, state.amRemoteAccess.remoteKey]
export default function MiniDrawer({ navs, onMobileClose, openMobile }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  const [isAMRemoting, amRemoteKey] = useSelector(selectorAmRemote);
  const isPopliteDrawerOpen = useSelector(
    (state) => state.popliteDrawer.isPopliteDrawerOpen,
  );

  const navItems = isAMRemoting
    ? navs
    : navs?.concat({
        href: 'https://info.populix.co/poplite',
        title: 'Info Poplite',
        icon: WebsiteIcon,
      });

  const handleDrawerClose = () => {
    dispatch(togglePopliteDrawer(!isPopliteDrawerOpen));
  };

  return (
    <Drawer
      {...(openMobile && { open: openMobile, onMobileClose: onMobileClose })}
      variant={openMobile ? 'temporary' : 'permanent'}
      classes={{
        paper: clsx(classes.drawer, {
          [classes.drawerOpen]: isPopliteDrawerOpen,
          [classes.drawerClose]: !isPopliteDrawerOpen,
        }),
      }}
    >
      {isAMRemoting ? null : (
        <Tooltip
          classes={{
            tooltip: classes.tooltip,
          }}
          placement="right"
          title="Top Up Popcoins"
          disableHoverListener={isPopliteDrawerOpen}
        >
          <Box>
            <BalanceCard
              isMiniDrawer
              isPopliteDrawerOpen={isPopliteDrawerOpen}
            />
          </Box>
        </Tooltip>
      )}

      <Box position="relative">
        <IconButton onClick={handleDrawerClose} className={classes.toggle}>
          {!isPopliteDrawerOpen ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
      </Box>

      <Tooltip
        classes={{
          tooltip: classes.tooltip,
        }}
        disableHoverListener={isPopliteDrawerOpen}
        title="Buat Survei"
        placement="right"
      >
        <Button
          fullWidth
          color="primary"
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => history.push(appendAMRemoteKey('/poplite/projects/browse'))}
          className={classes.btnCreateSurvei}
          classes={{
            root: clsx({ [classes.rootBtnCreate]: !isPopliteDrawerOpen }),
            label: clsx({ [classes.labelBtnCreate]: !isPopliteDrawerOpen }),
          }}
        >
          Buat Survei Baru
        </Button>
      </Tooltip>
      <Divider className={classes.divider} />

      <List style={{ overflow: 'auto' }}>
        {navItems.map((item) => (
          <PopliteNavItem
            title={item.title}
            icon={item.icon}
            href={appendAMRemoteKey(item.href)}
            isDrawerOpen={isPopliteDrawerOpen}
          />
        ))}
      </List>

      <Divider className={classes.divider} />

      <Box overflow="hidden">
        {isPopliteDrawerOpen && !isAMRemoting ? <PopliteSeeResultCard /> : null}
      </Box>
    </Drawer>
  );
}

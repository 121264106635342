/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import { ReactComponent as CroissantIcon } from 'src/assets/svg/croissant.svg';
import { ReactComponent as TakeOutBoxIcom } from 'src/assets/svg/takeout-box.svg';
import { ReactComponent as MoneyBagIcon } from 'src/assets/svg/money-bag.svg';
import { ReactComponent as CameraIcon } from 'src/assets/svg/poplite-camera.svg';
import { ReactComponent as ThumbsUpIcon } from 'src/assets/svg/thumbs-up.svg';
import { ReactComponent as SparklesIcon } from 'src/assets/svg/sparkles.svg';
import { ReactComponent as TelescopeIcon } from 'src/assets/svg/telescope.svg';

export const deviceOptions = [
  { value: 'android', alias: 'Android' },
  { value: 'web', alias: 'Web' },
  { value: 'ios', alias: 'iOS' },
];

export const screeningTypeOptions = [
  { label: 'All User', value: 'allUser' },
  { label: 'Whitelist', value: 'whitelist', alias: 'whitelist name' }, // alias is one off for card header
  { label: 'Study Code', value: 'code' },
  { label: 'Portraits', value: 'default' },
  { label: 'Distinct Portraits', value: 'distinctPortrait' },
];

export const studyGroupStepperStepOptions = {
  defaultSteps: ['Study Detail'],
  whitelistSteps: ['Study Detail', 'Whitelist', 'Review'],
  codeSteps: ['Study Detail', 'Study Code', 'Review'],
  portraitSteps: ['Study Detail', 'Portrait', 'Review'],
  allUserSteps: ['Study Detail', 'Review'],
  dailyQuestionSteps: ['Study Detail', 'Question', 'Review'],
};

export const popliteStudyGroupStepperStepOptions = {
  steps: [
    'Judul',
    'Upload Gambar atau Video',
    'Pertanyaan Template',
    'Pertanyaan Tambahan',
    'Demografi Responden',
    'Review',
  ],
};

export const customPopliteStudyGroupStepperStepOptions = {
  steps: [
    'Judul',
    'Upload Gambar atau Video',
    'Pertanyaan',
    'Demografi Responden',
    'Review',
  ],
};

// Poplite attributes
export const MIN_ATTRIBUTES = 2;
export const MAX_ATTRIBUTES = 50;
export const ASSET_WAIT = 30;

export const projectStepperStepOptions = {
  defaultSteps: ['Project Detail', 'Review'],
};

export const studyGroupStatus = [
  { label: 'PENDING', value: 'PENDING' },
  { label: 'IN PROGRESS', value: 'IN_PROGRESS' },
  { label: 'COMPLETED', value: 'COMPLETED' },
];

export const studyGroupUrlStatus = [
  { label: 'APPROVED', value: 'APPROVED' },
  { label: 'REJECTED', value: 'REJECTED' },
  { label: 'PENDING', value: 'PENDING' },
];
// STUDY GROUP SURVEY LINK TYPE
export const studyGroupUrlType = {
  Gizmo: { title: 'Gizmo', action: 'gizmo' },
  Questionpro: { title: 'Questionpro', action: 'questionpro' },
};

// STUDY GROUP STATUS
export const DAILY_QUESTION = 'DAILY_QUESTION';
export const ONLINE_SURVEY = 'ONLINE_SURVEY';
export const FGD = 'FGD';
export const IDI = 'IDI';
export const E_COMMERCE_RETAIL_AUDIT = 'E_COMMERCE_RETAIL_AUDIT';

export const NEED_PAYMENT = 'NEED_PAYMENT';
export const NEED_CONFIRMATION = 'NEED_CONFIRMATION';
// DRAFT added for handling study from new enterprise
export const DRAFT = 'DRAFT';
export const STOP = 'STOP';
export const ACTIVE = 'ACTIVE';
export const INVOICE_REJECTED = 'INVOICE_REJECTED';

export const studyGroupType = [
  { label: 'ONLINE SURVEY', value: ONLINE_SURVEY },
  { label: 'DAILY QUESTION', value: DAILY_QUESTION },
  { label: 'FGD', value: FGD },
  { label: 'IDI', value: IDI },
];

export const studyStatus = [
  { label: 'NEED PAYMENT', value: NEED_PAYMENT },
  { label: 'PENDING', value: NEED_CONFIRMATION },
  { label: 'PENDING', value: DRAFT },
  { label: 'STOP', value: STOP },
  { label: 'IN PROGRESS', value: ACTIVE },
  { label: 'INVOICE REJECTED', value: INVOICE_REJECTED },
];

export const studyGroupTypeOptions = [
  {
    title: 'Online Survey',
    value: 'ONLINE_SURVEY',
    description:
      'Metode penelitian yang dilakukan dalam jangka waktu tertentu terhadap sejumlah individu, baik secara sensus atau dengan mengunakan sample.',
  },
  {
    title: 'Forum Group Discussion',
    value: 'FGD',
    description:
      'Metode penelitian yang menonjolkan adanya interaksi antara sesama peserta diskusi yang dilakukan pada waktu dan tempat yang telah kami sediakan.',
  },
  {
    title: 'Daily Question',
    value: 'DAILY_QUESTION',
    description:
      'Metode penelitian yang dilakukan dalam jangka waktu tertentu terhadap sejumlah individu, baik secara sensus atau dengan mengunakan sample.',
  },
  {
    title: 'In Depth Interview',
    value: 'IDI',
    description:
      'Penelitian dengan cara tanya jawab sambil bertatap muka dengan responden di waktu dan tempat telah yang kami sediakan.',
  },
  {
    title: 'Retail Audit',
    value: 'E_COMMERCE_RETAIL_AUDIT',
    description: 'Retail Audit for Diary Data Marketplace.',
  },
];

// wording not yet ready
export const projectTypeOptions = [
  {
    title: 'Poplite Quantitative',
    value: 'POPLITE_QUANTITATIVE',
    description:
      'Metode penelitian yang dilakukan dalam jangka waktu tertentu terhadap sejumlah individu, baik secara sensus atau dengan mengunakan sample.',
  },
  {
    title: 'Self Serve Poplite Quantitative',
    value: 'SELF_SERVE_POPLITE_QUANTITATIVE',
    description:
      'Metode penelitian yang dilakukan dalam jangka waktu tertentu terhadap sejumlah individu, baik secara sensus atau dengan mengunakan sample.',
  },
  {
    title: 'Poplite Qualititative',
    value: 'POPLITE_QUALITATIVE',
    description:
      'Metode penelitian yang dilakukan dalam jangka waktu tertentu terhadap sejumlah individu, baik secara sensus atau dengan mengunakan sample.',
  },
  {
    title: 'Enterprise',
    value: 'CUSTOM',
    description:
      'Metode penelitian yang dilakukan dalam jangka waktu tertentu terhadap sejumlah individu, baik secara sensus atau dengan mengunakan sample.',
  },
  {
    title: 'Omnibus',
    value: 'OMNIBUS',
    description:
      'Metode penelitian yang dilakukan dalam jangka waktu tertentu terhadap sejumlah individu, baik secara sensus atau dengan mengunakan sample.',
  },
  {
    title: 'Retail Audit',
    value: 'E_COMMERCE_RETAIL_AUDIT',
    description: 'Retail Audit for Diary Data Marketplace.',
  },
];

// PROJECT TYPE STATUS
export const OMNIBUS = 'OMNIBUS';

// POPLITE TEMPLATES

export const POPLITE_TEMPLATES = {
  develop: {
    name: 'Menu/Packaging & Pricing Test',
    packColor: '#00417D',
    pack: 'Develop',
    projectPack: 'develop',
    researchObject: 'menu/packaging',
    title: 'Membuat: Menu/Packaging & Pricing Test',
    description:
      'Membantu anda untuk membuat produk, menu yang tepat dengan harga yan tepat untuk brand anda',
    purpose: 'Menguji Menu dan Kemasan',
    purposeDescription: [
      {
        icon: <CroissantIcon />,
        text: 'Presentasi Makanan & Minuman',
      },
      {
        icon: <TakeOutBoxIcom />,
        text: 'Tampilan Kemasan',
      },
      {
        icon: <MoneyBagIcon />,
        text: 'Harga',
      },
    ],
    assetInstructions: 'Mohon upload menu/packaging yang ingin di test',
    panelInstructions:
      'Perhatikanlah menu/packaging ini dan beri pendapat kepada kami',
    attributeInstructions:
      'Attribut apa yang mau anda mau tau dari responden terhadap menu/packaging ini?',
    specificQuestion: {
      question:
        'Berapa rata-rata uang yang akan Anda keluarkan untuk membeli/menggunakan produk diatas?',
      type: 'radio',
      validation: 'number',
      options: [100000, 350000, 550000, 750000, 1000000],
    },
  },
  grow: {
    name: 'Ads Creative & Marketing Channel Analysis',
    packColor: '#EE7824',
    pack: 'Grow',
    projectPack: 'grow',
    researchObject: 'iklan',
    title: 'Mengembagkan: Ads Creative & Marketing Channel Analysis',
    description:
      'Kembangkan brand anda dengan membuat iklan yang tepat sasaran dan di channel yang tepat',
    purpose: 'Menguji Iklan dan Sosial Media',
    purposeDescription: [
      {
        icon: <CameraIcon />,
        text: 'Postingan Iklan/Ads',
      },
      {
        icon: <ThumbsUpIcon />,
        text: 'Channel Sosial Media',
      },
    ],
    assetInstructions: 'Mohon upload iklan yang ingin di test',
    panelInstructions: 'Perhatikanlah iklan ini dan beri pendapat kepada kami',
    attributeInstructions:
      'Attribut apa yang mau anda mau tau dari responden terhadap iklan ini?',
    specificQuestion: {
      question:
        'Mana dibawah ini yang paling mempengaruhi Anda untuk membeli dari iklan di atas?',
      type: 'radio',
      validation: 'single',
      options: [
        'TV',
        'Radio',
        'Internet (Sosial media, website, situs pencarian)',
        'Papan reklame/billboard',
        'Banner & flyer edaran',
        'Media cetak',
        'Rekomendasi teman/keluarga',
        'Lainnya, Sebutkan (input)',
      ],
    },
  },
  innovate: {
    name: 'Trend/Pulse Check',
    pack: 'Innovate',
    projectPack: 'innovate',
    packColor: '#8240E2',
    researchObject: 'trend',
    title: 'Berinovasi: Trend/Pulse Checker',
    description:
      'Jangan ketinggalan jaman, cari tahu jika trend tertentu cocok untuk brand kamu, atau saatnya cari target baru?',
    purpose: 'Menguji Trend ',
    purposeDescription: [
      {
        icon: <SparklesIcon />,
        text: 'Konsep Baru Usaha/Produk Kamu',
      },
      {
        icon: <TelescopeIcon />,
        text: 'Trend Pasar Saat Ini',
      },
    ],
    assetInstructions: 'Mohon upload trend yang ingin di test',
    panelInstructions: 'Perhatikanlah trend ini dan beri pendapat kepada kami',
    attributeInstructions:
      'Attribut apa yang mau anda mau tau dari responden terhadap trend ini?',
    specificQuestion: {
      question:
        'Mana dibawah ini yang paling mempengaruhi Anda untuk membeli dari trend di atas?',
      type: 'radio',
      validation: 'single',
      options: [
        'TV',
        'Radio',
        'Internet (Sosial media, website, situs pencarian)',
        'Papan reklame/billboard',
        'Banner & flyer edaran',
        'Media cetak',
        'Rekomendasi teman/keluarga',
        'Lainnya, Sebutkan (input)',
      ],
    },
  },
  custom: {
    name: 'Poplite Study',
    packColor: '#EE7824',
    pack: 'Poplite Study',
    description: '',
    projectPack: 'custom',
    purpose: '',
    title: '',
    panelInstructions:
      'Perhatikan gambar/video berikut dan berikan pendapat kepada kami...',
  },
};

export const SCREENING_TAGS_MENU = [
  {
    id: 1,
    name: 'Screening Dasar',
    tags: 'BasicProfile',
  },
  {
    id: 2,
    name: 'Profesi',
    tags: 'Profesi',
  },
  {
    id: 3,
    name: 'Pendidikan',
    tags: 'Education',
  },
  {
    id: 4,
    name: 'Hubungan Keluarga',
    tags: 'Family',
  },
  {
    id: 5,
    name: 'Status Sosial',
    tags: 'SocialStatus',
  },
  {
    id: 6,
    name: 'Lain - Lain',
    tags: 'Others',
  },
];

export const studyTypeOptions = ['Custom', 'Poplite'];

export const dailyQuestionCustomScreeningOptions = [
  {
    question: 'Status Ekonomi Sosial',
    label: 'MonthlyExpenses',
    options: [
      'A > 6.000.000',
      'B 4.000.001 - 6.000.000',
      'C 2.000.001 - 4.000.000',
      'D 1.300.000 - 2.000.000',
      'E < 1.300.000',
    ],
  },
  {
    question: 'Umur',
    label: 'Age',
    options: [
      '< 18 tahun',
      '18-21 tahun',
      '22-28 tahun',
      '29-38 tahun',
      '39-55 tahun',
      '56-76 tahun',
    ],
  },
];

export const QUESTIONNAIRE_STATUS = {
  APPROVED: 'APPROVED',
  REVISED: 'REVISED',
  NEW: 'NEW',
};

export const FILTER_OPERATOR = {
  is_one_of_the_following_answer: 'is one of the following answer',
  is_not_one_of_the_following_answer: 'is not one of the following answer',
  is_exactly_equal_to: 'is exactly equal to',
  is_not_exactly_equal_to: 'is not exactly equal to',
  contains: 'contains',
  date_after_or_equal_to: 'date after or equal to',
  date_before_or_equal_to: 'date before or equal to',
  greater_than: 'greater than',
  greater_than_or_equal_to: 'greater than or equal to',
  less_than: 'less than',
  less_than_or_equal_to: 'less than or equal to',
};

export const advanceFilterLogicOperators = {
  RADIO: [
    {
      key: 'is_one_of_the_following_answer',
      value: FILTER_OPERATOR.is_one_of_the_following_answer,
    },
    // {
    //   key: 'is_not_one_of_the_following_answer',
    //   value: FILTER_OPERATOR.is_not_one_of_the_following_answer,
    // },
  ],
  MENU: [
    {
      key: 'is_one_of_the_following_answer',
      value: FILTER_OPERATOR.is_one_of_the_following_answer,
    },
  ],
  CHECKBOX: [
    {
      key: 'is_one_of_the_following_answer',
      value: FILTER_OPERATOR.is_one_of_the_following_answer,
    },
    // {
    //   key: 'is_not_one_of_the_following_answer',
    //   value: FILTER_OPERATOR.is_not_one_of_the_following_answer,
    // },
    {
      key: 'is_exactly_equal_to',
      value: FILTER_OPERATOR.is_exactly_equal_to,
    },
    // {
    //   key: 'is_not_exactly_equal_to',
    //   value: FILTER_OPERATOR.is_not_exactly_equal_to,
    // },
  ],
  WORDCLOUD: [
    // {
    //   key: 'contains',
    //   value: FILTER_OPERATOR.contains,
    // },
    // {
    //   key: 'date_after_or_equal_to',
    //   value: FILTER_OPERATOR.date_after_or_equal_to,
    // },
    // {
    //   key: 'date_before_or_equal_to',
    //   value: FILTER_OPERATOR.date_before_or_equal_to,
    // },
  ],
  SCALE: [
    // {
    //   key: 'is_exactly_equal_to',
    //   value: FILTER_OPERATOR.is_exactly_equal_to,
    // },
    // {
    //   key: 'is_not_exactly_equal_to',
    //   value: FILTER_OPERATOR.is_not_exactly_equal_to,
    // },
    // {
    //   key: 'greater_than',
    //   value: FILTER_OPERATOR.greater_than,
    // },
    // {
    //   key: 'greater_than_or_equal_to',
    //   value: FILTER_OPERATOR.greater_than_or_equal_to,
    // },
    // {
    //   key: 'less_than',
    //   value: FILTER_OPERATOR.less_than,
    // },
    // {
    //   key: 'less_than_or_equal_to',
    //   value: FILTER_OPERATOR.less_than_or_equal_to,
    // },
  ],
};

export const advanceFilterLogicOperatorsEnterpriseClient = {
  RADIO: [
    {
      key: 'is_one_of_the_following_answer',
      value: FILTER_OPERATOR.is_one_of_the_following_answer,
    },
    {
      key: 'is_not_one_of_the_following_answer',
      value: FILTER_OPERATOR.is_not_one_of_the_following_answer,
    },
  ],
  MENU: [
    {
      key: 'is_one_of_the_following_answer',
      value: FILTER_OPERATOR.is_one_of_the_following_answer,
    },
  ],
  CHECKBOX: [
    {
      key: 'is_one_of_the_following_answer',
      value: FILTER_OPERATOR.is_one_of_the_following_answer,
    },
    {
      key: 'is_not_one_of_the_following_answer',
      value: FILTER_OPERATOR.is_not_one_of_the_following_answer,
    },
    {
      key: 'is_exactly_equal_to',
      value: FILTER_OPERATOR.is_exactly_equal_to,
    },
    {
      key: 'is_not_exactly_equal_to',
      value: FILTER_OPERATOR.is_not_exactly_equal_to,
    },
  ],
  TEXTBOX: [
    {
      key: 'contains',
      value: FILTER_OPERATOR.contains,
    },
  ],
  SCALE: [
    {
      key: 'is_exactly_equal_to',
      value: FILTER_OPERATOR.is_exactly_equal_to,
    },
    {
      key: 'is_not_exactly_equal_to',
      value: FILTER_OPERATOR.is_not_exactly_equal_to,
    },
    {
      key: 'greater_than',
      value: FILTER_OPERATOR.greater_than,
    },
    {
      key: 'greater_than_or_equal_to',
      value: FILTER_OPERATOR.greater_than_or_equal_to,
    },
    {
      key: 'less_than',
      value: FILTER_OPERATOR.less_than,
    },
    {
      key: 'less_than_or_equal_to',
      value: FILTER_OPERATOR.less_than_or_equal_to,
    },
  ],
};

export const onlineSurveyFeedbackURL =
  'https://www.surveygizmo.eu/s3/90256727/Online-Study-Feedback-Form';
export const omnibusFeedbackURL =
  'https://www.surveygizmo.eu/s3/90251973/Omnibus-Feedback-Form';

export const chartColorsV2 = [
  '#00417d',
  '#0a4a89',
  '#1c5293',
  '#2e5a9c',
  '#4162a3',
  '#556aa8',
  '#6a72aa',
  '#8079a9',
  '#9680a5',
  '#ab87a0',
  '#bd8e9b',
  '#cc979c',
  '#d4a2a5',
  '#d4afba',
  '#cbbdd8',
];

export const buttonColors = {
  white: '#FFFFFF',
  orange: '#EE7824',
  green: '#4CAF50',
  red: '#DB3737',
  blue: '#329BFF',
  gray: '#BDBDBD',
  grey: '#BDBDBD',
};

export const ADMIN = 'admin';
export const RESEARCHER = 'researcher';
export const RETAIL_AUDIT_QC = 'retailAuditQC';
export const GUEST = 'guest';
export const POPLITE = 'popliteClient';
export const POPLITE_PRO = 'poplitePro';
export const PARTICIPANT = 'participant';
export const ACCOUNT_MANAGER = 'accountManager';
export const DOSEN_BINUS = 'dosenBinus';
export const POPLITE_CX_CLIENT = 'cxToolClient';
export const ENTERPRISE_CLIENT = 'enterpriseClient';
export const CXTOOLS_FEATURE = 'CXTOOLS_FEATURE';
export const POPLITE_FEATURE = 'POPLITE_FEATURE';
export const INTERCOM_APP_ID = 'sgfj93ly';
export const FINANCE = 'finance';

export const POPLITE_FEATURES = [
  {
    title: 'Poplite',
    subtitle: 'General Survey',
    description:
      'Create a survey and get quick feedback from our qualified pool of respondents.',
    isNewFeature: false,
    path: '/poplite/projects',
    tag: `${POPLITE_FEATURE}`,
  },
  {
    title: 'CX Tool',
    subtitle: 'Customer Experience Survey',
    description:
      "Hear your customers' voices. Gather insights and take the right actions to increase your customers' happiness.",
    isNewFeature: true,
    path: '/poplite/cx-tools/survey',
    tag: `${CXTOOLS_FEATURE}`,
  },
];

export const productHandbookURL =
  'https://docs.google.com/document/d/1dSN2aG17QyFZKpYpdfxQ0AwOFhvfPAKwk0DBANs_v6E/edit';

export const exampleResultURL = {
  production:
    'https://enterprise.populix.co/poplite/example/916/details/overview',
  qa: 'https://enterprise.qapopulix.co/poplite/example/2750/details/overview',
};

export const chipColor = {
  draft: { bg: '#F2F2F2', color: '#828282' },
  scheduled: { bg: '#F9EDFF', color: '#7733D7' },
  ongoing: { bg: '#F5FFED', color: '#21892F' },
  paused: { bg: '#FFEFD0', color: '#997100' },
  stopped: { bg: '#FFDAD4', color: '#BA1B1B' },
  followup: { bg: '#EDDBFF', color: '#5F04BF' },
  member: { bg: '#E0E0E0', color: '#4F4F4F' },
};

export const CX_SENDER_EMAIL = 'cx@populix.co';

export const SELF_DISTRIBUTION = 'SELF';
export const POPULIX_DISTRIBUTION = 'POPULIX';

export const distributionMethods = [
  {
    value: SELF_DISTRIBUTION,
    label: 'Self Distribution',
    cost: 1,
    desc:
      'Send the unique survey link to the respondent through your communication platform.',
  },
  {
    value: POPULIX_DISTRIBUTION,
    label: 'Distributed by Populix',
    cost: 3,
    desc:
      'Send the unique survey link to the respondent through Populix’s Email and WhatsApp.',
  },
];

export const distributionInReview = {
  [SELF_DISTRIBUTION]: distributionMethods[0],
  [POPULIX_DISTRIBUTION]: distributionMethods[1],
};

export const getDistributionMsgWA = (
  brandName = '',
) => ` Halo Bapak/Ibu {Name}\n
Kami dari ${brandName} melalui Populix sebagai penyedia layanan survey ingin meminta pendapat anda mengenai produk/jasa yang kami berikan kepada Anda. Silahkan mengisi pendapat Bapak/Ibu melalui Link berikut : {Link}\n
Terima kasih`;

export const NESTED_LOGIC_OPERATORS = {
  EQUAL: 'EQUAL',
  NOT_EQUAL: 'NOT_EQUAL',
  OR: 'OR',
  NOR: 'NOR',
  CONTAINS: 'CONTAINS',
  NOT_CONTAIN: 'NOT_CONTAIN',
  AND: 'AND',
  GT: 'GT',
  GTE: 'GTE',
  LT: 'LT',
  LTE: 'LTE',
  RANGE: 'RANGE',
};

export const ALLOWED_PARTICIPANT_TYPE_BY_OPERATOR = {
  [NESTED_LOGIC_OPERATORS.EQUAL]: {
    label: 'Equal to',
    allowedFields: [
      'OPTION_SELECT',
      'INPUT_NUMBER',
      'INPUT_DATE',
      'OPTION_CITY',
      'OPTION_GENDER',
      'DETAILED_ADDRESS',
    ],
  },
  [NESTED_LOGIC_OPERATORS.NOT_EQUAL]: {
    label: 'Not Equal to',
    allowedFields: [
      'OPTION_SELECT',
      'INPUT_NUMBER',
      'INPUT_DATE',
      'OPTION_CITY',
      'OPTION_GENDER',
      'DETAILED_ADDRESS',
    ],
  },
  [NESTED_LOGIC_OPERATORS.OR]: {
    label: 'Or',
    multiple: true,
    allowedFields: [
      'OPTION_SELECT',
      'MULTI_SELECT',
      'OPTION_CITY',
      'DETAILED_ADDRESS',
    ],
  },
  [NESTED_LOGIC_OPERATORS.NOR]: {
    label: 'Nor',
    multiple: true,
    allowedFields: ['OPTION_SELECT', 'OPTION_CITY', 'DETAILED_ADDRESS'],
  },
  [NESTED_LOGIC_OPERATORS.CONTAINS]: {
    label: 'Contain',
    allowedFields: ['MULTI_SELECT'],
  },
  [NESTED_LOGIC_OPERATORS.NOT_CONTAIN]: {
    label: 'Not Contain',
    allowedFields: ['MULTI_SELECT'],
  },
  [NESTED_LOGIC_OPERATORS.AND]: {
    label: 'And',
    multiple: true,
    allowedFields: ['MULTI_SELECT'],
  },
  [NESTED_LOGIC_OPERATORS.GT]: {
    label: 'Greater than',
    allowedFields: ['INPUT_NUMBER', 'INPUT_DATE'],
  },
  [NESTED_LOGIC_OPERATORS.GTE]: {
    label: 'Greater than equal',
    allowedFields: ['INPUT_NUMBER', 'INPUT_DATE'],
  },
  [NESTED_LOGIC_OPERATORS.LT]: {
    label: 'Less than',
    allowedFields: ['INPUT_NUMBER', 'INPUT_DATE'],
  },
  [NESTED_LOGIC_OPERATORS.LTE]: {
    label: 'Less than equal',
    allowedFields: ['INPUT_NUMBER', 'INPUT_DATE'],
  },
  [NESTED_LOGIC_OPERATORS.RANGE]: {
    label: 'Between',
    allowedFields: ['INPUT_NUMBER', 'INPUT_DATE'],
  },
};

export const PopMartProductTypes = {
  EVOUCHER: 'EVOUCHER',
  PHYSICAL: 'PHYSICAL',
};

export const EvocherCsvExampleUrl =
  'https://s3.ap-southeast-1.amazonaws.com/staging-static.populix.co/pop-mart-evoucher-csv-sample.csv';

export const PopmartSubmitType = {
  ACTIVATE: 'ACTIVATE',
  DEACTIVATE: 'DEACTIVATE',
  SAVE: 'SAVE',
  DRAFT: 'DRAFT',
};

export const PopPollStatus = {
  DRAFT: 'DRAFT',
  ONGOING: 'ONGOING',
  PUBLISHED: 'PUBLISHED',
  UNPUBLISHED: 'UNPUBLISHED',
  SCHEDULED: 'SCHEDULED',

  // old status
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

export const PopmartStatus = {
  DRAFT: 'DRAFT',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

export const LuckyDrawStatus = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  UPDATE: 'UPDATE',
};

export const DateFormat = 'YYYY-MM-DD';

export const PopStarCampaignStatus = {
  APPROVED: 'APPROVED',
  PENDING: 'PENDING_APPROVAL',
  ONGOING: 'ONGOING',
  REJECTED: 'REJECTED',
  CANCELLED: 'CANCELLED',
};

export const PopStarCriteriaType = {
  GENERAL: { key: 'GENERAL', value: 'basicProfile' },
  SOCIAL_MEDIA: { key: 'SOCIAL_MEDIA', value: 'socialMedia' },
};

export const PopStarSocmedCriteriaPlatforms = [
  {
    key: 'INSTAGRAM',
    label: 'Instagram',
  },
  {
    key: 'TIKTOK',
    label: 'Tiktok',
  },
  {
    key: 'FACEBOOK',
    label: 'Facebook',
  },
  {
    key: 'TWITTER',
    label: 'Twitter',
  },
];

export const PopStarCriteriaOptionType = {
  MULTIPLE_SELECT: 'MULTIPLE_SELECT',
  MULTI_SELECT: 'MULTI_SELECT',
  RANGE: 'RANGE',
  OCCUPATION_SELECT: 'OCCUPATION_SELECT',
  DOMICILE_SELECT: 'DOMICILE_SELECT',
  MULTIPLE_OPTION: 'MULTIPLE_OPTION',
};

export const PopStarCampaignOverviewButtonType = {
  DRAFT: 'DRAFT',
  PUBLISHED: 'PUBLISHED',
  UNPUBLISHED: 'UNPUBLISHED',
  SAVE: 'SAVE',
  DOWNLOAD: 'DOWNLOAD',
  EXIT: 'EXIT',
  ERROR: 'ERROR',
};

export const RewardType = {
  CASH: 'CASH',
  POINT: 'POINT',
};

import React from 'react';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Box from '@material-ui/core/Box';
import PopliteNavItem from 'src/components/PopliteNavItem';

const drawerWidth = 256;

const useStyles = makeStyles((theme) => ({
  drawer: {
    top: 72,
    height: 'calc(100% - 72px)',
    padding: theme.spacing(2.5),
    width: drawerWidth,
    boxShadow: '2px 0px 10px rgba(0, 0, 0, 0.1)',
    [theme.breakpoints.between('lg', theme.breakpoints.hlg)]: {
      width: 'unset',
    },
  },
  divider: {
    backgroundColor: '#E0E0E0',
  },
  list: {
    marginBottom: theme.spacing(1.5),
  },
}));

export default function CxToolsDrawer({ navs }) {
  const classes = useStyles();
  const isPopliteDrawerOpen = useSelector(
    (state) => state.popliteDrawer.isPopliteDrawerOpen,
  );
  const isCxToolsFeature = useSelector(
    (state) => state.popliteDrawer.isCxToolsFeature,
  );
  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: classes.drawer,
      }}
    >
      <Box color="#333333" fontSize="12px" fontWeight="600" mb={2.5}>
        Main Menu
      </Box>

      <Divider className={classes.divider} />

      <List className={classes.list}>
        {navs.map((item) => (
          <PopliteNavItem
            key={item.href}
            title={item.title}
            icon={item.icon}
            href={item.href}
            isDrawerOpen={isPopliteDrawerOpen}
            isCxTools={isCxToolsFeature}
          />
        ))}
      </List>

      <Divider className={classes.divider} />
    </Drawer>
  );
}

import FolderIcon from 'src/assets/svg/projectMenu.svg';
import AssessmentOutlined from 'src/assets/svg/studyMenu.svg';
import PeopleIcon from 'src/assets/svg/userMenu.svg';
import AppsIcon from 'src/assets/svg/criteriaMenu.svg';
import SearchIcon from 'src/assets/svg/panelExplorerMenu.svg';
import ProfileMenuIcon from 'src/assets/svg/profileMenu.svg';
import ResearcherIcon from 'src/assets/svg/researcher.svg';
import ThumbsupIcon from 'src/assets/svg/referralMenu.svg';
import CreditCardIcon from 'src/assets/svg/transactionMenu.svg';
import SurveyIcon from 'src/assets/svg/surveyCxMenu.svg';
import MyOrganizationIcon from 'src/assets/svg/myOrganizationCxMenu.svg';
import CreditUsageIcon from 'src/assets/svg/creditUsageCxMenu.svg';
import UploadIcon from 'src/assets/svg/uploadMenu.svg';
// NOTES: pending usage
// import PromoManagementIcon from 'src/assets/svg/promoManagementMenu.svg';
import MarketingIcon from 'src/assets/svg/marketingMenu.svg';
import {
  ADMIN,
  RESEARCHER,
  POPLITE,
  RETAIL_AUDIT_QC,
  GUEST,
  ACCOUNT_MANAGER,
  ENTERPRISE_CLIENT,
} from 'src/utils/variables';
import { useSelector } from 'react-redux';

const routeRoles = {
  '/projects': [ADMIN, RESEARCHER, POPLITE, GUEST, ENTERPRISE_CLIENT],
  '/projects/browse': [ADMIN, RESEARCHER, POPLITE, GUEST, ENTERPRISE_CLIENT],
  '/projects/create': [ADMIN],
  '/marketing': [ADMIN],
  '/marketing/pop-poll': [ADMIN],
  '/marketing/pop-mart': [ADMIN],
  '/marketing/lucky-draw': [ADMIN],
  '/marketing/pop-star': [ADMIN],
  '/marketing/reports': [ADMIN],
  '/studies': [ADMIN, RESEARCHER, POPLITE],
  '/studies/browse': [ADMIN, RESEARCHER, POPLITE],
  '/studies/create/dailyquestion': [ADMIN, RESEARCHER],
  '/user-management': [ADMIN],
  '/panel-explorer': [ADMIN],
  '/panel-book': [ADMIN, RESEARCHER, GUEST],
  '/criteria': [ADMIN],
  '/criteria/browse': [ADMIN],
  '/criteria/create': [ADMIN],
  '/criteria/participant': [ADMIN],
  '/screening-logic/browse': [ADMIN],
  '/screening-logic/create': [ADMIN],
  '/retail-audit': [ADMIN, RETAIL_AUDIT_QC],
  '/retail-audit/browse': [ADMIN, RETAIL_AUDIT_QC],
  '/retail-audit/products/browse': [ADMIN, RETAIL_AUDIT_QC],
  '/profile': [ADMIN],
  '/profile/general': [ADMIN],
  '/profile/security': [ADMIN],
  '/referrals': [ADMIN],
  '/referrals/create': [ADMIN],
  '/referrals/browse': [ADMIN],
  '/poplite/user': [ACCOUNT_MANAGER],
  '/poplite/projects': [ADMIN, POPLITE],
  '/poplite/projects/browse': [ADMIN, POPLITE],
  '/poplite/projects/create': [ADMIN, POPLITE],
  '/poplite/studies': [ADMIN, POPLITE],
  '/poplite/studies/browse': [ADMIN, POPLITE],
  '/poplite/studies/create/dailyQuestion': [ADMIN, POPLITE],
  '/poplite/panel-book': [ADMIN, POPLITE],
  '/poplite/payments': [ADMIN, POPLITE],
  '/poplite/cx-tools/survey': [ADMIN, POPLITE],
  '/poplite/cx-tools/credit-usage': [ADMIN, POPLITE],
  '/poplite/cx-tools/organization': [ADMIN, POPLITE],
  '/poplite/cx-tools/client-management': [ADMIN],
  '/poplite/payments/browse': [ADMIN, POPLITE],
  '/personality-result': [ADMIN],
  '/promo-management': [ADMIN],
};

const useGetNavConfig = () => {
  const featureFlag = useSelector((state) => state.featureFlag);

  const isNavAccessibleByRole = (path, userRoles) =>
    routeRoles[path]?.some((routeRole) => userRoles.includes(routeRole));

  const loadProjectRouteConfig = (userRoles) =>
    (isNavAccessibleByRole(`/projects`, userRoles) && [
      {
        title: 'Project',
        href: `/projects`,
        icon: FolderIcon,
        items: [
          ...((isNavAccessibleByRole(`/projects/browse`, userRoles) && [
            {
              title: 'Browse',
              href: '/projects/browse',
            },
          ]) ||
            []),
          ...((isNavAccessibleByRole('/projects/create', userRoles) && [
            {
              title: 'Create',
              href: `/projects/create`,
            },
          ]) ||
            []),
        ],
      },
    ]) ||
    [];

  const loadStudyRouteConfig = (userRoles, isPoplite) => {
    const menu = isPoplite ? 'Survei Kamu' : 'Study';
    const submenu = isPoplite ? 'Jelajah Survei Kamu' : 'Browse';
    const prefixPath = isPoplite ? '/poplite' : '';

    return (
      (isNavAccessibleByRole(`${prefixPath}/studies`, userRoles) && [
        {
          title: menu,
          href: `${prefixPath}/studies`,
          icon: AssessmentOutlined,
          items: [
            ...((isNavAccessibleByRole(
              `${prefixPath}/studies/browse`,
              userRoles,
            ) && [
              {
                title: submenu,
                href: `${prefixPath}/studies/browse`,
              },
            ]) ||
              []),
            ...((isNavAccessibleByRole(
              `${prefixPath}/studies/create/dailyquestion`,
              userRoles,
            ) && [
              {
                title: 'Daily Question',
                href: `${prefixPath}/studies/create/dailyquestion`,
              },
            ]) ||
              []),
          ],
        },
      ]) ||
      []
    );
  };

  const loadMarketingRouteConfig = (userRoles) =>
    (isNavAccessibleByRole(`/marketing`, userRoles) && [
      {
        title: 'Marketing',
        href: `/marketing`,
        icon: MarketingIcon,
        items: [
          ...((isNavAccessibleByRole(`/marketing/pop-poll`, userRoles) && [
            {
              title: 'PopPoll',
              href: '/marketing/pop-poll',
            },
          ]) ||
            []),
          ...((isNavAccessibleByRole('/marketing/pop-mart', userRoles) && [
            {
              title: 'PopMart',
              href: `/marketing/pop-mart`,
            },
          ]) ||
            []),
          ...((isNavAccessibleByRole('/marketing/lucky-draw', userRoles) && [
            {
              title: 'Lucky Draw',
              href: `/marketing/lucky-draw`,
            },
          ]) ||
            []),
          ...((isNavAccessibleByRole('/marketing/pop-star', userRoles) &&
            featureFlag?.popStar?.isActive && [
              {
                title: 'Super PopStar',
                href: `/marketing/pop-star`,
              },
            ]) ||
            []),
          ...((isNavAccessibleByRole('/marketing/reports', userRoles) && [
            {
              title: 'Reports',
              href: `/marketing/reports`,
            },
          ]) ||
            []),
        ],
      },
    ]) ||
    [];

  const loadUserManagementConfig = (userRoles) =>
    (isNavAccessibleByRole('/user-management', userRoles) && [
      {
        title: 'User Management',
        href: '/user-management',
        icon: PeopleIcon,
      },
    ]) ||
    [];

  const loadPanelExplorerDashboardConfig = (userRoles) =>
    (isNavAccessibleByRole('/panel-explorer', userRoles) && [
      {
        title: 'Panel Explorer',
        href: '/panel-explorer',
        icon: SearchIcon,
        iconActive: AppsIcon,
      },
    ]) ||
    [];

  const loadPanelBookConfig = (userRoles, isPoplite) => {
    const title = isPoplite ? 'Respondent Populix' : 'Panel Book';
    const prefixPath = isPoplite ? '/poplite' : '';

    return (
      (isNavAccessibleByRole(`${prefixPath}/panel-book`, userRoles) && [
        {
          title,
          href: `${prefixPath}/panel-book`,
          icon: ResearcherIcon,
          iconActive: AppsIcon,
        },
      ]) ||
      []
    );
  };

  const loadCriteriaRouteConfig = (userRoles) =>
    (isNavAccessibleByRole('/criteria', userRoles) && [
      {
        title: 'Criteria Management',
        href: '/criteria',
        icon: AppsIcon,
        items: [
          ...((isNavAccessibleByRole('/criteria/browse', userRoles) && [
            {
              title: 'Browse Criteria',
              href: '/criteria/browse',
            },
          ]) ||
            []),
          ...((isNavAccessibleByRole('/criteria/create', userRoles) && [
            {
              title: 'Create Criteria',
              href: '/criteria/create',
            },
          ]) ||
            []),
          ...((isNavAccessibleByRole('/screening-logic/browse', userRoles) && [
            {
              title: 'Browse Screening Logic',
              href: '/screening-logic/browse',
            },
          ]) ||
            []),
          ...((isNavAccessibleByRole('/screening-logic/create', userRoles) && [
            {
              title: 'Create Screening Logic',
              href: '/screening-logic/create',
            },
          ]) ||
            []),
          ...((isNavAccessibleByRole('/criteria/participant', userRoles) && [
            {
              title: 'Participant Criteria',
              href: '/criteria/participant',
            },
          ]) ||
            []),
        ],
      },
    ]) ||
    [];

  const loadRetailAuditConfig = (userRoles) =>
    (isNavAccessibleByRole('/retail-audit', userRoles) && [
      {
        title: 'Retail Audit QC',
        href: '/retail-audit',
        icon: AppsIcon,
        items: [
          ...((isNavAccessibleByRole('/retail-audit/browse', userRoles) && [
            {
              title: 'Transaction List',
              href: '/retail-audit/browse',
            },
          ]) ||
            []),
          ...((isNavAccessibleByRole(
            '/retail-audit/products/browse',
            userRoles,
          ) && [
            {
              title: 'Product Database',
              href: '/retail-audit/products/browse',
            },
          ]) ||
            []),
        ],
      },
    ]) ||
    [];

  const loadProfileRouteConfig = (userRoles) =>
    (isNavAccessibleByRole('/profile', userRoles) && [
      {
        title: 'Profile',
        href: '/profile',
        icon: ProfileMenuIcon,
        items: [
          ...((isNavAccessibleByRole('/profile/general', userRoles) && [
            {
              title: 'General',
              href: '/profile/general',
            },
          ]) ||
            []),
          ...((isNavAccessibleByRole('/profile/security', userRoles) && [
            {
              title: 'Security',
              href: '/profile/security',
            },
          ]) ||
            []),
        ],
      },
    ]) ||
    [];

  const loadReferralRouteConfig = (userRoles) =>
    (isNavAccessibleByRole('/referrals', userRoles) && [
      {
        title: 'Referral Tools',
        href: '/referrals',
        icon: ThumbsupIcon,
        items: [
          ...((isNavAccessibleByRole('/referrals/browse', userRoles) && [
            {
              title: 'Browse',
              href: '/referrals/browse',
            },
          ]) ||
            []),
          ...((isNavAccessibleByRole('/referrals/create', userRoles) && [
            {
              title: 'Create',
              href: '/referrals/create',
            },
          ]) ||
            []),
        ],
      },
    ]) ||
    [];

  const loadPopliteUserRouteConfig = (userRoles) =>
    (isNavAccessibleByRole('/poplite/user', userRoles) && [
      {
        title: 'Poplite’s User',
        href: '/poplite/user',
        icon: MyOrganizationIcon,
      },
    ]) ||
    [];

  const loadPopliteSurveyRouteConfig = (userRoles) =>
    (isNavAccessibleByRole(`/poplite/studies/browse`, userRoles) && [
      {
        title: 'Survei Saya',
        href: '/poplite/studies/browse',
        icon: AssessmentOutlined,
      },
    ]) ||
    [];

  const loadPopliteProjectRouteConfig = (userRoles) =>
    (isNavAccessibleByRole(`/poplite/projects`, userRoles) && [
      {
        title: 'Beranda',
        href: `/poplite/projects`,
        icon: FolderIcon,
      },
    ]) ||
    [];

  const loadPopliteTransactionHistoryRouteConfig = (userRoles) =>
    (isNavAccessibleByRole(`/poplite/projects`, userRoles) && [
      {
        title: 'Transaksi Saya',
        href: `/poplite/payments/browse`,
        icon: CreditCardIcon,
      },
    ]) ||
    [];

  function loadPersonalityResultRouteConfig(userRoles) {
    if (isNavAccessibleByRole(`/personality-result`, userRoles)) {
      return [
        {
          title: 'Personality Result',
          href: `/personality-result/upload`,
          icon: UploadIcon,
        },
      ];
    }

    return [];
  }

  function loadCxToolsSurveyRouteConfig(userRoles) {
    if (isNavAccessibleByRole(`/poplite/cx-tools/survey`, userRoles)) {
      return [
        {
          title: 'Survey',
          href: `/poplite/cx-tools/survey`,
          icon: SurveyIcon,
        },
      ];
    }
    return [];
  }

  function loadCxToolsOrganizationRouteConfig(userRoles) {
    if (isNavAccessibleByRole(`/poplite/cx-tools/organization`, userRoles)) {
      return [
        {
          title: 'My Organization',
          href: `/poplite/cx-tools/organization/overview`,
          icon: MyOrganizationIcon,
        },
      ];
    }
    return [];
  }

  function loadCxToolsCreditUsageRouteConfig(userRoles) {
    if (isNavAccessibleByRole(`/poplite/cx-tools/credit-usage`, userRoles)) {
      return [
        {
          title: 'Credit Usage',
          href: `/poplite/cx-tools/credit-usage`,
          icon: CreditUsageIcon,
        },
      ];
    }
    return [];
  }

  // NOTES: pending usage
  // function loadCxToolsClientRouteConfig(userRoles) {
  //   if (isNavAccessibleByRole(`/poplite/cx-tools/client-management`, userRoles)) {
  //     return [
  //       {
  //         title: `CX Tool’s Client`,
  //         href: `/poplite/cx-tools/client-management`,
  //         icon: MyOrganizationIcon,
  //       },
  //     ];
  //   }
  //   return [];
  // }

  // NOTES: pending usage
  // function loadPromoManagementRouteConfig(userRoles) {
  //   if (isNavAccessibleByRole('/promo-management', userRoles)) {
  //     return [
  //       {
  //         title: 'Promo Management',
  //         href: '/promo-management',
  //         icon: PromoManagementIcon,
  //       },
  //     ];
  //   }
  //   return [];
  // }

  const popliteRouteItems = (userRoles, isCxToolsFeature, isRemote) => {
    if (isRemote) {
      return [...loadPopliteSurveyRouteConfig(userRoles)];
    }

    if (isCxToolsFeature) {
      return [
        ...loadCxToolsSurveyRouteConfig(userRoles),
        ...loadCxToolsCreditUsageRouteConfig(userRoles),
        ...loadCxToolsOrganizationRouteConfig(userRoles),
      ];
    }

    return [
      ...loadPopliteProjectRouteConfig(userRoles),
      ...loadPopliteSurveyRouteConfig(userRoles),
      ...loadPopliteTransactionHistoryRouteConfig(userRoles),
      ...loadPopliteUserRouteConfig(userRoles),
    ];
  };

  const routeItems = (userRoles, isPoplite, disableProject, disableStudy) => [
    ...loadPanelExplorerDashboardConfig(userRoles),
    ...(disableProject ? [] : loadProjectRouteConfig(userRoles)),
    ...(disableStudy ? [] : loadStudyRouteConfig(userRoles)),
    ...loadMarketingRouteConfig(userRoles),
    ...loadCriteriaRouteConfig(userRoles),
    ...loadPanelBookConfig(userRoles, isPoplite),
    ...loadUserManagementConfig(userRoles),
    ...loadRetailAuditConfig(userRoles),
    ...loadProfileRouteConfig(userRoles),
    ...loadReferralRouteConfig(userRoles),
    // NOTES: pending usage
    // ...loadCxToolsClientRouteConfig(userRoles),
    ...loadPopliteUserRouteConfig(userRoles),
    // NOTES: pending usage
    // ...loadPromoManagementRouteConfig(userRoles),
    ...loadPersonalityResultRouteConfig(userRoles),
  ];

  const getNavConfig = (
    userRoles,
    isCxToolsFeature,
    disableProject,
    disableStudy,
    isRemote = false,
  ) => {
    const isPoplite = isRemote ? true : userRoles.indexOf(POPLITE) >= 0;
    let title = '';

    if (userRoles?.length) {
      title = !isPoplite ? 'Pages' : '';
      if (isCxToolsFeature) title = 'Main Menu';
    }

    const items = isPoplite
      ? popliteRouteItems(userRoles, isCxToolsFeature, isRemote)
      : routeItems(userRoles, isPoplite, disableProject, disableStudy);

    return [
      {
        subheader: title,
        items,
      },
    ];
  };

  return { getNavConfig };
};

export default useGetNavConfig;

import { apmBase } from '@elastic/apm-rum';

export function initAPM (active) {
  return apmBase.init({
    serviceName: process.env.REACT_APP_ELASTIC_APM_SERVICE_NAME,
    serverUrl: process.env.REACT_APP_ELASTIC_APM_SERVER_URL,
    active,
    environment: process.env.REACT_APP_ENVIRONMENT,
  });
}

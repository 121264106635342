import React, { useState } from 'react';
import { useLocation } from 'react-router';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import ReactGA from 'react-ga';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { ListItem, Button, Collapse } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0,
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: (props) => (props.isPoplite ? theme.spacing(2) : 0),
  },
  button: {
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    [theme.breakpoints.between('lg', theme.breakpoints.hlg)]: {
      fontSize: '12px',
    },
  },
  buttonLeafPoplite: {
    color: '#4F4F4F',
    borderRadius: '16px',
    fontWeight: 300,
  },
  buttonLeaf: {
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightRegular,
    '&.depth-0': {
      fontWeight: theme.typography.fontWeightMedium,
    },
    [theme.breakpoints.between('lg', theme.breakpoints.hlg)]: {
      fontSize: '12px',
    },
  },
  icon: {
    color: theme.palette.icon,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  expandIcon: {
    marginLeft: 'auto',
    marginRight: 8,
    height: 16,
    width: 16,
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
  },
  activePoplite: {
    color: '#333333',
    background: '#F2F2F2',
    fontWeight: 600,
    '& $icon': {
      color: '#002B5A',
    },
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main,
    },
  },
  inActive: {
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

function NavItem({
  id,
  title,
  href,
  depth,
  children,
  icon: Icon,
  className,
  open: openProp,
  label: Label,
  isPoplite,
  ...rest
}) {
  const classes = useStyles({ isPoplite });
  const location = useLocation()
  const [open, setOpen] = useState(openProp);
  const shouldActiveOnPromoManagement = location.pathname.includes('promo-management') && href?.includes('promo-management')
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  let paddingLeft = 8;

  if (depth > 0) {
    paddingLeft = 40 + 8 * depth;
  }

  const style = {
    paddingLeft,
    paddingRight: 0,
  };

  if (children) {
    return (
      <ListItem
        {...rest}
        className={clsx(classes.item, className)}
        disableGutters
        key={title}
      >
        <Button
          id={id}
          className={classes.button}
          onClick={handleToggle}
          style={style}
          startIcon={
            Icon && <img src={Icon} alt="menu" className={classes.icon} />
          }
        >
          {title}
          {open ? (
            <ExpandLessIcon className={classes.expandIcon} color="inherit" />
          ) : (
            <ExpandMoreIcon className={classes.expandIcon} color="inherit" />
          )}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    );
  }

  return (
    <ListItem
      {...rest}
      className={clsx(classes.itemLeaf, className)}
      disableGutters
      key={title}
    >
      <Button
        id={id}
        activeClassName={isPoplite ? classes.activePoplite : classes.active}
        className={clsx(
          classes.buttonLeaf,
          isPoplite ? classes.buttonLeafPoplite : `depth-${depth}`, shouldActiveOnPromoManagement ? classes.active : {}
        )}
        component={RouterLink}
        exact
        style={style}
        to={href}
        onClick={() => {
          if (
            href === '/poplite/payments/browse' &&
            process.env.REACT_APP_GA_MEASUREMENT_ID
          ) {
            
            ReactGA.event({
              category: 'Payment Poplite',
              action: 'dashboard_saldo_transaction',
            });
          }
        }}
        startIcon={
          Icon && <img src={Icon} alt="menu" className={classes.icon} />
        }
      >
        {Icon && !isPoplite ? (
          <div style={{ fontWeight: 600 }}>{title}</div>
        ) : (
          title
        )}

        {Label && (
          <span className={classes.label}>
            <Label />
          </span>
        )}
      </Button>
    </ListItem>
  );
}

NavItem.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.any,
  label: PropTypes.any,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
  isPoplite: PropTypes.bool,
};

NavItem.defaultProps = {
  open: false,
};

export default NavItem;

import { createApi } from '@reduxjs/toolkit/query/react';
import Axios from 'axios';

const baseQuery = () => async ({ baseURL, path, method, ...config }) => {
  try {
    const res = await Axios({
      baseURL: baseURL + path,
      method,
      ...config,
    });

    return res;
  } catch (error) {
    return error;
  }
};

export const resourcesApi = createApi({
  reducerPath: 'resourcesApi',
  baseQuery: baseQuery(),
  endpoints: (builder) => ({
    getProvinces: builder.query({
      query: () => ({
        baseURL: process.env.REACT_APP_CDN,
        path: '/provinces.json',
        method: 'get',
      }),
    }),
  }),
});

export const { useGetProvincesQuery } = resourcesApi;

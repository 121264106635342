export const SET_OPEN_POPLITE_DRAWER = 'SET_OPEN_POPLITE_DRAWER';
export const SET_POPLITE_FEATURE = 'SET_POPLITE_FEATURE';
export const SET_OPEN_FEATURE_MENU = 'SET_OPEN_FEATURE_MENU';
export const RESET_POPLITE_FEATURE = 'RESET_POPLITE_FEATURE';


export const togglePopliteDrawer = (payload) => {
  return (dispatch) => {
    dispatch({ type: SET_OPEN_POPLITE_DRAWER, payload });
  };
};

export const setPopliteFeature = (payload) => {
  return (dispatch) => {
    dispatch({ type: SET_POPLITE_FEATURE, payload });
  };
};

export const setOpenFeatureMenu = (payload) => {
  return (dispatch) => {
    dispatch({ type: SET_OPEN_FEATURE_MENU, payload });
  };
};

export const resetPopliteFeature = () => {
  return (dispatch) => {
    dispatch({ type: SET_POPLITE_FEATURE  });
  };
};
/**
 * @param {ReturnType<typeof import('formik').useFormik>} formik
 * @param {string} fieldName
 */
export function getFormikMuiFieldProps(formik, fieldName) {
  return {
    name: fieldName,
    value: formik.values?.[fieldName],
    error: !!(formik.touched?.[fieldName] && formik.errors?.[fieldName]),
    helperText: formik.touched?.[fieldName] && formik.errors?.[fieldName],
    onChange: formik.handleChange,
    onBlur: formik.handleBlur,
  };
}
